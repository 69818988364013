

import { useEffect, useState, useMemo, useRef } from "react";


import Moment from 'react-moment';


import { Box, Button, HStack, IconButton, Spinner, Stack, Tag, Text, useRadio, useToast } from "@chakra-ui/react";
import { WorkflowSteps, GeniouslyContextProvider, GeniouslyThemeProvider, Step, GeniouslyCommander, GeniouslyCommanderModal, ChatService, ChatServiceOptions, ChatMessage } from "geniously-chat-ui";
import { DetailsModal } from "./detailsModal";
import { getApi } from "../../apiService";
import { IconAdjustmentsAlt, IconArrowBackUp, IconCheck, IconCode, IconLock, IconLockCheck, IconPencil, IconPencilPlus, IconPlayerPause, IconPlayerPlay, IconPlayerStop, IconPlayerTrackNext, IconRepeatOnce } from "@tabler/icons-react";
import { useNavigate, useParams } from 'react-router-dom';
import { getStepPath } from "./helpers";
import { AddStepInput } from "./addStepInput";
import { Logo } from "../CommonIcons/commonIcons";
import { IconDeviceFloppy } from "@tabler/icons-react";
import { IconPlayerSkipForward } from "@tabler/icons-react";
import { IconCaretRightFilled } from "@tabler/icons-react";
import { useApiEndpoint } from "../../utils/useApiHook";
import { VersionList } from "./versionsList";



export default function WorkFlowDetail({ workflowId, onWorkflowIdChange }: { workflowId: string, onWorkflowIdChange: (workflowId: string) => void }) {

  const [workflow, setWorkflow] = useState<any>();
  const [stepsOverride, setStepsOverride] = useState<Step[]>();
  const [modal, setModal] = useState(null)
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [selectedSteps, setSelectedSteps] = useState<Step[]>([])
  const navigate = useNavigate();
  const toast = useToast()

  useEffect(() => {
    if (workflowId && workflowId != "undefined") {
      //if workflow id was provided without version
      if (workflow && workflowId && !workflowId.includes(":") && workflow.id.startsWith(workflowId + ":")) return;
      if (!(workflow && workflow.id == workflowId)) {
        refreshWorkflow()
      }
    }
  }, [workflowId])

  function refreshWorkflow() {
    setWorkflow(undefined)
    getApi()?.authorize().then((authHeaders) => {
      fetch(`${getApi().baseUrl}/tasks/workflows/${workflowId}`, {
        headers: authHeaders
      }).then((response) => response.json()).then((workflow) => {
        workflow && setWorkflow(workflow);
        if (workflow && workflow.id !== workflowId) {
          onWorkflowIdChange(workflow.id)
        }

      })
    }).catch((error) => {
      console.error(error)
    })
  }

  // useEffect(()=>{
  //     if (currentUser && searchParams.get("force")!="true" ){
  //         navigate("/home")
  //     }
  //     else{
  //         navigate("/login")
  //     }
  // },[currentUser])

  const [pendingActions, setPendingActions] = useState<object>({})
  const backgroundCss = "linear-gradient(90deg, white calc(15px - 1.5px), transparent calc(15px - 1.5px), transparent calc(15px * 2)) center / 15px 15px, linear-gradient(white calc(15px - 1.5px), transparent calc(15px - 1.5px), transparent 80%) center / 15px 15px, lightgray;"
  function openStepDetailsModal(step: Step, tab: "inputs" | "code" = undefined) {

    setModal(<DetailsModal workflow={workflow} step={step} onClose={(reloadNeeded, newWorkflow) => {
      setModal(undefined)
      if (reloadNeeded) {
        if (newWorkflow) {
          setWorkflow(undefined)
          setTimeout(() => { /*hot fix... the Workflow UI somehow doesn't refresh it self property so this should enforce disposing it and create nee*/
            setWorkflow(newWorkflow)
          }, 500)
        }
        else {
          refreshWorkflow()

        }
      }
    }} defaultTab={tab} />)
  }




  function openWorkflowDetailsModal(tab: "inputs" | "code" = undefined) {

    setModal(<DetailsModal workflow={workflow} onClose={(reloadNeeded, newWorkflow) => {
      setModal(undefined)
      if (reloadNeeded) {
        if (newWorkflow) {
          setWorkflow(undefined)
          setTimeout(() => { /*hot fix... the Workflow UI somehow doesn't refresh it self property so this should enforce disposing it and create nee*/
            setWorkflow(newWorkflow)
          }, 500)
        }
        else {
          refreshWorkflow()

        }
      }
    }} defaultTab={tab} />)
  }
  function getStepActionButtons(step: Step) {
    let res = []
    if ((step as any).step_impl_function) {
      res.push(<Button variant="ghost" size="xs" opacity={0.8} fontSize="10px" onClick={() => { openStepDetailsModal(step, "code") }} leftIcon={<IconCode size="15px" />}>Code</Button>)
    }
    if (step.step_inputs && Object.keys(step.step_inputs).length > 0) {
      res.push(<IconButton variant="ghost" color="gray" opacity={0.8} aria-label="Show details" size="xs" onClick={() => {
        console.log(workflow.version)
        openStepDetailsModal(step)
      }
      } icon={<IconAdjustmentsAlt size="15px" />} />)
    }
    return <>{res}</>
  }



  const chatUrl = new URL(process.env.REACT_APP_API_URL);
  const wssUrl = `${chatUrl.protocol?.replace("http", "ws") || "wss"}//${chatUrl.hostname}${chatUrl.port ? ":" + chatUrl.port : ""}/tasks/workflows/${workflowId}/edit-copilot-chat`;


  const pendingUpdateTimerRef = useRef<any>()
  const chatService = useMemo(() => {
    let options: ChatServiceOptions = {
      wsEndpoint: wssUrl,
      authorizeFunc: () => getApi().authorize() as any,
      onNewMessageReceived: (message) => onMessageReceivedHandler(message),
      onDisconnect: () => {
        setDebugMode({ message: "disconnected", debug_active: false, debug_paused: false, pending_status_update: true })
      },
      onConnectionOpened: () => {
        pendingUpdateTimerRef.current = setTimeout(() => {
          setDebugMode(undefined)
        }, 5000);
      }
    }

    let service = ChatService.getInstance(options, true)

    return service
  }, [workflowId])

  function onMessageReceivedHandler(message: ChatMessage) {
    console.log("New message received", message)
    if (message.widgets && message.widgets[0] && message.widgets[0].workflow_steps) {
      setStepsOverride(message.widgets[0].workflow_steps)
    }
    if (pendingUpdateTimerRef.current) {
      clearTimeout(pendingUpdateTimerRef.current)
    }
    let command_msg: {
      type: "command-result", action_id: string, result: {
        current_step: Step,
        pending_step: Step,
        debug_active: boolean,
        debug_paused: boolean,

      }
    } = message as any
    if (command_msg.type == "command-result" && command_msg.action_id == "debug-update") {
      setDebugMode(command_msg.result)
    }

  }


  const [debugMode, setDebugMode] = useState<{
    current_step?: Step,
    pending_step?: Step,
    debug_active: boolean,
    debug_paused: boolean,
    message?: string,
    pending_status_update?: boolean

  } | null>()

  const workflowPatchEndpoint = useApiEndpoint("PATCH", `/tasks/workflows/${workflowId}`)

  function sendDebugCommand(command: "debug-start" | "debug-run-next-step" | "debug-run-until-step" | "debug-loop-finish" | "debug-loop-next-n-items" | "debug-loop-break" | "debug-run" | "debug-pause" | "debug-stop", args: { [key: string]: any } | undefined = undefined) {
    setPendingActions({ ...pendingActions, [command]: true })
    chatService.sendCommand({ "type": "command", "action_id": command, args: args || {} }, { waitForResult: true, timeoutSec: 120 }).finally((res) => {
      setPendingActions({ ...pendingActions, [command]: false })

    })

  }


  const debugSection = (
    <Stack align="end" opacity={debugMode?.pending_status_update ? 0.5 : undefined} pointerEvents={debugMode?.pending_status_update ? "none" : undefined}>
      {debugMode?.message && <Text fontSize="xs">{debugMode.message}</Text>}

      {!debugMode?.debug_active ? (
        <Button variant="outline" colorScheme="black" leftIcon={<IconPlayerPlay size="15px" />} size="xs" isLoading={pendingActions["debug-start"]}
          onClick={() => { sendDebugCommand("debug-start") }}
        >Start Debug</Button>
      ) : (
        <>
          <HStack >

            {debugMode?.debug_paused ? <>
              <Tag colorScheme="gray">Paused</Tag>
              <Button isLoading={setPendingActions["debug-run-next-step"]} variant="outline" colorScheme="black" leftIcon={<IconPlayerSkipForward size="15px" />} size="xs"
                onClick={() => { sendDebugCommand("debug-run-next-step") }}
              >Run next step
              </Button>


            </> : (
              <HStack color="gray"><Spinner size="xs" /><Text fontSize="xs">Running</Text></HStack>
            )}
            {debugMode?.debug_paused && <Button variant="outline" colorScheme="black" leftIcon={<IconPlayerPlay size="15px" />} size="xs"
              onClick={() => { sendDebugCommand("debug-run") }}
            >Run</Button>}
            {!debugMode?.debug_paused && <Button variant="outline" colorScheme="black" leftIcon={<IconPlayerPause size="15px" />} size="xs" onClick={() => { sendDebugCommand("debug-pause") }} isLoading={pendingActions["debug-pause"]}>Pause</Button>}

            {!debugMode?.debug_paused && <Button variant="outline" colorScheme="black" leftIcon={<IconPlayerStop size="15px" />} size="xs" onClick={() => { sendDebugCommand("debug-stop") }} isLoading={pendingActions["debug-stop"]}>Stop</Button>}

          </HStack>
          {debugMode?.pending_step?.step_type == "loop" && <HStack >

            <Button variant="outline" colorScheme="black" leftIcon={<IconRepeatOnce size="15px" />} onClick={() => { sendDebugCommand("debug-loop-next-n-items", { iter_n: 1 }) }} size="xs" isLoading={pendingActions["debug-loop-next-n-items"]}>Run next item</Button>
            <Button variant="outline" colorScheme="black" leftIcon={<IconPlayerPlay size="15px" />} size="xs" onClick={() => { sendDebugCommand("debug-loop-break") }} isLoading={pendingActions["debug-loop-break"]}>Break loop</Button>
            <Button variant="outline" colorScheme="black" leftIcon={<IconPlayerPlay size="15px" />} size="xs" onClick={() => { sendDebugCommand("debug-loop-finish") }} isLoading={pendingActions["debug-loop-finish"]}>Finish loop</Button>


          </HStack>}
          <Stack>
            {debugMode.current_step && <HStack>
              <IconCaretRightFilled size="15px" />
              <Text fontWeight={900}>{debugMode.current_step?.step_name}</Text>
            </HStack>}
            {debugMode.pending_step && <Stack spacing={0}>

              <Text fontSize="2xs" color="gray">Next step</Text>
              <HStack pl="20px" opacity={0.5}>

                <Text fontSize="sm">{debugMode.pending_step?.step_name}</Text>
              </HStack>
            </Stack>}
          </Stack>
        </>

      )}
    </Stack>
  )

  return (
    <Stack className="page" pt="0px" spacing={0} pr={modal ? "520px" : undefined}>
      <Box p="0px 10px" position="sticky" top="0px">



        {workflow &&
          <HStack align="start">
            <VersionList onVersionSelected={(version) => {
              onWorkflowIdChange(version.id)
            }} taskId={workflow.task_id} />

            <HStack pl="15px" justify="space-between">

            <Stack align="start" spacing={1}>
                <HStack>
              <Text fontWeight={900}>{workflow?.task_name} (v.{workflow.version})</Text>
                  <Button size="xs" onClick={() => { openWorkflowDetailsModal() }} variant="outline">Details</Button>
                </HStack>
              <HStack>

                {workflow.is_last ? (<HStack>
                  <Text fontSize="sm" color="gray.500">Workflow version is active</Text> <IconLockCheck color="green" size="18px" />
                  <Button size="xs" leftIcon={<IconPencilPlus size="15px" />} onClick={() => {

                    getApi().authorize().then((authHeaders) => {
                      fetch(`${getApi().baseUrl}/tasks/workflows/${workflowId}/new-version`, {
                        method: "POST",
                        headers: authHeaders
                      }).then((response) => response.json()).then((workflow) => {
                        if (!workflow.errors) {

                          setWorkflow(workflow);
                          navigate(`/workflows/${workflow.id}`)
                          toast({ title: "Create new version", description: "New version has been created 🎉", status: "success", duration: 2000, isClosable: true })
                        }
                        else {
                          toast({ title: "Create new version", description: "An error ocurred", status: "error", duration: 2000, isClosable: true })
                        }

                      })
                    })

                  }}>Edit as new version</Button>
                </HStack>)
                  : (
                    <>
                      <Text fontSize="sm" color="gray.500">Workflow version is a draft </Text> <IconPencil color="gray" size="18px" />
                        <Button size="xs" isLoading={workflowPatchEndpoint.isRunning} leftIcon={<IconCheck size="15px" />} onClick={() => {
                          workflowPatchEndpoint.execute({}, { is_last: true }).then((res) => {
                            if (res.success && res.task_workflow.is_last) {
                              setStepsOverride(undefined)
                              setWorkflow(res.task_workflow)
                              toast({ title: "Set as active", description: "Workflow version had been activated", status: "success", duration: 2000, isClosable: true })
                            }
                            else {
                              toast({ title: "Set as active", description: (res.errors && res.errors[0]) || "", status: "error", duration: 2000, isClosable: true })
                            }
                          })
                        }}>Set current as active</Button>
                    </>
                  )}

              </HStack>

              {stepsOverride && (
                <HStack>
                    {/* <Button size="xs" p="15px 8px 15px 3px" colorScheme="brand"
                    leftIcon={<IconCheck />}
                    isLoading={pendingActions["confirm-changes"]}
                    onClick={() => {
                      setPendingActions({ ...pendingActions, "undo-changes": true })
                      chatService.sendCommand({ "type": "command", "action_id": "confirm-changes" }, { waitForResult: true })
                        .then((res) => {
                          setStepsOverride(undefined)
                          setWorkflow(res)
                        }).finally(() => setPendingActions({ ...pendingActions, "confirm-changes": false }))

                    }}>Confirm changes
                  </Button> */}
                    <Button size="xs" p="15px 8px 15px 3px" colorScheme="brand" variant="outline"
                    isLoading={pendingActions["undo-changes"]}
                    leftIcon={<IconArrowBackUp />}
                    onClick={() => {
                      setPendingActions({ ...pendingActions, "undo-changes": true })
                      chatService.sendCommand({ "type": "command", "action_id": "undo-changes" }, { waitForResult: true })
                        .then((res) => {
                          setStepsOverride(undefined)
                          setWorkflow(res)
                        }).finally(() => setPendingActions({ ...pendingActions, "undo-changes": false }))

                      }}>Undo last change
                  </Button>
                </HStack>
              )}

            </Stack>
            <HStack justifySelf="end">
                {<HStack justifySelf="end" position="sticky" top="0px" alignSelf="end">
                  {debugSection}
                </HStack>}

            </HStack>
            </HStack>
          </HStack>
        }
      </Box>
      <GeniouslyThemeProvider>
        <GeniouslyContextProvider
          apiUrl={process.env.REACT_APP_API_URL}
          appId={"default"}
          appUrl={window.location.origin}
          authorizeFunc={() => getApi().authorize()}
        >

          <Stack flexGrow={1} align="stretch" overflow="auto" p="50px 10%" pb="150px">

            {modal}
            {workflow ? (


              <WorkflowSteps steps={stepsOverride || workflow?.workflow_steps}
                getStepActionButtons={getStepActionButtons}
                selectMode="multi-select"
                onSelectionChanged={(selectedSteps) => {
                  setSelectedSteps(selectedSteps)
                }}
                onStepClick={(step) => {
                  openStepDetailsModal(step)
                }}
                onAddStepRequested={(where, onDone) => {

                  return <AddStepInput whereToAdd={where} chatService={chatService} workFlow={workflow} onFinish={(steps) => {
                    setStepsOverride(steps)
                    onDone()
                  }}
                    onCancel={() => onDone()}
                  />

                }}
              />

            ) : (
              <Stack align="center">
                <Spinner size="lg" />
                <Box>Loading...</Box>
              </Stack>
            )}

          </Stack>
          <Stack position="absolute" bottom="20px" zIndex={100} width={modal ? "60%" : "calc(100% - 80px)"}   >

            <Stack align="center">

              <GeniouslyCommanderModal appId={"default"}
                apiUrl={process.env.REACT_APP_API_URL}
                authorizeFunc={() => getApi().authorize()}
                onNewMessageReceived={() => setIsChatOpen(true)}

                // onExecuteAction={onExecuteAction} TODO!!!
                //suggestedAttachments={suggestedAttachments} TODO  !!!
                //renderWidgets={renderWidgets} TODO!!

                //visitorId={finalVisitorId}
                //sessionId={sessionId}
                //onSessionChanged={onSessionChanged}
                //openMessageId={openMessageId}
                //notificationMessage={notificationMessage}
                suggestedAttachments={selectedSteps?.map(s => {
                  return ({ type: "text", name: s.step_name, content: JSON.stringify(s) })
                })}
                chatService={chatService}
                collectContext={() =>
                  Promise.resolve({
                    localTime: new Date().toString(),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  })
                }
              //open={isChatOpen}
                onClose={() => setIsChatOpen(false)}
              />
            </Stack>
          </Stack>
        </GeniouslyContextProvider>
      </GeniouslyThemeProvider>


    </Stack>
  );
}

