



import { useEffect, useMemo, useState } from "react";


import { Tabs, TabList, TabPanels, Tab, TabPanel, Tag, WrapItem, Wrap, Link, ButtonGroup, Button } from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";

import { useAppContext } from "../appContext";
import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import Inbox from "../components/Inbox/inbox";
import GenericList from "../components/GenericList/genericList";

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { useApiEndpoint, useApiFetch } from "../utils/useApiHook";
import ContactCard from "../components/Inbox/ContactCard";
import { IconChevronDown } from "@tabler/icons-react";




export default function CampaignAnalytics() {

    const { currentUser, logOut } = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { campaignId } = useParams()

    // useEffect(()=>{
    //     if (currentUser && searchParams.get("force")!="true" ){
    //         navigate("/home")
    //     }
    //     else{
    //         navigate("/login")
    //     }
    // },[currentUser])

    const getCampaignEndpoint = useApiEndpoint("GET", "/analytics/campaigns/{campaignId}", true, true)

    const [selectedCampaign, setSelectedCampaign] = useState<any>(undefined)
    useEffect(() => {
        if (campaignId && !(selectedCampaign?.id == campaignId) || (selectedCampaign && !selectedCampaign.stats)) {
            getCampaignEndpoint.execute({ campaignId }).then((data) => {
                setSelectedCampaign(data)
            }).catch((err) => {
                setSelectedCampaign({ id: campaignId, name: "Campaign not found" })
            })
        }
    }, [campaignId, selectedCampaign])


    return (
        <HStack justify="stretch" width="100%">
            <GenericList endpointPath="/analytics/campaigns" listTitle="Campaign analytics" primaryField="campaign"
                onSelected={(item) => { }}
                customCardRenderer={
                    (item, index, selected) => {
                        return (
                            <Box key={index} border="1px solid gray" borderRadius={8} p="6px" bg={selected ? "gray.100" : "white"} onClick={() => {
                                navigate("/analytics/" + item.id)
                                setSelectedCampaign(item)
                            }}>
                                <Box >
                                    <Box>
                                        <Text fontSize="lg">{item.name}</Text>
                                    </Box>
                                    <HStack justify="space-between" >
                                        <Stack flexGrow={1}>
                                            {item.start_date && <Text fontSize="sm" fontWeight={500}><Moment fromNow>{item.start_date}</Moment></Text>}
                                            {/* <Text fontSize="2xl" textAlign="end" fontWeight={900}>{Math.round((item.goal_achieved / item.contacts) * 100)}%</Text> */}
                                        </Stack>

                                    </  HStack>
                                </Box>
                            </Box>
                        )
                    }

                }
            />
            {selectedCampaign && (
                <CampaignDetail selectedCampaign={selectedCampaign} />
            )}

        </HStack>
    );
}

const CampaignDetail = ({ selectedCampaign }: { selectedCampaign: any }) => {
    const contactsEndpoint = useApiFetch<any[]>(`/analytics/campaigns/${selectedCampaign.id}/contacts`, { swrOptions: { revalidateIfStale: false, refreshInterval: 990000, revalidateOnFocus: false } })
    const [fetchMessagesType, setMessagesType] = useState<string>()
    const messagesEndpoint = useApiFetch<any[]>(`/analytics/campaigns/${selectedCampaign.id}/messages/${fetchMessagesType}`, { swrOptions: { revalidateIfStale: false, refreshInterval: 990000, revalidateOnFocus: false, shouldFetch: !!fetchMessagesType } })
    const [activeTab, setActiveTab] = useState(0)
    const navigate = useNavigate()
    useEffect(() => {
        setActiveTab(0)
        setMessagesType(undefined)

    }, [selectedCampaign])
    useEffect(() => {
        if (activeTab == 1) {
            setMessagesType("sent")
        }
    }, [activeTab])
    const chartData = useMemo(() => {
        if (selectedCampaign) {
            let res = selectedCampaign?.steps?.map((step) => {
                let res = selectedCampaign.stats?.stages?.find(s => s.stage == step.stage_name) || { stage: step.stage_name }
                if (res) {
                    res.sent = res.count - (res.errors || 0) - (res.replies || 0)
                    res.error_percent = Math.round(100 * ((res.errors as any) || 0) / selectedCampaign.contacts_count)
                }

                return res

            })
            if (!selectedCampaign?.steps?.length && selectedCampaign.stats?.stages?.length) {
                // patch old data
                res = selectedCampaign.stats?.stages.map((stage) => {
                    return {
                        stage: stage.stage,
                        count: stage.count,
                        errors: stage.errors
                    }
                })

            }
            if (res)
                res.reverse()
            return res
        }
    }, [selectedCampaign])

    const colors = {
        "error": "#ff5050",
        "sent": "#0099ff",
        "replied": "#00cc66",
    }

    const bulkUpdateEndpoint = useApiEndpoint("DELETE", "/inbox/contacts/activities/messages/bulk_archive_scheduled")

    function bulkCancelMessages(type: "not-engaged" | "all") {
        let payload = { campaign_id: selectedCampaign.id }
        if (type == "not-engaged") {
            payload["campaign_contact_state_filter"] = "scheduled"
        }
        bulkUpdateEndpoint.execute({}, payload)
    }

    return selectedCampaign && <Stack align="stretch" justify="stretch" height="100%" pt="30px" maxH="calc(100vh - 60px)" flexGrow={1} width="100%" >
        <HStack justify="space-between" p="10px">
            <Text fontSize="lg">📊 {selectedCampaign.name}</Text>
            {/* <Text>{JSON.stringify(selectedCampaign)}</Text> */}
            <HStack justify="end">

                <Menu >
                    <Link href={`${window.location.origin}/lists/${selectedCampaign.list_id}`}>
                        <MenuButton size="sm" as={Button}>Open list</MenuButton>
                    </Link>    <MenuButton size="sm" as={Button} rightIcon={<IconChevronDown />} isLoading={bulkUpdateEndpoint.isRunning}>
                        Actions
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => bulkCancelMessages("not-engaged")}>Cancel campaign for not engaged</MenuItem>
                        <MenuItem onClick={() => bulkCancelMessages("all")}>Cancel campaign for all</MenuItem>

                    </MenuList>
                </Menu>
            </HStack >
        </HStack>
        <HStack align="start">

            <Stack flexGrow={1} maxH="40vh" maxW="60vw" overflow="auto">
                {selectedCampaign.steps && <ReactECharts
                    style={{
                        height: '30vh',
                        width: '40vw',
                    }}
                    option={{
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                // Use axis to trigger tooltip
                                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                            }
                        },
                        legend: {},
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true // Ensure labels fit within the grid
                        },

                        xAxis: {
                            type: 'value',
                            show: false

                        },
                        yAxis: {
                            type: 'category',
                            axisLine: {
                                show: false
                            },
                            data: chartData?.map((step) => step.stage)
                        },
                        series: [
                            {
                                name: 'Total',
                                type: 'bar',
                                stack: 'total',
                                color: "lightgray",
                                label: {
                                    show: true
                                },

                                barWidth: 10,
                                data: chartData?.map((step) => step.count || 0)
                            },
                            {
                                name: 'Sent',
                                type: 'bar',
                                stack: 'types',
                                color: colors["sent"],
                                label: {
                                    show: true
                                },

                                data: chartData?.map((step) => step.sent || undefined)
                            }
                            ,
                            {
                                name: 'Replied',
                                type: 'bar',
                                stack: 'types',
                                color: colors["replied"],
                                label: {
                                    show: true
                                },

                                data: chartData?.map((step) => step.replies || undefined)
                            },
                            {
                                name: 'Failed',
                                type: 'bar',
                                stack: 'types',
                                color: colors["error"],
                                label: {
                                    show: true
                                },

                                data: chartData?.map((step) => step.errors || undefined)
                            }

                        ]
                    }}

                    //notMerge={true}
                    lazyUpdate={true}
                    //theme={"theme_name"}
                    //onChartReady={this.onChartReadyCallback}

                //opts={ }
                />
                }
                {/* {selectedCampaign.steps && <ReactECharts
                style={{
                    height: '50vh',
                    width: '50vw',
                }}
                option={{
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // Use axis to trigger tooltip
                            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                        }
                    },
                    legend: {},

                    xAxis: {
                        type: 'value',
                        show: false

                    },
                    yAxis: {
                        type: 'category',
                        axisLine: {
                            show: false
                        },
                        data: ['LinkedIn Connection Message', 'Follow-up Message'] //chartData?.map((step) => step.stage)
                    },


                    series: [
                        {
                            name: 'Sent',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: [10, 20]//chartData?.map((step) => step.count || 0)
                        },
                    ]
                }}

                //notMerge={true}
                lazyUpdate={true}
                //theme={"theme_name"}
                //onChartReady={this.onChartReadyCallback}

            //opts={ }
            />
            } */}
            </Stack>
            <Stack p="0px 20px" >

                <HStack justify="end" >
                    <Text fontWeight={500}>Total contacts:</Text>
                    <Text fontWeight={900}>{selectedCampaign.stats?.contacts_count}</Text>
                </HStack >
                <HStack justify="end">
                    <Text fontWeight={500}>Accepted invite:</Text>
                    <Text fontWeight={900}>{selectedCampaign.stats?.accepted_invite}</Text>
                </HStack>
                <HStack justify="end">
                    <Text fontWeight={500}>Replied:</Text>
                    <Text fontWeight={900}>{selectedCampaign.stats?.replied}</Text>
                </HStack>
            </Stack>
        </HStack>
        <Stack flexGrow={1} maxH="40vh" width="100%" >
            <Tabs width="100%" index={activeTab} onChange={setActiveTab}>
                <TabList>
                    <Tab>Contacts</Tab>
                    <Tab>Messages</Tab>

                </TabList>

                <TabPanels width="100%">
                    <TabPanel>
                        <Stack overflow="auto" maxH="40vh" >
                            <Wrap align="stretch" alignItems="stretch">
                                {contactsEndpoint.isLoading ? (<Text>Loading...</Text>) : (
                                    (contactsEndpoint.data?.map((contact, index) => {
                                        return (
                                            <WrapItem width="400px" >
                                                <Stack key={index} width="100%" height="100%" >
                                                    <Link href={"/inbox/" + contact.contact_id}>
                                                        <ContactCard contact={contact.contact} height="100%">
                                                            <HStack>

                                                                <Tag fontSize="xs" fontWeight={500}>{contact.current_stage || "no action yet"}</Tag>
                                                                <Tag fontSize="xs" fontWeight={500} backgroundColor={colors[contact.state]}>{contact.state}</Tag>
                                                            </HStack>


                                                        </ContactCard>
                                                    </Link>
                                                </Stack>
                                            </WrapItem>
                                        )
                                    }))
                                )}
                            </Wrap>
                            {contactsEndpoint.data?.length == 100 && <Stack><Text>Showing only first 100</Text></Stack>}
                        </Stack>
                    </TabPanel>
                    <TabPanel width="100%">
                        <Stack >
                            <HStack alignSelf="end">
                                <ButtonGroup size='sm' isAttached colorScheme="red">
                                    <Button variant={fetchMessagesType == "sent" ? "solid" : "outline"} onClick={() => setMessagesType("sent")}>Sent</Button>
                                    <Button variant={fetchMessagesType == "scheduled" ? "solid" : "outline"} onClick={() => setMessagesType("scheduled")} >Scheduled next</Button>
                                    <Button variant={fetchMessagesType == "received" ? "solid" : "outline"} onClick={() => setMessagesType("received")}>Received</Button>

                                </ButtonGroup>
                            </HStack>
                            <Stack overflow="auto" maxH="35vh">
                                {messagesEndpoint.isLoading ? (<Text>Loading...</Text>) : (
                                    (messagesEndpoint.data?.map((message, index) => {
                                        return (
                                            <Link href={"/inbox/" + message.contact_id}>
                                            <Stack key={index} width="100%" height="100%" spacing={1} border="1px solid gray" p="4px" rounded={2} borderRadius={4}
                                                cursor={"pointer"}
                                                onClick={() => {
                                                    //navigate()
                                                }}
                                            >
                                                <HStack justify="space-between">
                                                    {message.subject ? <Text>Subject: {message.subject}</Text> : <Text></Text>}
                                                    <Text justifySelf="end" fontSize="xs" fontWeight={500} color="gray" textAlign="end"><Moment>{message.sent_at || message.scheduled_send_time || message.timestamp}</Moment></Text>
                                                </HStack>
                                                <Text whiteSpace="pre-wrap">{message.text}</Text>
                                            </Stack>
                                            </Link>
                                        )
                                    }))
                                )}
                            </Stack>
                        </Stack>
                    </TabPanel>

                </TabPanels>
            </Tabs>
        </Stack>


    </Stack>
}