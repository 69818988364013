import { Button, ButtonGroup, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Spinner, Stack, Text } from "@chakra-ui/react"
import GenericList from "../GenericList/genericList"
import Moment from "react-moment"
import { useState } from "react"
import { useApiEndpoint, useApiFetch } from "../../utils/useApiHook"
import { AutoUI, GeniouslyThemeProvider } from "geniously-chat-ui"
import { FilterButton } from "./filterButton"
import { IconStack2 } from "@tabler/icons-react"

interface VersionInfo {
    data_page: any,
    filter: any,
    column_schema: JSONSchema
    operations: { name: string }[]
    total_count: number
}
const ListVersionsComponent = ({ listId, onRestored }) => {
    const [currentVersion, setCurrentVersion] = useState(null)
    const versionInfoEndpoint = useApiFetch<VersionInfo>(`/lists/${listId}?version=${currentVersion?.id}`, { swrOptions: { revalidateOnFocus: false, refreshInterval: 999999 }, shouldFetch: !!currentVersion })
    const restoreVersionEndpoint = useApiEndpoint<any>("POST", `/lists/${listId}/versions/${currentVersion?.id}/restore`, true, true)
    return <HStack align="start" flexShrink={1}>
        <GenericList endpointPath={`/lists/${listId}/versions`} listTitle="Versions" primaryField="version" collapsible
            selectedId={currentVersion?.id}
            onSelected={(item) => {
                setCurrentVersion(item)
            }}
            customCardRenderer={(item) => {
                return <Stack border="1px solid gray" borderRadius="8px" spacing={0} p="4px 8px">
                    <Text >v{item.version}</Text>
                    <HStack justify="space-between" align="end" width="100%" >
                        <Text fontSize="xs">
                            <Moment format="DD. MMM YYYY HH:mm:ss">{item.created_at}</Moment>
                        </Text>
                        <Text fontSize="xs">
                            <Moment fromNow>{item.created_at}</Moment>
                        </Text>
                    </HStack>
                </Stack>
            }}
        />
        <Stack overflow="auto" justify="stretch" height="100%" >

                {versionInfoEndpoint.isLoading && <Spinner />}
            {!!versionInfoEndpoint.data && <Stack align="stretch" width="100%" justify="stretch" height="100%" >
                <HStack justify="space-between" width="100%" >

                        <Stack spacing={0}>
                            {currentVersion ? <Text fontWeight={900}>Version {currentVersion?.version}</Text> : null}
                            {versionInfoEndpoint.data ? <Text fontSize="2xs">Total records {versionInfoEndpoint.data?.total_count}</Text> : null}
                        </Stack>

                        <HStack align="end">
                            <Menu >
                                <MenuButton as={IconButton} size="xs" icon={<IconStack2 size="18px" />}
                                />
                                <MenuList zIndex={15}>
                                    {versionInfoEndpoint.data?.operations?.map((op, i) => (
                                        <MenuItem cursor="default" >{op.name}</MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>


                            <ButtonGroup size="xs">

                                <FilterButton
                                    filters={versionInfoEndpoint?.data?.filter} />
                                <Button colorScheme="brand" isLoading={restoreVersionEndpoint.isRunning} onClick={() => restoreVersionEndpoint.execute({}).then(() => onRestored())}>Restore this version</Button>
                            </ButtonGroup>
                        </HStack>
                    </HStack>
                    {versionInfoEndpoint?.isLoading ? <Text>Loading...</Text> : (
                    <Stack border="1px solid lightgray" flexGrow={1} flexShrink={1} overflow="auto" borderRadius="4px" p="5px">
                        <GeniouslyThemeProvider>

                            <AutoUI
                                layout="table"
                                value={versionInfoEndpoint?.data?.data_page}
                                schema={versionInfoEndpoint.data?.column_schema}
                            />
                        </GeniouslyThemeProvider>
                        </Stack>
                    )}

            </Stack>}
        </Stack>

    </HStack >

}

export { ListVersionsComponent }