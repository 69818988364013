
import {
    Box,
    Button,
    HStack,
    IconButton,
    Spinner,
    Stack,
    Text,
} from "@chakra-ui/react";
import { IconChevronLeft, IconChevronRight, IconList } from "@tabler/icons-react";
import { Task } from "geniously-chat-ui";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { getApi } from "../../apiService";
import { SearchFilter } from "../SearchFilter/searchFilter";

import Moment from "react-moment";
import { TaskWorkflow } from "./workFlowSchema";
import { useApiFetch } from "../../utils/useApiHook";

const TaskWorkflowVersionCard = ({
    taskWorkflow,
    onClick,
    isSelected,
}: {
    taskWorkflow: TaskWorkflow;
    onClick: () => any;
    isSelected: boolean;
}) => {
    return (
        <Stack
            border={isSelected ? "2px solid black" : "1px solid #cacaca"}
            rounded="md"
            p="4px"
            spacing="0px"
            _hover={{ border: "1px solid black" }}
            onClick={() => onClick()}
            cursor="pointer"
            background={isSelected ? "gray.100" : "white"}
        >
            <HStack>
                <Text fontWeight="bold" fontSize="sm">
                    Version {taskWorkflow.version}{" "}
                </Text>
            </HStack>
            <HStack fontSize="sm">
                <Text fontSize="sm">Created </Text>
                <Text fontSize="sm">
                    <Moment fromNow>{taskWorkflow.created_at}</Moment>{" "}
                </Text>
            </HStack>
            <Text fontSize="xs" color="gray">
                <Moment>{taskWorkflow.created_at}</Moment>{" "}
            </Text>


            <Text fontSize="md">Status: {taskWorkflow.status}</Text>
        </Stack>
    );
};

const VersionList = ({
    taskId,
    onVersionSelected,
    currentVersionId,
}: {
    taskId: string,
    onVersionSelected: (taskWorkflow) => any;
    currentVersionId?: string;
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [query, setQuery] = useState("");
    const [appId, setAppId] = useState("");
    const [account, setAccount] = useState<any>();


    // useEffect(()=>{
    //     mutate()
    // },[query,appId])

    const [expanded, setExpanded] = useState(false);
    const { data: workflowVersions, isLoading } = useApiFetch<TaskWorkflow[]>(`/tasks/${taskId}/workflows/versions`, { shouldFetch: expanded })

    return (
        <Stack>
            <Stack
                background="white.900"
                height="100%"
                // bgGradient="linear(to-b, white, gray.50)"
                align="start"
                justify="start"
                spacing="10px"
                flexGrow={1}
                rounded="5px"
            >
                <Stack
                    bgColor="white"
                    borderWidth={1}
                    rounded={15}
                    overflow="hidden"
                    p="5px"
                    m="5px"
                    spacing={0}
                >
                    <HStack>
                        <IconButton
                            aria-label="show/hide"
                            size="sm"
                            onClick={() => setExpanded(!expanded)}
                            icon={expanded ? <IconChevronLeft /> : <IconList />}
                            alignSelf="flex-end"
                        />
                        {expanded && <Text fontWeight={900}>Workflow versions</Text>}
                    </HStack>
                    {expanded && (
                        <Stack
                            minWidth="400px"
                            p="10px"
                            overflow="auto"
                            border="5px solid white"
                            justify="start"
                            minH="80vh"
                            maxH="calc(100vh - 125px)"
                            flexGrow={1}
                        >
                            <Stack
                                position="sticky"
                                m="-5px -5px 0px"
                                top="-15px"
                                p="10px"
                                bgColor="white"
                            >



                            </Stack>
                            {isLoading ? (
                                <Box justifySelf="center">
                                    <Spinner size="xl" />
                                </Box>
                            ) : (
                                workflowVersions?.map ? (
                                    workflowVersions?.map((version, i) => (
                                        <TaskWorkflowVersionCard
                                            key={i}
                                            taskWorkflow={version}
                                            onClick={() => onVersionSelected(version)}
                                            isSelected={version.id === currentVersionId}
                                        />
                                    ))) : (
                                    <Text>{JSON.stringify(workflowVersions)}</Text>
                                )
                            )}
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export { VersionList };


