
import {
  Box,
  Button,
  HStack,
  IconButton,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { Task } from "geniously-chat-ui";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { getApi } from "../../apiService";
import { SearchFilter } from "../SearchFilter/searchFilter";
import { AccountsFilter } from "./accountsFilter";
import { TaskWorkflow } from "./workflowsSchema";
import Moment from "react-moment";

const TaskCard = ({
  task,
  onClick,
  isSelected,
}: {
  task: Task;
  onClick: () => any;
  isSelected: boolean;
}) => {
  return (
    <Stack
      border={isSelected ? "2px solid black" : "1px solid #cacaca"}
      rounded="md"
      p="4px"
      spacing="0px"
      _hover={{ border: "1px solid black" }}
      onClick={() => onClick()}
      cursor="pointer"
      background={isSelected ? "gray.100" : "white"}
    >
      <HStack>
        <Text fontWeight="bold" fontSize="sm">
          {task.task_name}{" "}
        </Text>
      </HStack>
      <HStack>
        <Text fontSize="sm">Created </Text>
        <Text fontSize="sm">
          <Moment fromNow>{task.created_at}</Moment>{" "}
        </Text>
      </HStack>

      <Text fontSize="md">Status: {task.status}</Text>
    </Stack>
  );
};

const WorkflowList = ({
  onTaskSelected,
  selectedTask,
}: {
  onTaskSelected: (taskWorkflow) => any;
  selectedTask?: Task;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState("");
  const [appId, setAppId] = useState("");
  const [account, setAccount] = useState<any>();

  useEffect(() => {
    const selected_account = searchParams.get("account");
    if (account) {
      setAppId(account?.default_app_id);
      if (account?.id !== selected_account) {
        setSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          account: account?.id,
        });
      }
    } else if (selected_account) {
      getApi()
        .getAccount(selected_account, true)
        .then((acnt) => {
          setAccount(acnt);
        });
    } else if (!account && (selected_account || appId)) {
      setAppId(undefined);

      setSearchParams({
        ...Object.fromEntries(
          [...(searchParams.entries() as any)].filter(
            ([k, v]) => k != "account"
          )
        ),
      });
    }
  }, [searchParams, account]);
  // useEffect(()=>{
  //     mutate()
  // },[query,appId])
  const loginOnAccountOfUser = useMemo(
    () => localStorage.getItem("loginOnAccountOfUser"),
    []
  );
  const {
    data: tasks,
    isLoading,
    mutate,
  } = useSWR(
    `tasks?q=${query}&tenant_id=${appId}`,
    () => {
      return getApi().getTasks({
        q: query,
        app_id: appId,
        complex_task: true,
        root_mode: true,
      });
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
    }
  );

  // useEffect(()=>{
  //     mutate()
  // },[query, appId])

  const [expanded, setExpanded] = useState(false);

  return (
    <Stack>
      <Stack
        background="white.900"
        height="100%"
        // bgGradient="linear(to-b, white, gray.50)"
        align="start"
        justify="start"
        spacing="10px"
        flexGrow={1}
        rounded="5px"
      >
        <Stack
          bgColor="white"
          borderWidth={1}
          rounded={15}
          overflow="hidden"
          p="5px"
          m="5px"
          spacing={0}
        >
          <HStack>
            <IconButton
              aria-label="show/hide"
              size="sm"
              onClick={() => setExpanded(!expanded)}
              icon={!expanded ? <IconChevronLeft /> : <IconChevronRight />}
              alignSelf="flex-end"
            />
            {!expanded && <Text fontWeight={900}>Task workflows</Text>}
          </HStack>
          {!expanded && (
            <Stack
              minWidth="400px"
              p="10px"
              overflow="auto"
              border="5px solid white"
              justify="start"
              minH="80vh"
              maxH="calc(100vh - 125px)"
              flexGrow={1}
            >
              <Stack
                position="sticky"
                m="-5px -5px 0px"
                top="-15px"
                p="10px"
                bgColor="white"
              >
                <AccountsFilter
                  onAccountSelected={(v) => {
                    setAccount(v);
                    setSearchParams({
                      ...Object.fromEntries(
                        [...(searchParams.entries() as any)].filter(
                          ([k, v]) => k != "account"
                        )
                      ),
                    });
                  }}
                  account={account}
                  rootMode
                />
                {account && account?.name !== loginOnAccountOfUser ? (
                  <Button
                    size="xs"
                    colorScheme="blue"
                    onClick={() => {
                      localStorage.setItem(
                        "loginOnAccountOfUser",
                        account.name
                      );
                      window.location.reload();
                    }}
                    //alignSelf="end"
                  >
                    Login as "{account?.name}"
                  </Button>
                ) : (
                  <></>
                )}
                <SearchFilter
                  search={query}
                  onChange={(search, filter) => {
                    setQuery(search);
                  }}
                />
              </Stack>
              {isLoading ? (
                <Box justifySelf="center">
                  <Spinner size="xl" />
                </Box>
              ) : (
                tasks?.map((task, i) => (
                  <TaskCard
                    key={i}
                    task={task}
                    onClick={() => onTaskSelected(task)}
                    isSelected={selectedTask?.id == task.id}
                  />
                ))
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export { WorkflowList };


