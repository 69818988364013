

import { useEffect, useMemo,  useRef,  useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  layout,
  Select,
  Spinner,
  Stack,
  Switch,
  Tag,
  TagCloseButton,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { FiEdit } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { BiMessageSquareError } from "react-icons/bi";
import { AutosizeTextInput } from "./AutosizeTextInput";
import { AutosizeTextArea } from "./AutosizeTextArea";
import { AutoDatePicker } from "./AutoDatePicker";

const AutoUIRow = ({
  value,
  schema,
  excludeFields,
  size = "sm",
  type = "object",
  validationErrors,
  onValueChange,
  parentPath = "",
  allowAddNew = true,
  readOnly,
  customEditors,
  showOnlySchemaFields,
  layout: layoutDefined,
  handle_lookup,
  _mark,
}: {
  value?: any;
  schema?: JSONSchema;
  excludeFields?: string[];
  size?: string;
  type?: JSONSchemaType | null | undefined | JSONSchemaType[];
  parentPath?: string;
  validationErrors?: { loc: string[]; msg: string }[];
  readOnly?: boolean;
  showOnlySchemaFields: boolean;
  layout?: "horizontal" | "vertical" | "table" | undefined;
  allowAddNew?: boolean;
  onValueChange?: (value: any) => void;
  customEditors?: { [key: string]: any };
  _mark: string;
  handle_lookup?: (
    value: any,
    fieldSchema: JSONSchema,
    fieldName: string
  ) => any;
}) => {
  const [valueState, setValueState] = useState({});
  const [layout, _setLayout] = useState<
    "horizontal" | "vertical" | "table" | undefined
  >(layoutDefined || "vertical");
  // const setLayout = (layout:"horizontal"|"vertical"|"table"|undefined)=>{
  //     if (layout){
  //         _setLayout(layout)
  //     }
  //     else{
  //         _setLayout(layoutDefined)
  //     }
  // }

  function getChildErrors(field: any) {
    return validationErrors
      ?.filter((e) => e.loc[0] == field)
      .map((e) => ({
        ...e,
        loc: e.loc.slice(1),
      }));
  }
  function getFieldErrors(field: any) {
    return validationErrors
      ?.filter((e) => e.loc[0] === field)
      .map((e) => e.msg);
  }

  const fields = useMemo(() => {
    let newFields: any[] = [];
    let x = _mark;
    if (schema) {
      if (type === "object") {
        schema.properties &&
          Object.getOwnPropertyNames(schema.properties).forEach((f) =>
            newFields.push(f)
          );
      }
      if (type === "array") {
        let properties = (schema.items as JSONSchema)?.properties;
        properties &&
          Object.getOwnPropertyNames(properties).forEach((f) =>
            newFields.push(f)
          );
      }
    }
    if (!showOnlySchemaFields || !schema) {
      let _ref_val = valueState || value;
      if (Array.isArray(_ref_val)) {
        _ref_val = _ref_val[0];
      }
      if (_ref_val) {
        Object.getOwnPropertyNames(_ref_val).forEach(
          (f) => !newFields.includes(f) && newFields.push(f)
        );
      }
    }
    return newFields;
  }, [valueState, schema]);

  const [editedField, setEditedField] = useState<string>();

  function modifyValue(modifyFunc) {
    let newValueState = Object.assign({}, valueState);
    modifyFunc(newValueState);
    setValue(undefined, newValueState);
  }

  useEffect(() => {
    if (value !== valueState) {
      setValueState(value || {});
    }
  }, [value]);

  function setValue(path, val) {
    let newValueState;
    if (path) {
      newValueState = Object.assign({}, valueState);
      let current = newValueState;
      let pathArr = path.split(".");
      pathArr.forEach((prop, i) => {
        if (i != pathArr.length - 1) {
          let newCurrent = current[prop];
          if (!newCurrent) {
            newCurrent = {};
            current[prop] = newCurrent;
          } else {
            newCurrent = Object.assign({}, newCurrent);
            current[prop] = newCurrent;
          }
          current = newCurrent;
        }
      });
      current[pathArr[pathArr.length - 1]] = val;
    } else if (val && typeof val === "object") {
      newValueState = { ...val };
    } else if (typeof val === "function") {
      newValueState = val;
    }

    if (newValueState) {
      setValueState(newValueState);
      onValueChange && onValueChange(newValueState);
    }
  }

  function evaluateShowCondition(show_condition) {
    if (show_condition) {
      let not_met_found = Object.getOwnPropertyNames(show_condition).find(
        (f) =>
          !(
            valueState[f] == show_condition[f] ||
            !!valueState[f] === show_condition[f]
          )
      );
      if (not_met_found) {
        return false;
      }
    }

    return true;
  }

  function getFieldTitle(field: string, customSchema?: JSONSchema) {
    if (
      (customSchema || schema)?.properties &&
      (customSchema || schema)?.properties[field]?.title?.length
    ) {
      return (customSchema || schema)?.properties[field]?.title;
    } else {
      return field;
    }
  }

  function getCanRemoveKey(field: string, customSchema?: JSONSchema) {
    return (
      !readOnly &&
      (schema || customSchema) &&
      (customSchema || schema)?.properties &&
      !(customSchema || schema)?.properties[field]
    ); // if field is not specified in the schema
  }

  function getFieldSchema(field: string, customSchema?: JSONSchema) {
    try {
      const theSchema = customSchema || schema;

      let result;
      if (schema?.type == "object") {
        result = theSchema?.properties && theSchema?.properties[field];
      } else {
        result =
          theSchema?.items &&
          (theSchema?.items as JSONSchema)?.properties[field];
      }
      if (
        !result &&
        theSchema?.additional_properties &&
        typeof theSchema?.additional_properties === "object"
      )
        result = theSchema?.additional_properties;
      if (result?.type === undefined && result?.allOf) {
        //try to find refs:
        if (result.allOf.length > 0) {
          let ref = result.allOf[0]["$ref"];
          if (ref && ref.startsWith("#/")) {
            let current = theSchema;
            for (let part of ref.split("/")) {
              if (part === "#") {
                current = theSchema;
              } else {
                current = current[part];
                if (!current) break;
              }
            }
            if (current) return current;
          }
        }
      }
      return result;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  function getFieldType(field) {
    if (value && value[field])
      return Array.isArray(value[field]) ? "array" : typeof value[field];
    else return getFieldSchema(field)?.type || "string";
  }

  const FieldTitle = ({ field }) => {
    const fieldSchema = useMemo(() => getFieldSchema(field), [field]);
    const fieldErrors = useMemo(() => getFieldErrors(field), [field]);
    const canRemoveKey = useMemo(() => getCanRemoveKey(field), [field]);
    const titleFontSize =
      size == "medium" ? "sm" : size == "large" ? "md" : "xs";
    return (
      <Stack align="start" justify="start">
        <Tooltip label={fieldSchema?.description} placement="top-start">
          <Flex>
            <Text
              css={{ whiteSpace: "nowrap" }}
              fontSize={titleFontSize}
              fontWeight={800}
            >
              {getFieldTitle(field, schema)}
            </Text>

            {fieldErrors ? (
              <BiMessageSquareError size="12px" color="red" />
            ) : (
              fieldSchema?.description && (
                <Text fontSize="0.8em" fontWeight={900}>
                  ?
                </Text>
              )
            )}
          </Flex>
        </Tooltip>
        {canRemoveKey && (
          <IconButton
            size="12px"
            m="2px"
            variant="outline"
            aria-label="Search database"
            icon={<IoMdClose size="12px" />}
            onClick={() => {
              modifyValue((val) => {
                delete val[field];
              });
            }}
          />
        )}
      </Stack>
    );
  };
  function ConditionalWrap({ condition, wrap, children }) {
    return condition ? wrap(children) : children;
  }

  return (
    <ConditionalWrap
      condition={layout === "table"}
      wrap={(children) => <tr className="trMain">{children}</tr>}
    >
      {fields &&
        fields
          .filter(
            (field) =>
              field != "$lookupCache" &&
              evaluateShowCondition(schema?.showCondition) &&
              !(
                schema?.properties && schema?.properties[field]?.hidden === true
              )
          )
          .filter((field) => {
            return (
              !excludeFields ||
              !excludeFields.includes(
                (parentPath ? `${parentPath}.` : "") + field
              )
            );
          })
          .map((field, i) => {
            const fieldSchema = getFieldSchema(field);
            const fieldType = getFieldType(field);
            const isArray =
              Array.isArray(valueState[field]) ||
              (!valueState[field] && fieldSchema?.type == "array");
            const isArrayOfObjects =
              (Array.isArray(valueState[field]) &&
                valueState[field] &&
                valueState[field][0] &&
                typeof valueState[field][0] === "object") ||
              (valueState[field] &&
                fieldSchema?.type === "array" &&
                (fieldSchema?.items as any)?.type == "object");
            const showAsTable =
              layoutDefined === "table" ||
              (!layoutDefined &&
                isArrayOfObjects &&
                valueState[field] &&
                valueState[field].length >= 5);
            //const fieldErrors= getFieldErrors(field)

            return fieldType === "object" ? (
              <ConditionalWrap
                key={i}
                condition={layout !== "table"}
                wrap={(children) => <tr className="tr-obj"> {children}</tr>}
              >
                <td colSpan={2}>
                  {layout !== "table" && <FieldTitle field={field} />}
                  <Box p="0px 0px 10px 20px">
                    {customEditors?.[field] || (
                      <AutoUI
                        value={valueState[field]}
                        schema={fieldSchema}
                        size={size}
                        readOnly={readOnly || !onValueChange}
                        type={schema?.properties?.[field]?.type}
                        parentPath={parentPath ? `${parentPath}.` : "" + field}
                        validationErrors={getChildErrors(field)}
                        excludeFields={excludeFields}
                        showOnlySchemaFields={showOnlySchemaFields}
                        onValueChange={(newVal) => setValue(field, newVal)}
                        _mark="layout_not_table"
                      />
                    )}

                    {allowAddNew && fieldSchema?.additional_properties && (
                      <Box>
                        {customEditors?.[field] || (
                          <FieldValue
                            size={size}
                            layout={layout}
                            fieldValue={undefined}
                            fieldName={field}
                            objValue={valueState}
                            schema={{
                              type: "string",
                              description: "Add new key",
                            }}
                            onEditStart={() => setEditedField(field)}
                            handle_lookup={undefined}
                            onEditFinish={(val) => {
                              val && setValue(field + "." + val, "");
                              setEditedField(undefined);
                            }}
                            onObjChange={(objValue) => {
                              setValue(undefined, objValue);
                              setEditedField(undefined);
                            }}
                            isBeingEdited={editedField == field}
                            _mark="F1"
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </td>
              </ConditionalWrap>
            ) : (
              <>
                {layout === "vertical" && isArray && (
                  <tr>{<FieldTitle field={field} />}</tr>
                )}

                <ConditionalWrap
                  key={i}
                  condition={!showAsTable && layout !== "table"}
                  wrap={(children) => <tr className="tr-layout">{children}</tr>}
                >
                  {layout != "vertical" && layout !== "table" && (
                    <td>{<FieldTitle field={field} />}</td>
                  )}

                  {layout === "horizontal" && <FieldTitle field={field} />}

                  {isArray ? (
                    <>
                      {valueState &&
                        ((isArrayOfObjects && !showAsTable) ||
                          !isArrayOfObjects) &&
                        valueState[field]?.map(
                          (v, i) =>
                            customEditors?.[field] || (
                              <FieldValue
                                size={size}
                                layout={layout}
                                handle_lookup={handle_lookup}
                                isReadOnly={readOnly || !onValueChange}
                                fieldName={field}
                                fieldValue={valueState[field][i]}
                                objValue={valueState}
                                schema={fieldSchema?.items}
                                onEditStart={() =>
                                  setEditedField(field + "." + i)
                                }
                                onEditFinish={(val) => {
                                  if (val) {
                                    valueState[field][i] = val;
                                    setValue(field, [...valueState[field]]);
                                  } else {
                                    valueState[field].splice(i, 1);
                                    setValue(field, [...valueState[field]]);
                                  }
                                  setEditedField(undefined);
                                }}
                                onObjChange={(objValue) => {
                                  setValue(undefined, objValue);
                                  setEditedField(undefined);
                                }}
                                isBeingEdited={editedField == field + "." + i}
                                _mark="F2"
                              />
                              // (isArrayOfObjects) ? (

                              //     (!!showAsTable)?(

                              //      <Box  rounded="md" m="4px" p="2px" >
                              //         <AutoUI
                              //             value={valueState[field][i]}
                              //             //value={{a:"s"}}
                              //             //schema={fieldSchema}
                              //             //layout={(Object.keys(valueState[field][0]).length<4)?"table":layout }
                              //             layout={showAsTable?"table":layoutDefined}
                              //             size={size}
                              //             readOnly={readOnly||!onValueChange}
                              //             type={schema?.properties?.[field]?.type}
                              //             parentPath={parentPath?`${parentPath}.`:""+field}
                              //             validationErrors={getChildErrors(field)}
                              //             excludeFields={excludeFields}
                              //             onValueChange={(val) => {
                              //                 if (val) {
                              //                     valueState[field][i] = val
                              //                     setValue(field, [...valueState[field]])
                              //                 }
                              //                 else {
                              //                     valueState[field].splice(i, 1)
                              //                     setValue(field, [...valueState[field]] )
                              //                 }
                              //                 setEditedField(undefined)
                              //             }}
                              //     />
                              //      </Box>

                              //      )
                              //     :(
                              //         <>
                              //         {i==0 && <><tr  >
                              //             {valueState && valueState[field] && Object.keys(valueState[field][0]).map((key, i) => (
                              //                 <>

                              //                 <td key={i} >
                              //                     <Text fontSize="2xs" fontWeight={900} color="gray" m="4px">
                              //                     {key}
                              //                     </Text>
                              //                 </td>
                              //                 </>
                              //             ))}
                              //             </tr>
                              //         </>}

                              //         <AutoUIRows
                              //             value={valueState[field][i]}
                              //             //value={{a:"s"}}
                              //             //schema={fieldSchema}
                              //             layout={"table"}
                              //             //layout={"table"}
                              //             size={size}
                              //             readOnly={readOnly||!onValueChange}
                              //             type={schema?.properties?.[field]?.type}
                              //             parentPath={parentPath?`${parentPath}.`:""+field}
                              //             validationErrors={getChildErrors(field)}
                              //             excludeFields={excludeFields}
                              //             onValueChange={(val) => {
                              //                 if (val) {
                              //                     valueState[field][i] = val
                              //                     setValue(field, [...valueState[field]])
                              //                 }
                              //                 else {
                              //                     valueState[field].splice(i, 1)
                              //                     setValue(field, [...valueState[field]] )
                              //                 }
                              //                 setEditedField(undefined)
                              //             }}
                              //         />

                              //         </>
                              //     )

                              // ):(

                              //     <FieldValue
                              //         size={size}
                              //         layout={layout}
                              //         handle_lookup={handle_lookup}
                              //         isReadOnly={readOnly||!onValueChange}
                              //         fieldName={field}
                              //         fieldValue={valueState[field][i]}
                              //         objValue={valueState}
                              //         schema={fieldSchema?.items}
                              //         onEditStart={() => setEditedField(field+"."+i)}
                              //         onEditFinish={(val) => {
                              //             if (val) {
                              //                 valueState[field][i] = val
                              //                 setValue(field, [...valueState[field]])
                              //             }
                              //             else {
                              //                 valueState[field].splice(i, 1)
                              //                 setValue(field, [...valueState[field]] )
                              //             }
                              //             setEditedField(undefined)
                              //         }}
                              //         onObjChange={(objValue) => {
                              //             setValue(undefined,objValue)
                              //             setEditedField(undefined)
                              //         }}
                              //         isBeingEdited={editedField == field+"."+i}
                              //     />

                              // )
                            )
                        )}

                      {isArrayOfObjects && showAsTable && (
                        <HStack
                          rounded="md"
                          m="4px"
                          p="2px"
                          border="1px solid #bababa"
                          overflow="auto"
                        >
                          <AutoUI
                            value={valueState[field]}
                            //value={{a:"s"}}
                            schema={fieldSchema}
                            //layout={(Object.keys(valueState[field][0]).length<4)?"table":layout }
                            layout={"table"}
                            size={size}
                            showOnlySchemaFields={showOnlySchemaFields}
                            readOnly={readOnly || !onValueChange}
                            type={schema?.properties?.[field]?.type}
                            parentPath={
                              parentPath ? `${parentPath}.` : "" + field
                            }
                            validationErrors={getChildErrors(field)}
                            excludeFields={excludeFields}
                            onValueChange={(val) => {
                              if (val) {
                                valueState[field] = val;
                                setValue(field, [...valueState[field]]);
                              } else {
                                valueState[field].splice(i, 1);
                                setValue(field, [...valueState[field]]);
                              }
                              setEditedField(undefined);
                            }}
                            _mark="is_arrays_of_objects_table"
                          />
                          <HStack></HStack>
                        </HStack>
                      )}
                      {/* add new item placeholder  */}

                      {allowAddNew &&
                        !isArrayOfObjects &&
                        (customEditors?.[field] || (
                          <Box maxW="200px">
                            <FieldValue
                              size={size}
                              layout={layout}
                              handle_lookup={handle_lookup}
                              fieldValue={""}
                              placeholder="Add new"
                              fieldName={field}
                              objValue={valueState}
                              isReadOnly={readOnly || !onValueChange}
                              schema={{
                                ...(fieldSchema || { type: "string" }),
                                description: "Add new",
                              }}
                              onEditStart={() => setEditedField(field + ".new")}
                              onEditFinish={(val) => {
                                val &&
                                  setValue(field, [
                                    ...(valueState[field] || []),
                                    val,
                                  ]);
                                setEditedField(undefined);
                              }}
                              onObjChange={(objValue) => {
                                setValue(undefined, objValue);
                                setEditedField(undefined);
                              }}
                              isBeingEdited={editedField == field + ".new"}
                              _mark="F3"
                            />
                          </Box>
                        ))}
                    </>
                  ) : (
                    <td className="td-layout" style={{ width: "100%" }}>
                      {layout == "vertical" && <FieldTitle field={field} />}
                      <Flex direction="row" align="start">
                        {/* {valueState[field]} */}
                        {/* standard edit vvvvvvvvvvvvvvvvvv*/}

                        {customEditors?.[field] || (
                          <FieldValue
                            size={size}
                            layout={layout}
                            handle_lookup={handle_lookup}
                            required={schema?.required?.includes(field)}
                            isReadOnly={readOnly || !onValueChange}
                            fieldValue={valueState[field]}
                            fieldName={field}
                            objValue={valueState}
                            //fieldValue={"valueState[field]"}
                            schema={fieldSchema}
                            onEditStart={() => setEditedField(field)}
                            onEditFinish={(val) => {
                              setEditedField(undefined);
                              modifyValue((state) => (state[field] = val));
                            }}
                            onObjChange={(objValue) => {
                              setValue(undefined, objValue);
                              setEditedField(undefined);
                            }}
                            isBeingEdited={editedField == field}
                            //isBeingEdited={true}
                            _mark="F4"
                          />
                        )}
                        {fieldSchema?.type !== "boolean" &&
                          schema?.required?.includes(field) && (
                            <Tooltip label="Required field">
                              <Box marginTop="-5px">
                                <Text
                                  fontWeight={900}
                                  fontSize="large"
                                  color={
                                    !valueState[field] ? "red" : "darkgray"
                                  }
                                >
                                  *
                                </Text>
                              </Box>
                            </Tooltip>
                          )}
                        {/* standard edit ^^^^^^^^^^^^^^^^^*/}
                      </Flex>
                    </td>
                  )}
                </ConditionalWrap>
              </>
            );
          })}
    </ConditionalWrap>
  );
};

export const AutoUI = (props: {
  value?: any;
  schema?: JSONSchema;
  excludeFields?: string[];
  size?: string;
  type?: JSONSchemaType | null | undefined | JSONSchemaType[];
  parentPath?: string;
  allowAddNew?: boolean;
  validationErrors?: { loc: string[]; msg: string }[];
  readOnly?: boolean;
  showOnlySchemaFields?: boolean;
  layout?: "horizontal" | "vertical" | "table" | undefined;
  onValueChange?: (value: any) => void;
  customEditors?: { [key: string]: any };
  handle_lookup?: (
    value: any,
    fieldSchema: JSONSchema,
    fieldName: string
  ) => any;
  _mark?: string;
}) => {
  const finalProps: any = useMemo(() => {
    let _props: any = { ...props };
    if (_props.allowAddNew == undefined) _props.allowAddNew = true;
    if (_props.value && Array.isArray(_props.value)) {
      //_props.value = { items: _props.value };
      if (_props.onValueChange) {
        _props.onValueChange = (val) => {
          props.onValueChange(val.items);
        };
      }
    }
    return _props;
  }, [props]);

  const fieldCaptions = useMemo(() => {
    if (props.layout !== "table") return undefined;
    if (finalProps.schema) {
      if (
        finalProps.schema.type == "array" &&
        finalProps.schema.items?.properties &&
        !(props.value && !Array.isArray(props.value))
      ) {
        return Object.fromEntries(
          Object.keys(finalProps.schema.items.properties).map((field) => [
            field,
            finalProps.schema.items.properties[field].title || field,
          ])
        );
      }
    } else if (props.value && Array.isArray(props.value)) {
      return Object.fromEntries(
        Object.keys(props.value[0]).map((field) => [field, field])
      );
    }
  }, [finalProps.schema, props.value]);

  return (
    <Flex
      width="100%"
      //maxWidth="100%"
      //border="2px solid black"
      flexShrink={1}
      className="autoUIGridBox"
      overflow="hidden"
      css={`
        & table {
          table-layout: fixed;
          max-width: 100%;
          overflow: auto;
        }
      `}
    >
      <table className="autoUIGrid">
        <tbody>
          {fieldCaptions ? (
            <>
              <tr>
                {Object.values(fieldCaptions).map((field, i) => (
                  <th key={i}>
                    <Text fontSize="sm" whiteSpace="nowrap">
                      {field}
                    </Text>
                  </th>
                ))}
              </tr>

              {props.value.map((val, i) => (
                <AutoUIRow
                  key={i}
                  _mark={props._mark + "_(A)"}
                  {...{
                    ...finalProps,
                    value: val,
                    onValueChange: (val) => {
                      let newVal = [...props.value];
                      if (val) {
                        newVal[i] = val;
                      } else {
                        newVal.splice(i, 1);
                      }
                      props.onValueChange(newVal);
                    },
                  }}
                />
              ))}
              {props.allowAddNew && !props.readOnly && (
                <tr>
                  <Button
                    onClick={() => {
                      let newVal = [...props.value];
                      newVal.push(
                        Object.fromEntries(
                          Object.keys(fieldCaptions).map((field) => [field, ""])
                        )
                      );
                      props.onValueChange(newVal);
                    }}
                  >
                    Add item
                  </Button>
                </tr>
              )}
            </>
          ) : (
            <AutoUIRow
              {...{ ...finalProps, schema: finalProps.schema }}
              _mark={props._mark + "_(B)"}
            />
          )}
        </tbody>
      </table>
      {/* {value && JSON.stringify(value)} */}
    </Flex>
  );
};

const FieldValue = ({
  fieldValue,
  fieldName,
  objValue,
  onObjChange,
  size,
  required,
  schema,
  layout,
  placeholder,
  isBeingEdited,
  onEditStart,
  onEditFinish,
  isLoading,
  isReadOnly,
  handle_lookup,
  _mark,
}: {
  fieldValue: any;
  fieldName: string;
  objValue: any;
  onObjChange?: (objValue: any) => void;
  size?: string;
  required?: boolean;
  layout: string;
  schema?: JSONSchema;
  isBeingEdited?: boolean;
  onEditStart: () => void;
  onEditFinish: (value: any) => void;
  placeholder?: string;
  isLoading?: boolean;
  isReadOnly?: boolean;
  handle_lookup?: (
    value: any,
    fieldSchema: JSONSchema,
    fieldName: string
  ) => any;
  _mark?: string;
}) => {
  const isDateTime = useMemo(() => {
    if (schema) {
      return schema.format === "date-time";
    } else if (typeof fieldValue === "string") {
      const isoDateRegex =
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}([+|\d|\.|:|Z]*)$/;
      let res = isoDateRegex.test(fieldValue);

      return res;
    } else {
      return false;
    }
  }, [schema]);
  const readOnly = isReadOnly || (schema && (schema["readOnly"] as boolean));
  const defaultNullPlaceholder = schema?.default || placeholder || "undefined";
  let fieldSchema: JSONSchema = schema || {
    type: fieldValue ? (typeof fieldValue as any) : "string",
  };
  const [tempValue, setTempValue] = useState<any>(undefined);
  useEffect(() => {
    let vals = fieldValue || fieldSchema.default || "";

    setTempValue(vals);
  }, [fieldValue]);
  const _onEditFinish = (e) => {
    onEditFinish && onEditFinish(tempValue);
  };
  const isBigText =
    typeof fieldValue === "string" &&
    ((fieldSchema?.max_len || fieldSchema?.maxLength || 0) > 1000 ||
      fieldValue?.includes("\n"));

  const sizeChakra =
    size == "small"
      ? "sm"
      : size == "medium"
      ? "md"
      : size == "lg"
      ? "large"
      : "sm";

  function getPlaceholderText() {
    if (readOnly) return defaultNullPlaceholder;
    else if (fieldSchema.example) {
      return "e.q.: " + fieldSchema.example;
    } else if (fieldSchema.description?.length < 50) {
      // we can use short description as placeholder
      return fieldSchema.description;
    } else {
      return defaultNullPlaceholder;
    }
  }

  const placeholderText = useMemo(() => getPlaceholderText(), [fieldSchema]);

  let isMultiline =
    isBigText &&
    fieldSchema?.type === "string" &&
    !fieldSchema.format &&
    !fieldSchema.one_of;

  const inputSizeCss = useMemo(() => {
    if (size == "small") {
      return {
        minWidth: "250px",
        fontFamily: "inherit",
        padding: "0px 2px",
        fontSize: "10pt",
      };
    } else if (size == "large") {
      return {
        minWidth: "250px",
        fontFamily: "inherit",
        padding: "0px 2px",
        fontSize: "14pt",
      };
    } else {
      return {
        minWidth: "250px",
        fontFamily: "inherit",
        margin: "-2px 0px",
        padding: "0px 2px",
        fontSize: "12pt",
      };
    }
  }, [size]);

  function getEditElement() {
    let x = _mark;
    if (
      (fieldSchema?.type === "string" || fieldSchema?.type === "array") &&
      !fieldSchema.format &&
      !(fieldSchema.one_of || fieldSchema.enum)
    ) {
      if (fieldSchema?.lookup_datasource) {
        return (
          <AutosizeTextInput
            css={inputSizeCss}
            value={tempValue}
            placeholder={placeholderText}
            selectedValue={
              tempValue &&
              objValue["$lookupCache"] &&
              objValue["$lookupCache"][tempValue]
            }
            onValueSelected={(val) => {
              if (val && typeof val === "object" && val.value) {
                onObjChange((oldObjValue) => {
                  let objValue = oldObjValue ? { ...oldObjValue } : {};
                  if (!objValue["$lookupCache"]) objValue["$lookupCache"] = {};
                  if (!objValue["$lookupCache"][fieldName])
                    objValue["$lookupCache"][fieldName] = {};
                  objValue["$lookupCache"][fieldName][val.value] = val;
                  if (Array.isArray(objValue[fieldName])) {
                    // if it is an array, update the value in the array
                    let i = objValue[fieldName].findIndex(
                      (item) => item === fieldValue
                    );
                    if (i >= 0) {
                      objValue[fieldName][i] = val.value;
                    } else {
                      //Or add if its a new val
                      objValue[fieldName].push(val.value);
                    }
                  } else {
                    objValue[fieldName] = val.value;
                  }
                  return objValue;
                });
              } else if (
                !val &&
                objValue["$lookupCache"] &&
                objValue["$lookupCache"][fieldName] &&
                objValue["$lookupCache"][fieldName][fieldValue]
              ) {
                // remove value from lookup cache
                onObjChange((objValue) => {
                  delete objValue["$lookupCache"][fieldName][fieldValue];
                  if (Array.isArray(objValue[fieldName])) {
                    // if it is an array, remove the value from the array
                    let i = objValue[fieldName].findIndex(
                      (item) => item === fieldValue
                    );
                    objValue[fieldName].splice(i, 1);
                  } else {
                    objValue[fieldName] = undefined;
                  }
                  return objValue;
                });
              }
            }}
            handleLookup={
              !handle_lookup
                ? undefined
                : (val) => {
                    return handle_lookup(
                      val,
                      fieldSchema,
                      fieldSchema?.lookup_datasource
                    );
                    // return new Promise((resolve, reject) => {
                    //     setTimeout(() => {
                    //         resolve([
                    //             {document_id:"a", title:"title a",text:"text"},
                    //             {document_id:"b", title:"title b",text:"text"},
                    //         ].filter((item)=>item.title.includes(val)).map((item)=>({value:item.document_id, label:item.title ||item.text||item.document_id, secondaryLabel:item.text}))
                    //         )
                    //     }, 1000)
                    // })
                  }
            }
            onApply={(val) => {
              if (
                tempValue &&
                objValue["$lookupCache"] &&
                objValue["$lookupCache"][fieldName]
              ) {
                let found: any = Object.values(
                  objValue["$lookupCache"][fieldName]
                ).find((v: any) => v?.label === val);
                if (found) {
                  onEditFinish(found.value);
                  setTempValue(found.value);
                  return;
                }
              }
              setTempValue(val);
              onEditFinish(val);
            }}
          />
        );
      } else if (!isBigText) {
        return (
          <AutosizeTextInput
            css={inputSizeCss}
            value={tempValue}
            placeholder={placeholderText}
            onApply={onEditFinish}
          />
        );
      } else {
        return (
          <AutosizeTextArea
            placeholder={getPlaceholderText()}
            maxWidth="100%"
            value={tempValue}
            maxHeight="70vh"
            autoFocus
            css={inputSizeCss}
            onApply={onEditFinish}
          />
        );
      }
    } else if (fieldSchema.one_of || fieldSchema.enum) {
      const _enum = fieldSchema.one_of || fieldSchema.enum;
      return (
        <Select
          size={size || "small"}
          css={inputSizeCss}
          value={tempValue}
          //open={true}

          onBlur={(e) => _onEditFinish(e)}
          //onClose={(e) => _onEditFinish(e)}
          onChange={(e) => {
            setTempValue(e.target.value);
            onEditFinish && onEditFinish(e.target.value);
          }}
        >
          <option key={-1} value={undefined}>
            [empty]
          </option>
          {_enum.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </Select>
      );
    } else if (
      fieldSchema?.type === "number" ||
      typeof fieldValue === "number"
    ) {
      return (
        <AutosizeTextInput
          css={inputSizeCss}
          value={tempValue}
          placeholder={placeholderText}
          onApply={(val) => onEditFinish(parseFloat(val))}
        />
      );
    } else if (fieldSchema?.type === "integer") {
      return (
        <AutosizeTextInput
          css={inputSizeCss}
          value={tempValue}
          placeholder={placeholderText}
          onApply={(val) => onEditFinish(parseInt(val))}
        />
      );
    } else {
      return (
        <Box>
          <Text fontSize={sizeChakra}>{fieldValue?.toString()}</Text>
          {/* <Text fontSize="2xs" marginTop="-4px">edit is not supported</Text> */}
        </Box>
      );
    }
  }

  function validateValue(fieldSchema, value) {
    if (!fieldSchema) return null;
    if (!fieldValue?.toString()) return null;
    if (fieldSchema.type === "string") {
      if (fieldSchema.maxLength && value?.length > fieldSchema.maxLength)
        return `Value is too long. Max length is ${fieldSchema.maxLength}`;
      if (fieldSchema.minLength && value?.length < fieldSchema.minLength)
        return `Value is too short. Min length is ${fieldSchema.minLength}`;
      if (
        fieldSchema.pattern &&
        value?.match &&
        !value.match(fieldSchema.pattern)
      )
        return `Value is not in correct format. It must match this pattern: ${fieldSchema.pattern}`;
    }
  }

  const error = useMemo(
    () => validateValue(fieldSchema, tempValue),
    [fieldSchema, tempValue]
  );
  const textValueRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  useEffect(() => {
    if (textValueRef.current) {
      setHasOverflow(
        textValueRef.current.scrollHeight > textValueRef.current.clientHeight
      );
    }
  }, [textValueRef]);

  let className = undefined;
  if (fieldSchema?.type !== "boolean" && !isBeingEdited) {
    let classes = [];
    if (readOnly) classes.push("readOnlyFieldValue");
    else {
      classes.push("fieldValue");
    }
    if (isBigText) classes.push("multiline");
    if (required) classes.push("required");
    if (error) classes.push("error");
    if (!fieldValue?.toString()) {
      if (fieldSchema?.default) classes.push("isDefault");
      else classes.push("empty");
    }

    className = classes.join(" ");
  }

  if (isDateTime) {
    return (
      <AutoDatePicker
        value={tempValue?.toString() || fieldValue?.toString()}
        onApply={(val) => {
          setTempValue(val);

          onEditFinish && onEditFinish(val);
        }}
        readOnly={readOnly}
        onValueChange={(val) => {
          setTempValue(val);
          //onEditFinish && onEditFinish(val)
        }}
      />
    );
  }

  return (
    <Flex //width={(isMultiline||layout==="table")?"100%":undefined}
      maxH={isMultiline ? "75vh" : "1.7em"}
      className="fieldValueBox"
      width="100%"
      maxW="100%"
      justify="stretch"
      flexGrow={1}
      overflow="hidden"
      css={`
        & .fieldValue {
          background-color: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(150, 150, 150, 0.2);
          border-radius: 5px;
          min-height: 1.5em !important;
          min-width: 100px !important;
          position: relative;
          max-width: 60vw;
        }
        & .fieldValue.error {
          border: 2px solid red !important;
        }
        & .fieldValue.isDefault .textField {
          color: rgba(100, 100, 100) !important;
        }
        & .fieldValue.empty .textField {
          color: rgba(100, 100, 100, 0.5) !important;
        }
        & .fieldValue.required {
          background-color: rgba(255, 255, 255, 0.4);
        }

        & .fieldValue.required.empty {
          background-color: rgba(255, 255, 255, 0.4);
          border: 2px solid rgba(255, 0, 0, 0.2);
        }

        & .fieldValue:hover {
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(150, 150, 150, 0.6);
        }
        & .fieldValue .editIcon {
          visibility: hidden;
          position: absolute;
          right: -4px;
          top: -2px;
          /* margin:2px; */
        }
        & .fieldValue:hover .editIcon {
          visibility: visible;
        }

        & .fieldValueContent {
        }

        & .fieldValue.multiline {
          flex: 1 0 auto;
        }

        & .fieldValue.multiline textarea {
          white-space: pre-wrap !important;
          width: 100% !important;
          min-height: 4em !important;
          overflow-wrap: anywhere !important;
        }
        & .fieldValueContent.multiline {
          white-space: pre-wrap;
          width: 100%;
          min-height: 4em;
          overflow-wrap: anywhere;
        }
        & .fieldValueContent.multiline span {
          overflow-wrap: anywhere;
        }
        & .fieldValue:hover .editIcon {
          visibility: visible;
        }

        & .fieldValue span,
        .readonlyFieldValue span {
          max-height: 500px;
          overflow-y: auto;

          overflow-x: hidden !important;
        }
      `}
    >
      <Flex
        className={className}
        direction="row"
        align="center"
        onClick={
          !readOnly
            ? () => {
                if (
                  !isBeingEdited &&
                  !isDateTime &&
                  fieldSchema?.type !== "boolean"
                ) {
                  onEditStart();
                }
              }
            : undefined
        }
        width="100%"
      >
        {isBeingEdited &&
        fieldSchema?.type !== "boolean" &&
        tempValue !== undefined ? (
          getEditElement()
        ) : fieldSchema?.type === "string" &&
          fieldSchema?.lookup_datasource &&
          objValue["$lookupCache"] &&
          objValue["$lookupCache"] &&
          objValue["$lookupCache"][fieldName] &&
          objValue["$lookupCache"][fieldName][tempValue] ? (
          <Wrap>
            <WrapItem>
              <Tooltip
                colorScheme="gray"
                bg="gray.100"
                color="black"
                hasArrow
                label={
                  <Stack>
                    <Text fontSize="2xs">
                      <b>id:</b>
                      {objValue["$lookupCache"][fieldName][tempValue]?.value}
                    </Text>
                    <Text fontSize="xs">
                      {
                        objValue["$lookupCache"][fieldName][tempValue]
                          ?.secondaryLabel
                      }
                    </Text>
                  </Stack>
                }
                closeOnScroll
              >
                <Tag>
                  <Text>
                    {objValue["$lookupCache"][fieldName][tempValue]?.label}
                  </Text>
                  <TagCloseButton
                    onClick={() => {
                      onEditFinish && onEditFinish(null);
                    }}
                  />
                </Tag>
              </Tooltip>
            </WrapItem>
          </Wrap>
        ) : fieldSchema?.type === "boolean" ? (
          <Box margin="0px 5px">
            <Switch
              size="md"
              //background={readOnly?"gray.200":undefined}
              readOnly={readOnly}
              isChecked={
                fieldValue == undefined ? fieldSchema.default : fieldValue
              }
              onChange={(e) => {
                setTempValue(e.target.checked);
                onEditFinish(e.target.checked);
              }}
            />
          </Box>
        ) : (
          <Flex
            p="1px 2px"
            style={{ overflow: "hidden" }}
            align="start"
            className={"fieldValueContent" + (isBigText ? " multiline" : "")}
            maxWidth="100%"
            width="100%"
          >
            <Tooltip
              label={
                hasOverflow
                  ? fieldValue?.toString() || getPlaceholderText()
                  : undefined
              }
              placement="top-start"
            >
              <Text
                ref={textValueRef}
                className="textField"
                textAlign="start"
                fontSize={sizeChakra || "sm"}
                fontWeight={500}
                textOverflow="ellipsis"
                whiteSpace={isMultiline ? undefined : "nowrap"}
                overflow="hidden"
              >
                {tempValue?.toString() || getPlaceholderText()}
              </Text>
            </Tooltip>
          </Flex>
        )}
        {!isBeingEdited && fieldSchema?.default && !fieldValue?.toString() && (
          <Box
            margin="0px 10px"
            style={{ position: "relative", left: "8px", zIndex: 90 }}
            alignSelf="start"
          >
            <Tooltip label="This is the default value, which will be used unless changed">
              <Text fontSize="12px" fontWeight={500} color="gray">
                ?
              </Text>
            </Tooltip>
          </Box>
        )}
        {isLoading && (
          <Box margin="0px 10px">
            <Spinner size="sm" />
          </Box>
        )}
        {!readOnly && !isBeingEdited && fieldSchema?.type !== "boolean" ? (
          <Flex
            margin="2px 25px 0px -25px"
            className="editIcon"
            align="center"
            justify="end"
            opacity="20%"
          >
            <FiEdit
              color="black"
              size="15px"
              style={{ position: "relative", left: "20px" }}
            />
          </Flex>
        ) : (
          <></>
        )}
      </Flex>
      <Box width="15px" marginLeft="-5px">
        <Text color="red" fontSize="10px">
          {error}
        </Text>
      </Box>
    </Flex>
  );
};

//                           

