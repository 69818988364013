import React, { useEffect, useMemo, useState } from "react";
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppContext } from "../appContext";
import { AutoUI } from "../components/AutoUI/AutoUI";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Input,
  Portal,
  Spinner,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  TabIndicator,
  Text,
  Tooltip,
  VStack,
  Wrap,
  WrapItem,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import { AddSkillButton, SkillBox } from "../components/SkillBox/skillBox";
import { AppProject, MetaType, Skill } from "../models/dataModel";

import { EditModal } from "../components/ModalDialogs/editModal";
import {
  IconArrowBack,
  IconChevronLeft,
  IconChevronRight,
  IconCircleCheckFilled,
  IconMessageCircle,
  IconPalette,
  IconRocket,
} from "@tabler/icons-react";
import AppSkillsEdit from "../components/AppDetail/appSkillsEdit";

import { DataSourcesSection } from "../components/DataSourcesEdit/dataSourcesSection";
// import ChatFloatingButton from "../chat/chatFloatingButton";
// import { ChatComponent } from "../chat/chatComponent";

import ColorPicker from "../components/ColorPicker/colorPicker";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { IconBook2 } from "@tabler/icons-react";
import { IconWand } from "@tabler/icons-react";
import { IconAdjustmentsHorizontal } from "@tabler/icons-react";
import {
  ChatComponent,
  ChatFloatingButton,
  GeniouslyThemeProvider,
  ChatContainer,
} from "geniously-chat-ui";

// require('react-dom');
// (window as any).React2 = require('react');
// console.log("test: ",(window as any).React1 === (window as any).React2);

export default function AppEditPage() {
  const {
    currentUser,
    logOut,
    setCurrentAppProject,
    setIsAppModified,
    setInfo,
    setError,
  } = useAppContext();
  const [modal, setModal] = useState<any>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [appValue, setAppValue] = useState<AppProject>();

  function updateAppValue(updateFunc: (appValue: AppProject) => void) {
    const newAppValue = { ...appValue };
    updateFunc(newAppValue);
    setAppValue(newAppValue);
    setCurrentAppProject(newAppValue);
    if (JSON.stringify(newAppValue) != JSON.stringify(savedValue)) {
      setIsAppModified(true);
    }
  }
  const [savedValue, setSavedValue] = useState<AppProject>();
  const isChanged = useMemo(
    () => JSON.stringify(appValue) !== JSON.stringify(savedValue),
    [appValue, savedValue]
  );

  const { appId } = useParams();

  useEffect(() => {
    if (!appId) return;
    getApi()
      .getApp(appId)
      .then((res) => {
        setSavedValue(res);
        setCurrentAppProject(res);
        setAppValue(res);
        setIsAppModified(false);
      });
  }, [appId]);

  const [saving, setSaving] = useState(false);
  const toast = useToast();
  function save(appValue: AppProject) {
    setSaving(true);
    getApi()
      .saveApp(appValue)
      .then((res) => {
        toast({
          title: "Success",
          description: "App successfully saved 🎉",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        setAppValue(res);
        setCurrentAppProject(res);
        setIsAppModified(false);
        setSavedValue(res);
      })
      .catch((err) => {
        console.error(err);
        let details = err.response?.data?.detail
          ?.map((errDetail) => {
            let sliced = null;
            if (errDetail?.loc?.length > 1) {
              // skip first element, it's always "body"
              sliced = errDetail?.loc.slice(1);
              return sliced.join(".") + ": " + errDetail?.msg;
            } else {
              return null;
            }
          })
          .filter((s) => s);

        if (details?.length) {
          // slice only first 3 errors
          details = details.slice(0, 3);
        }

        toast({
          title: "Error ",
          description: details ? details.join("\n") : err.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  const [showChat, setShowChat] = useState(false);

  const NiceTab = React.forwardRef(
    (
      {
        icon,
        children,
        number,
      }: {
        icon?: any;
        children: string;
        number?: number;
      },
      ref
    ) => {
      return (
        <Tab flexShrink={1} flexBasis={"40px"} p={["1px", "5px", "10px"]}>
          <HStack spacing={2} fontWeight={900}>
            <Box flexShrink={1}>{icon}</Box>
            <Box flexShrink={1} flexGrow={1}>
              <Text
                width="100%"
                fontSize={["8px", "12px", "14px"]}
                whiteSpace={"nowrap"}
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {children}
              </Text>
            </Box>
            <Box flexShrink={1}>
              {number > 0 && (
                <Badge
                  p="0px 2px"
                  mb="5px"
                  variant="solid"
                  lineHeight="15px"
                  alignSelf="start"
                  colorScheme="brand"
                  color="white"
                >
                  {number}
                </Badge>
              )}
            </Box>
          </HStack>
        </Tab>
      );
    }
  );

  if (!appValue) {
    return (
      <Box p="20px">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Stack>
      <HStack
        spacing={5}
        p="15px 20px"
        alignSelf="stretch"
        justifyContent="space-between"
        position="sticky"
        top="0px"
        bgColor="gray.50"
        backdropBlur="3xl"
        zIndex={1}
      >
        {appId ? (
          <Heading fontSize="18px">Setup</Heading>
        ) : (
          <Heading>Create new AI chat for your app</Heading>
        )}
        <HStack>
          {/* <Button colorScheme="brand" alignSelf="flex-start"    
                    //isLoading={saving}
                    isDisabled={!!!appId}
                    rightIcon={<IconRocket/>} onClick={()=>{
                        setModal(<DeployDialog appId={appId} onClose={()=>setModal(undefined)}/>)
                    }
                }>Deploy</Button> */}
          <Tooltip label={!isChanged ? "No changes to save" : undefined}>
            <Button
              colorScheme="brand"
              alignSelf="flex-start"
              isLoading={saving}
              isDisabled={!isChanged}
              rightIcon={<IconCircleCheckFilled />}
              onClick={() => {
                save(appValue);
              }}
            >
              Save
            </Button>
          </Tooltip>
        </HStack>
      </HStack>
      <Stack
        align="start"
        p={["0px 15px", "0px 20px"]}
        alignSelf="stretch"
        spacing={2}
      >
        {modal}

        <Box
          bgColor="white"
          rounded={10}
          borderWidth={1}
          shadow="md"
          p="5px"
          width="100%"
        >
          <Tabs
            colorScheme="brand"
            onChange={(tabIndex) => setShowChat(tabIndex === 4)}
            width="100%"
          >
            <TabList width="100%" overflow="hidden">
              <NiceTab icon={<IconAdjustmentsHorizontal />}>
                General settings
              </NiceTab>
              <NiceTab
                icon={<IconBook2 />}
                number={appValue?.data_sources?.length}
              >
                
                Knowledge base
              </NiceTab>
              <NiceTab icon={<IconWand />} number={appValue?.skills?.length}>
                Skills
              </NiceTab>
            </TabList>
            <TabIndicator mt="-1.5px" height="3px" bgColor="brand.500" />
            <TabPanels>
              <TabPanel overflow="auto" maxH="calc(100vh - 205px)">
                <AutoUI
                  value={{
                    name: appValue.name,
                    company_info: appValue.company_info,
                    company_domain: appValue.company_domain,
                    //default_languages: appValue.default_languages,
                  }}
                  onValueChange={(val) =>
                    setAppValue({
                      ...appValue,
                      name: val.name,
                      company_domain: val.company_domain,
                      company_info: val.company_info,
                      //default_languages: val.default_languages,
                    })
                  }
                  schema={{
                    title: "DataSourceDefinition",
                    type: "object",
                    properties: {
                      name: {
                        title: "Project name",
                        description: "Name of this AI app",
                        maxLength: 256,
                        example: "",
                        type: "string",
                      },
                      company_domain: {
                        title: "Company domain",
                        description: "URL of your company website",
                        maxLength: 256,
                        example: "www.mycompany.com",
                        type: "string",
                        
                      },
                      company_info: {
                        title: "Company info",
                        description: "Describe what your company does",
                        maxLength: 10024,
                        
                        type: "string",
                      },
                    
                    },
                    required: [
                      "name",
                      //"instructions",
                    ],
                  }}
                />
              </TabPanel>
              <TabPanel overflow="auto" maxH="calc(100vh - 205px)">
                <DataSourcesSection
                  allDataSources={appValue.data_sources}
                  onEditDataSource={(i, ds) => {
                    const newDataSources = [...appValue.data_sources];
                    newDataSources[i] = ds;
                    setAppValue({ ...appValue, data_sources: newDataSources });
                  }}
                  onAddDataSource={(ds) => {
                    setAppValue({
                      ...appValue,
                      data_sources: [...appValue.data_sources, ds],
                    });
                  }}
                  onDeleteDataSource={(ds) => {
                    setAppValue({
                      ...appValue,
                      data_sources: appValue.data_sources.filter(
                        (d) => !(d === ds || (d.uuid && d.uuid === ds.uuid))
                      ),
                    });
                  }}
                />
              </TabPanel>
              <TabPanel overflow="auto" maxH="calc(100vh - 205px)">
                <AppSkillsEdit appValue={appValue} onAppChange={setAppValue} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Stack>
    </Stack>
  );
}
