import { Box, Button, Spinner, Stack } from "@chakra-ui/react";
import { ImSearch } from "react-icons/im";
import { DataSource, MetaType, Skill } from "../../models/dataModel";
import { useEffect, useMemo, useState } from "react";
import { AutoUI } from "../AutoUI/AutoUI";
import { getApi } from "../../apiService";

import useSWR from 'swr'
import { useAppContext } from "../../appContext";
import { ConfirmModal } from "../ModalDialogs/confirmModal";


export const DataSourceConfigEdit = ({value:providedValue, onChange}:{
    value:DataSource, 
    onChange:(value:any)=>any
    
}) => {


    const [value, _setValue] = useState(providedValue)
    const [dialog, setDialog] = useState<any>()
    const [hasChanged, setHasChanged] = useState<any>()
    const setValue = (modifiedDs:DataSource)=>{
        _setValue(modifiedDs)
        onChange(modifiedDs)
        if (JSON.stringify(modifiedDs)!=JSON.stringify(providedValue)){
            setHasChanged(true)
        }
    }

    
    const {currentAppProject, isAppModified, setError} = useAppContext()

    function loadIndexStatus(){
        if (!value.index_id) return
        return getApi().getIndex(value.index_id).then((res)=>{
            return res.indexing_progress
        })
    }

    
    
   

    const loadSchema = (type:string)=>{
        return getApi().getMetaSchema("datasources", value.type).then((res)=>{
            res.properties["type"]["readOnly"] = true  
            return res
            //setIsLoading(false)
        })
    }

    const {data:schema, isLoading} = useSWR(`datasources/${providedValue?.type}`,()=>loadSchema(providedValue?.type))

const reindex = (source_name:string|null=null)=>{
    if (!isAppModified && !hasChanged){
        getApi().reindexDatasource(currentAppProject.id, value.uuid).catch((err)=>{
            setError("Sorry, something went wrong","Reindexing failed")
        })
    }
    else{
        setDialog(<ConfirmModal caption="Reindex datasource"  question="You need to first save all changes before reindexing datasource" onOk={()=>{setDialog(undefined)}}/>)
    }
}


 return(

     <Box alignItems="start" justifyItems="start">
        {dialog}
        {isLoading?(<Spinner/>):(
            <>
            <AutoUI value={value} onValueChange={setValue} schema={schema} excludeFields={["type", "uuid"
            //,"configuration.connector"
            ]}/>
            </>
        )}
        {/* {schema?.properties?.configuration?.properties?.connector!=undefined &&(
            <Box>

            "Connector"
            </Box>
        )} */}
        {!isLoading && currentAppProject && value?.uuid&&(
            <Button size="xs" variant="outline" colorScheme="brand" onClick={()=>reindex()}>
                Reindex datasource
            </Button>
        )}
    </Box>
      )  
}
