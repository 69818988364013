import {
    Editable,
    EditableInput,
    EditablePreview,
    useEditableControls,
    IconButton,
    ButtonGroup,
    Flex,
    Input,
    EditableProps,
  } from '@chakra-ui/react'
import { IconEdit } from '@tabler/icons-react'
import { IconCheck, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'

export function EditableField({ label, value, onApply, required, ...rest }: {
  label?: string,
  value: string,
  required?: boolean,
  onApply: (value: string) => void
  [key: string]: any
}) {
    /* Here's a custom control */
  const [tmpValue, setTmpValue] = useState<string>()
  useEffect(() => {
    setTmpValue(value || "")
  }, [value])
  
  return (

    <Editable width="100%" value={tmpValue} _hover={{ border: "1px solid gray" }} border={(required && !tmpValue) ? "1px solid red" : "1px dotted lightgray"} color={tmpValue ? "#050505" : "#cacaca"} borderRadius={5} p="0px 4px" {...rest}>
      <EditablePreview width="100%" />

      <EditableInput width="100%" onChange={(e) => setTmpValue(e.target.value)} onBlur={() => onApply(tmpValue)} />
      </Editable>
    )
  }