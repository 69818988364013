import { Box, Button, HStack, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Stack, Text } from "@chakra-ui/react"
import { NotificationsCenter } from "../Notifications/notificationions"
import { Logo } from "../CommonIcons/commonIcons"
import { IconCreditCard, IconDiscountCheck, IconLogout, IconUser, IconUsersGroup } from "@tabler/icons-react"
import { useNavigate } from "react-router-dom"
import { IconMenu2 } from "@tabler/icons-react"
import { useAppContext } from "../../appContext"
import { IconUserCog } from "@tabler/icons-react"
import { Link as ReactRouterLink } from 'react-router-dom'
import { IconUsers } from "@tabler/icons-react"

const AppHeader=({showMenuBtn, onMenuBtnClick}:{
    showMenuBtn?:boolean
    onMenuBtnClick?:()=>any
})=>{
    const navigate = useNavigate()
    const {currentUser, logOut} = useAppContext()
    return (
    <Stack direction="row" justify="space-between" p="5px" 
    position="sticky" top="0"  
    height="50px" width="100%"
            background="white" zIndex={900}  > 
        <HStack spacing={1}>
            {showMenuBtn && <IconButton variant="ghost" icon={<IconMenu2/>} aria-label="Menu" onClick={()=>onMenuBtnClick()}></IconButton>}
            <Box  onClick={()=>navigate("/")}>
            <Logo className="logo-icon" size={!showMenuBtn?"large":"medium"} withText/>
            </Box>
        </HStack>

        <Stack direction="row"  spacing='1' align="start">
            {/* <NotificationsCenter/> */}

            <Popover>
                <PopoverTrigger>
                <IconButton
                
                variant="ghost"
                aria-label='Notifications Center'
                icon={<IconUser />}
                />
                </PopoverTrigger>
                <PopoverContent  width="auto">
                <PopoverArrow/>
                {/* <PopoverCloseButton /> */}
                <PopoverHeader><Text marginRight="40px" fontSize="12px">{currentUser?.name}</Text></PopoverHeader>
                <PopoverBody >
                    <Stack direction="column" spacing="1" align="stretch">
                    
                        <Button as={ReactRouterLink} to="/settings/my-account" size="xs" width="100%" justifyContent="start" leftIcon={<IconUserCog/>} variant="ghost">My account</Button>
                        <Button as={ReactRouterLink} to="/settings/team" size="xs" width="100%" justifyContent="start" leftIcon={<IconUsers/>} variant="ghost">My team / organization</Button>
                                {/* <Button as={ReactRouterLink} to="/settings/manage-plan" size="xs" width="100%" justifyContent="start" leftIcon={<IconDiscountCheck/>} variant="ghost">Manage my plan</Button> */}
                        <Button as={ReactRouterLink} to="/settings/billing" size="xs" width="100%" justifyContent="start" leftIcon={<IconCreditCard/>} variant="ghost">Billing</Button>
                        <Button size="xs" width="100%" justifyContent="start" leftIcon={<IconLogout/>} variant="ghost"
                        onClick={()=>{
                            logOut()
                            navigate("/login?force=true")
                        }}
                        >Logout</Button>
                    </Stack>
                </PopoverBody>
            </PopoverContent>
            </Popover>
        </Stack>
    </Stack>)

    }

export default AppHeader