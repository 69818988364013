
import { Box, HStack, Popover, PopoverContent, PopoverTrigger, Stack, Tag, TagCloseButton, TagLabel, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import useSWR from "swr";
import { getApi } from "../../apiService";
import { SearchFilter } from "../SearchFilter/searchFilter";





const AccountsFilter = ({onAccountSelected, account, rootMode=false}:{
    
    onAccountSelected:(account:{name:string,id:string,defaultAppId:string})=>any,
    account?:{name:string,id:string,defaultAppId:string},
    rootMode:boolean
    
}) => {

    const [query,setQuery] = useState("")
    const [queryTemp,setQueryTemp] = useState("")
    const { isOpen, onOpen, onClose } = useDisclosure()

    
    const { data:accounts, isLoading} = useSWR(
        `accounts?q=${query}&rootMode=${rootMode}`,
        async ()=>{
            if (query){
                return getApi().getAccounts(query, rootMode)
            }
            else {
                return null
            }
        },
        {
            revalidateIfStale:true,
            revalidateFirstPage:false
        }
      );

      

    

    return (
    <Stack>
        <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        ////onClose={onClose}
        //placement='bottom'
        closeOnBlur={true}
      >
        <PopoverTrigger>
        <Box>
        <SearchFilter placeholder="Select account" 
        //onFocus={()=>onOpen()}
        //onBlur={()=>onClose()}
        //search={query}
        onApply={(value)=>{
            if(value){
                onOpen()
                setQuery(value)
            }
            else{
                setQuery(value)
                onClose()
            }
            }} />
        </Box>
        </PopoverTrigger>
        <PopoverContent>
        <Stack >
            {!isLoading && !accounts?.length && <Text>No matching accounts found</Text>}
            {accounts?.map((account,i)=>{
                return (
                    <Box key={i} onClick={()=>{
                            onClose()
                            onAccountSelected(account as any)
                        }} cursor="pointer" p="5px" border="1px solid #cacaca" rounded="md" m="5px" _hover={{border:"1px solid black"}}>
                        <Text>{account.name}</Text>
                        <Text fontSize="xs">{account.id}</Text>
                    </Box>
                )
            })}
            </Stack >
            </PopoverContent>
      </Popover>
      <HStack>
      {account && <Tag >
        <TagLabel>

        {account.name}
        </TagLabel>
        <TagCloseButton onClick={()=>onAccountSelected(undefined)} />
      </Tag>}
      </HStack>

    </Stack>
    )

}

export { AccountsFilter };


