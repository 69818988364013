

import { useEffect, useState } from "react";


import { Link as ReactRouterLink } from 'react-router-dom'
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";

import { useAppContext } from "../appContext";
import { FaUser } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { Logo } from "../components/CommonIcons/commonIcons";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Button, Card, Flex, FormControl, FormLabel, HStack, Heading, IconButton, Image, Input, Kbd, Link, PinInput, PinInputField, Spinner, Stack, Text, Tooltip, VStack, useToast } from "@chakra-ui/react";
import { IconBook2, IconBrandChrome, IconCopy, IconDownload, IconFileTypeZip, IconFolder, IconLogout, IconSettings, IconWand } from "@tabler/icons-react";
import { IconSwitchHorizontal } from "@tabler/icons-react";
import { IconCheck } from "@tabler/icons-react";
import IntegrationsPage from "./integrationsPage";
import { DataSourcesSection } from "../components/DataSourcesEdit/dataSourcesSection";
import { AppProject } from "../models/dataModel";
import AppSelect from "../components/AppSelect/appSelect";
import AppSkillsEdit from "../components/AppDetail/appSkillsEdit";
import { InfoModal } from "../components/ModalDialogs/infoModal";
import { IconArrowBigRightLines } from "@tabler/icons-react";
import { TfiLayoutTabMin } from "react-icons/tfi";
import useSWR from 'swr'
import { GeniouslyCommander, Task, TaskComponent, GeniouslyThemeProvider, GeniouslyContextProvider } from "geniously-chat-ui"
import { useApiEndpoint } from "../utils/useApiHook";
import { use } from "echarts";

export default function ChatPage() {

    const { currentUser, currentAppProject, setCurrentAppProject } = useAppContext()
    const navigate = useNavigate()
    const APP_URL = window.location.origin
  const { sessionId: sessionIdParam } = useParams()

  const [openMessageId, setOpenMessageId] = useState<string>();
  const [sessionId, _setSessionId] = useState<string>();
  useEffect(() => {
    if (sessionIdParam && sessionIdParam != sessionId) {
      _setSessionId(sessionIdParam)
    }
  }, [sessionIdParam]);
  const setSessionId = (sessionId: string) => {
    _setSessionId(sessionId)
    if (sessionId) {
      navigate(`/chat/${sessionId}`)
    } else {
      navigate(`/chat`)
    }
  }




  const taskDeleteEndpoint = useApiEndpoint("PATCH", "/tasks/{id}/deactivate")
    const [notificationMessage, setNotificationMessage] = useState<string>();
    function handleNotificationClick(messageId: string, sessionId: string) {
      setOpenMessageId(messageId)
    }
    const { data: tasks, isLoading: isLoadingTasks, mutate: reloadTasks } = useSWR(`/tasks`, () => {
        console.log("Loading token...")
        return  getApi().authorize().then((headers) => {
          console.log("Loading tasks...")
          return fetch(process.env.REACT_APP_API_URL + `/tasks?active=true`, { headers: headers }).then((response => response.json())).then(data => {
            let tasks = (data as any[])
            console.log("tasks", tasks)
            return tasks
          })
        })
      })


    return (
      currentUser && (
        <Stack
          align="center"
          direction="column"
          className="general-page"
          //height="100vh"
          width="100%"
          overflow="hidden"
        >
          {/* <Stack align="center">
            <Flex
              direction="row"
              position="sticky"
              top="0px"
              justify="space-between"
              width="calc(100vw - 25px)"
            >
              <Box onClick={() => navigate("/")} p="10px">
                <Logo className="logo-icon" size={"large"} withText />
              </Box>
              <HStack>
                <IconButton
                  aria-label="Settings"
                  background="white"
                  margin="5px"
                  as={ReactRouterLink}
                  to="/settings"
                  icon={<IconSettings />}
                  variant="ghost"
                />
                <Tooltip
                  label={`Logged as ${currentUser.name || currentUser.email}`}
                  placement="bottom-end"
                >
                  <Button
                    background="white"
                    margin="5px"
                    as={ReactRouterLink}
                    to="/login"
                    leftIcon={<IconLogout />}
                    variant="ghost"
                  >
                    Logout
                  </Button>
                </Tooltip>
              </HStack>
            </Flex>
          </Stack> */}

          <Flex justify="space-between" width="100%"></Flex>

          <Card
            m="20px"
            p="10px"
            alignSelf="center"
            shadow="xl"
            border="0.5px solid #99cdcdcd"
            maxWidth="800px"
          >
            <HStack rounded={5} alignSelf="start" p="2px">
              <Box>
                <Text fontSize="md" fontWeight="bold">
                  Tasks
                </Text>
              </Box>
              <Badge alignSelf="start">
                {isLoadingTasks ? "loading" : tasks?.length || 0}
              </Badge>
            </HStack>
            <Stack m="10px 0px" maxH="70vh" p="10px 10px 50px" overflow="auto">
              {isLoadingTasks && (
                <Stack width="30vw" height="30vh" align="center" justify="center">
                  <Spinner size="lg" />
                </Stack>
              )}
              <GeniouslyThemeProvider>
                <GeniouslyContextProvider
                  apiUrl={process.env.REACT_APP_API_URL}
                  appId={"default"}
                  appUrl={window.location.origin}
                  authorizeFunc={() => getApi().authorize()}
                >
                  {tasks?.map((task, i) => (
                    <TaskComponent
                      key={i}
                      task={task}

                      onClick={() => {
                        setSessionId(task.session_id);
                      }}
                      onDelete={() =>
                        taskDeleteEndpoint.execute({ id: task.id }).then(() => {
                          reloadTasks()
                        })
                        // return getApi()
                        //   .authorize()
                        //   .then((headers) =>
                        //     fetch(
                        //       process.env.REACT_APP_API_URL +
                        //         `/tasks/${task.id}/deactivate`,
                        //       { method: "PATCH", headers: headers }
                        //     )
                        //   )
                        //   .then((response) => response.json())
                        //   .then((data) => {
                        //     reloadTasks();
                        //   });
                      }
                    />
                  ))}

                  {tasks?.length <= 3 && (
                    <Stack
                      minW="20vw"
                      align="center"
                      opacity={0.7}
                      m="20px 0px 0px"
                    >
                      <Stack spacing="0px" align="center">
                        {tasks?.length === 0 && (
                          <Text fontWeight={900} color="gray">
                            No active tasks so far
                          </Text>
                        )}
                      </Stack>
                      <Stack spacing="10px" align="center">
                        <Text fontSize="sm" fontWeight={600} color="gray">
                          Looking for inspiration? Ask our assistant one to help
                          you with of these
                        </Text>
                        <Stack spacing="10px" align="center" fontSize="12px">
                          <Card
                            p="10px"
                            shadow="md"
                            rounded={5}
                            border="0.5px solid lightgray"
                          >
                            <Text>
                              Give me summary of my last emails from [name]{" "}
                            </Text>
                          </Card>
                          <Card
                            p="10px"
                            shadow="md"
                            rounded={5}
                            border="0.5px solid lightgray"
                          >
                            <Text>Schedule a meeting with [name] at 5pm</Text>
                          </Card>
                          <Card
                            p="10px"
                            shadow="md"
                            rounded={5}
                            border="0.5px solid lightgray"
                          >
                            <Text>
                              Automatically label my emails in the inbox and
                              based on the urgency
                            </Text>
                          </Card>
                          <Card
                            p="10px"
                            shadow="md"
                            rounded={5}
                            border="0.5px solid lightgray"
                          >
                            <Text>
                              Remind me if some of my team members didn't
                              replied to if I've have given them a task and
                              followup with them
                            </Text>
                          </Card>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </GeniouslyContextProvider>
              </GeniouslyThemeProvider>
            </Stack>
          </Card>

          <Box position="fixed" bottom="0" left="0" right="0">
            <GeniouslyCommander
              appId={"default"}
              apiUrl={process.env.REACT_APP_API_URL}
              appUrl={APP_URL}
              visitorId={"-"}
              open={sessionIdParam ? true : false}
              openMessageId={openMessageId}
              notificationMessage={notificationMessage}
              collectContext={() =>
                Promise.resolve({
                  localTime: new Date().toString(),
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })
              }
              authDataRetriever={() => getApi().authorize()}
              onSessionChanged={(sessionId, sessionName, sessionType) => {
                setSessionId(sessionId);
                if (sessionType == "list_building") {
                  navigate(`/lists/${sessionId}`)
                }

              }}
              sessionId={sessionId as any}
              onClose={() => {
                setSessionId(null);
              }}
              //onExecuteAction={(actionId, args) => onExecuteAction(actionId, args)}
            />
          </Box>
        </Stack>
      )
    );

}
