



import { useState, useEffect, useMemo, useCallback } from "react";

import Editor, { useMonaco } from "@monaco-editor/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Badge,
  Text,
  Stack,
  Box,
  Input,
  Checkbox,
  Spinner,
  Switch,
} from '@chakra-ui/react'


interface CodeFeedback {
  is_error: boolean;
  lineno: number;
  step_name: string;
  text: string;
}

export const CodeEditor = ({
  workflowCode,
  issues,
  onChange,
  height
}: {
  workflowCode: string
  issues?: CodeFeedback[]
  onChange?: (code: string) => any;
  height?: string

}) => {


  const codeValue = workflowCode || "\n";

  const monaco = useMonaco();
  const [editorInstance, setEditorInstance] = useState<any>();



  useEffect(() => {
    if (!monaco) return;
    let monacoModel = monaco.editor.getModel(
      monaco.Uri.parse("inmemory://model/1")
    );
    if (monacoModel && issues) {

      monaco.editor.setModelMarkers(monacoModel, "parent", []);
      monaco.editor.setModelMarkers(
        monacoModel,
        "parent",
        issues.map((feedback: CodeFeedback) => ({
          startLineNumber: feedback.lineno,
          endLineNumber: feedback.lineno,
          startColumn: 1,
          endColumn: 100,
          message: feedback.text,
          severity: feedback.is_error
            ? monaco.MarkerSeverity.Error
            : monaco.MarkerSeverity.Warning,
        }))
      );
      editorInstance.createDecorationsCollection(
        issues.map((feedback: CodeFeedback) => ({
          range: new monaco.Range(feedback.lineno, 1, feedback.lineno, 1),
          options: {
            isWholeLine: true,
            className: feedback.is_error
              ? "myContentClassError"
              : "myContentClassInfo",
            glyphMarginClassName: feedback.is_error
              ? "myGlyphMarginClassError"
              : "myGlyphMarginClassInfo",
            hoverMessage: { value: feedback.text },
          },
        }))
      );
      return () => {
        //editorInstance.dispose();

      }
    }
  })


  return (
    <Stack>
      {codeValue && (
        <Editor
          height={height || "70vh"}
          defaultPath="inmemory://model/1"
          defaultLanguage="python"
          value={codeValue}
          onMount={(editor, monaco) => {
            setEditorInstance(editor);
          }}
          options={{
            glyphMargin: true,
            minimap: {
              enabled: false,
            },
          }}
          onChange={(value: string) => onChange(value)}
        />
      )}
    </Stack>
  );
};
