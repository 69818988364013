


import { useCallback, useEffect, useMemo, useState } from "react";


import {
    Input,
    InputGroup,
    InputLeftElement,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
} from '@chakra-ui/react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'

import { useNavigate, useParams, useSearchParams } from "react-router-dom";



import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Checkbox, CloseButton, Flex, HStack, Heading, IconButton, MenuGroup, Portal, Progress, SimpleGrid, Skeleton, Spinner, Stack, Tag, Text, Wrap, WrapItem, background, keyframes, useToast } from "@chakra-ui/react";


import { AutoUI, ChatInput, GeniouslyCommander, ChatService, ChatStatusBadge, GeniouslyThemeProvider, StatusMessage, CommandResultMessage, MarkdownContent } from "geniously-chat-ui";

import { IconArrowsDiagonal2, IconBoxAlignBottomLeftFilled, IconChevronDown, IconChevronRight, IconChevronUp, IconEyeCheck, IconEyeFilled, IconMapPin, IconMapPin2, IconPlayerPlay, IconPlus, IconRefresh, IconThumbUp } from "@tabler/icons-react";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";



import { IconX } from "@tabler/icons-react";

import { IconArrowsDiagonalMinimize } from "@tabler/icons-react";
import { IconWindowMaximize } from "@tabler/icons-react";
import { IconMapPinFilled } from "@tabler/icons-react";
import { IconSparkles } from "@tabler/icons-react";
import { ConfirmModal } from "../ModalDialogs/confirmModal";
import { IconListSearch } from "@tabler/icons-react";
import { useApiFetch } from "../../utils/useApiHook";
import { IconTablePlus } from "@tabler/icons-react";


interface CommandAction {
    // Define the structure of CommandAction here
    // For example:
    action_id: string;
    args: any;
    title: string;
}

interface OpSessionStage {
    id: string;
    name: string;
    is_final: boolean
    description?: string; // Optional string
    prompt?: string;      // Optional string
    args: { [key: string]: any }; // Dictionary with string keys and any type values
    args_schema: { [key: string]: any }; // Dictionary with string keys and any type values
    validation_error?: string | null; // Optional string or null
    info_message?: string | null;     // Optional string or null
    actions?: CommandAction[] | null; // Optional array of CommandAction or null
}
interface OpInfo {
    name: string,
    arg_schema: JSONSchema,
    category: "filter" | "add_column" | "modify_column" | "import" | "transformation" | "other"
    op_type: string
    supports_prompting: boolean
    supports_preview: boolean
    interactive: boolean

}
interface ListOp { id: string, op_type: string, is_ready: boolean, name: string, arg_schema: JSONSchema, stages?: OpSessionStage[], all_stages: string[], kwargs?: { [key: string]: any } }
const ListOpDialog = ({ opType, list_id, argValues, argSchema: argSchemaPreset, listOp, interactive: interactivePreset, supports_prompting, supports_preview: supports_previewPreset, execOptions, chatService, onClose }: {

    list_id: string,
    opType: string, 
    argSchema?: JSONSchema,
    interactive?: boolean,
    listOp?: ListOp,
    supports_prompting?: boolean,

    supports_preview?: boolean,
    argValues?: { [key: string]: any },
    chatService: ChatService,
    onClose: () => void,
    execOptions?: { [key: string]: any }

}): JSX.Element => {

    const opInfoFetch = useApiFetch<OpInfo>(`/lists/${list_id}/op-info/${opType}`, { swrOptions: { refreshInterval: 60000 } })
    const argSchema = argSchemaPreset || opInfoFetch.data?.arg_schema
    const supports_preview = opInfoFetch.data?.supports_preview || supports_previewPreset
    const interactive = opInfoFetch.data?.interactive || interactivePreset

    const [actionData, setActionData] = useState(argValues || {})
    const [sentActionData, setSentActionData] = useState(argValues || {})
    const [error, setError] = useState<string>()
    const [info_message, setInfoMessage] = useState<string>()
    const [previewWidget, setPreviewWidget] = useState<any | "loading">()
    const [statusMessage, setStatusMessage] = useState<StatusMessage>()
    const [windowState, setWindowState] = useState<"normal" | "minimized" | "maximized">("normal");
    const [awaitingAction, _setAwaitingAction] = useState<string>()
    const [previewMaxCount, setPreviewMaxCount] = useState<number>(5)
    const [finishMaxCount, setFinishMaxCount] = useState<number | null>()
    const setAwaitingAction = (action) => {

        _setAwaitingAction(action)
    }
    const [currentListOp, _setCurrentListOp] = useState<ListOp>(listOp)
    const [currentInitSession, _setInitCurrentSession] = useState<{
        list_op: ListOp,
        current_stage_id: string,


    }>()
    //const [currentSchema, setCurrentSchema] = useState<JSONSchema>(argSchema)
    const [logs, setLogs] = useState<{ log: string, row_index?: number }[]>([])
    const logsGrouped = useMemo(() => {
        // Group logs by row_index
        let groups = logs.reduce((acc, log) => {
            if (log.row_index !== undefined) {
                if (!acc[log.row_index]) {
                    acc[log.row_index] = []
                }
                acc[log.row_index].push(log.log)
            }
            return acc
        }, {})
        return groups
    }, [logs])

    const addLog = (log, row_index) => {
        console.log(row_index, log)
        setLogs((previous) => [...(previous || []), { log: log, row_index: row_index }])
    }



    const setInitCurrentSession = (session) => {
        _setInitCurrentSession(session)
        if (session?.list_op) {
            _setCurrentListOp(session.list_op)
            let currentStage = session.list_op?.stages?.find((stage) => stage.id == session.current_stage_id)
            if (currentStage) {
                setActionData(currentStage.args || {})
            }

        }
    }
    const setCurrentListOp = (listOp) => {
        _setCurrentListOp(listOp)
        if (currentInitSession) {
            _setInitCurrentSession({ ...currentInitSession, list_op: listOp })
        }


    }


    useEffect(() => {
        if (listOp) {
            setCurrentListOp(listOp)
            //setCurrentSchema(listOp.arg_schema)
            setActionData(listOp.kwargs || {})
        }
    }, [listOp])



    function close() {
        if (previewWidget) {

            onClose()
        }
        else {
            onClose()
        }
    }

    const onNewMessageReceivedCallback = useCallback((msg) => {
        if (msg?.widgets?.length && msg.widgets[0].widget_type == "list_preview") {
            setPreviewWidget(msg.widgets[0] as any)
        }
    }, [])
    const statusUpdateCallback = useCallback((statusMsg: StatusMessage) => {
        setAwaitingAction(undefined)
        if (statusMsg.message) {
            setStatusMessage(statusMsg)
            addLog(statusMsg.message, statusMsg?.metadata?.row_index)
        }
        else if (statusMsg?.metadata?.list_op || statusMsg?.metadata?.preview_data) {
            if (statusMsg?.metadata?.list_op) {
                setCurrentListOp(statusMsg.metadata.list_op)
            }
            if (statusMsg?.metadata.state == "error") {
                setError("Error running operation")
            }
            if (statusMsg?.metadata.preview) {

                setPreviewWidget({
                    ...statusMsg?.metadata.preview,
                    list_op: (statusMsg.metadata.list_op || statusMsg?.metadata.preview.list_op),
                    preview_data: (statusMsg.metadata.preview_data || statusMsg?.metadata.preview.preview_data)
                })
            }
            else if (previewWidget && typeof (previewWidget) === "object") {
                setPreviewWidget({
                    ...previewWidget,
                    list_op: (statusMsg.metadata.list_op || previewWidget.list_op),
                    preview_data: (statusMsg.metadata.preview_data || previewWidget.preview_data)
                })
            }
            setStatusMessage(statusMsg)
        }

    }, [])

    useEffect(() => {
        chatService.subscribe({
            onNewMessageReceived: onNewMessageReceivedCallback,
            onStatusUpdate: statusUpdateCallback
        })
    }, [chatService])

    useEffect(() => {
        if ((interactive || currentListOp?.stages) && !currentInitSession) {
            initInteractiveSession()
        }
    }, [currentListOp, currentInitSession])

    function callCommand(args: any, timeout: number = 90) {
        setLogs([])
        setSentActionData(args)
        setError(undefined)
        const metadata = { "$metadata": { ...(execOptions || {}), pause_after_steps: previewMaxCount } }


        setPreviewWidget("loading")
        if (currentListOp) {

            setAwaitingAction("finish")
            let newCurrentListOpState = undefined
            if (!currentSessionStage) {
                newCurrentListOpState = { ...currentListOp, kwargs: args }
            }
            else {
                newCurrentListOpState = {
                    ...currentListOp, stages: currentListOp.stages?.map((stage) => {
                        if (stage.id == currentSessionStage.id) {
                            return { ...stage, args: args }
                        }
                        return stage
                    })
                }
                setInitCurrentSession({ ...currentInitSession, list_op: newCurrentListOpState })
            }

            setCurrentListOp(newCurrentListOpState)
            return chatService.sendCommand({ "type": "command", "action_id": opType, "args": { "preview": true, "list_op": currentListOp, op_args: args, ...metadata } }, { waitForResult: true, returnResultMessage: true, timeoutSec: timeout }).then((res: CommandResultMessage) => {
                if (res.success) {
                    setPreviewWidget("loading")
                }
                else if (res.error) {
                    setError(res.error)
                }
                else if (res.result?.command_accepted) {
                    setError("Error finishing operation")
                }
            }).finally(() => {
                setAwaitingAction(undefined)
            })
        }
        else {
            return chatService.sendCommand({ "type": "command", "action_id": opType, "args": { ...(args || {}), ...metadata } }, { waitForResult: true, returnResultMessage: true, timeoutSec: timeout }).then((res) => {
                setPreviewWidget(res.result?.preview)

                if (res.error) {
                    setError(res.error)
                }
                setAwaitingAction(undefined)

            }).catch((err) => {
                console.error(err)
                if (typeof (err) === "string") {
                    setError(err)
                }
                else {
                    setError(err?.message || "Error running command")
                }

                //setPreviewWidget(undefined)
                setAwaitingAction(undefined)
            })
        }
    }

    function replaceDollarValues(obj, inputObject) {
        // Helper function to get the value from inputObject based on a path
        function getValueFromPath(path, inputObj) {
            return path.split('.').reduce((acc, key) => acc && acc[key], inputObj);
        }

        // Helper function to recursively replace values
        function recursiveReplace(currentObj) {
            if (Array.isArray(currentObj)) {
                return currentObj.map(recursiveReplace);
            } else if (currentObj !== null && typeof currentObj === 'object') {
                let newObj = {};
                for (let key in currentObj) {
                    if (currentObj.hasOwnProperty(key)) {
                        let value = currentObj[key];
                        if (typeof value === 'string') {
                            if (value === '$') {
                                newObj[key] = inputObject;
                            } else if (value.startsWith('$.')) {
                                newObj[key] = getValueFromPath(value.slice(2), inputObject);
                            } else {
                                newObj[key] = value;
                            }
                        } else {
                            newObj[key] = recursiveReplace(value);
                        }
                    }
                }
                return newObj;
            }
            return currentObj;
        }

        return recursiveReplace(obj);
    }

    function callAction(action: CommandAction) {

        let args = replaceDollarValues(action.args, actionData)
        setPreviewWidget(undefined)
        chatService.sendCommand({ "type": "command", "action_id": "op-action", "args": { "list_op": currentListOp, op_action_id: action.action_id, op_action_args: args } }, { waitForResult: true, returnResultMessage: true, timeoutSec: 90 }).then((res: CommandResultMessage) => {
            if (res.success) {
                if (res.result && typeof (res.result) === "object") {
                    if (res.result["$call"] && res.result["$call"].type == "command") {
                        let call = res.result["$call"]
                        delete res.result["$call"]
                        if (call.action_id == "open-url") {
                            window.open(call.args.url, "_blank")
                        }
                    }
                    setCurrentListOp({
                        ...currentListOp, stages: currentListOp.stages.map((s, i) => {
                            if (s.id == currentSessionStage.id) {
                                return { ...currentSessionStage, ...res.result }

                            }
                            else {
                                return s
                            }
                        })
                    })
                }
                else if (res.error) {
                    setError(res.error)
                }
            }
        }).finally(() => {
            setAwaitingAction(undefined)
        })
    }

    function initInteractiveSession() {
        setAwaitingAction("next")
        setPrompt("")
        let filteredActionData = Object.entries(actionData).reduce((result, [k, v]) => {
            if (!k.startsWith("$")) {
                result[k] = v;
            }
            return result;
        }
            , {});
        chatService.sendCommand({ "type": "command", "action_id": "op-init-session", "args": { "op_type": opType, "list_op": currentListOp } }, { waitForResult: true, returnResultMessage: true, timeoutSec: 360 }).then((res: CommandResultMessage) => {
            if (res.success) {
                setInitCurrentSession(res.result)

            }
            else if (res.error) {
                setError(res.error)
            }
        }).finally(() => {
            setAwaitingAction(null)
        })
    }

    function nextStep() {
        setAwaitingAction("next")
        setPrompt("")
        let filteredActionData = Object.entries(actionData).reduce((result, [k, v]) => {
            if (!k.startsWith("$")) {
                result[k] = v;
            }
            return result;
        }
            , {});
        chatService.sendCommand({ "type": "command", "action_id": "op-next-step", "args": { "op_type": opType, "session": currentInitSession, op_args: filteredActionData } }, { waitForResult: true, returnResultMessage: true, timeoutSec: 360 }).then((res: CommandResultMessage) => {
            if (res.success) {
                setInitCurrentSession(res.result)

            }
            else if (res.error) {
                setError(res.error)
            }
        }).finally(() => {
            setAwaitingAction(null)
        })
    }
    const currentSessionStage = useMemo(() => {
        if (!currentInitSession) return undefined
        return currentInitSession.list_op.stages?.find((stage) => stage.id == currentInitSession.current_stage_id)
    }, [currentInitSession])
    const currentSessionStageIndex = useMemo(() => {
        if (!currentInitSession) return undefined
        return currentInitSession.list_op.stages.findIndex((stage) => stage.id == currentInitSession.current_stage_id)
    }, [currentSessionStage])
    useEffect(() => {
        if (currentSessionStage) {
            if (JSON.stringify(currentSessionStage.args) != JSON.stringify(actionData)) {
                setActionData(currentSessionStage.args || {})
            }
        }
        setError(undefined)
    }, [currentSessionStage])
    useEffect(() => {
        if (actionData && currentSessionStage) {
            if (JSON.stringify(actionData) !== JSON.stringify(currentSessionStage.args)) {
                setInitCurrentSession({
                    ...currentInitSession, list_op: {
                        ...currentInitSession.list_op, stages: currentInitSession.list_op.stages.map((stage) => {
                            if (stage.id == currentSessionStage.id) {
                                return { ...stage, args: actionData }
                            }
                            return stage
                        })
                    }
                })
            }

        }
    }, [actionData])

    const pending_stages = useMemo(() => {
        if (currentListOp) {
            return currentListOp.all_stages?.filter((stage) => !currentInitSession?.list_op.stages?.find((s) => s.name == stage))
        }
    }, [currentListOp])

    const [prompt, setPrompt] = useState<string>("")
    const [promptWorking, setPromptWorking] = useState<boolean>()
    function generatePrompt() {
        if (prompt) {
            setPromptWorking(true)
            setInfoMessage(undefined)
            const filteredActionData = Object.entries(actionData).reduce((result, [k, v]) => {
                if (!k.startsWith("$")) {
                    result[k] = v;
                }
                return result;
            }, {});
            return chatService.sendCommand({ "type": "command", "action_id": "op-prompt", "args": { "op_type": opType, "args": filteredActionData, "init_session": currentInitSession, "prompt": prompt } }, { waitForResult: true, returnResultMessage: true, timeoutSec: 35 }).then((res: CommandResultMessage) => {

                setPrompt("")
                if (res.success) {
                    if (res.result.init_session)
                        setInitCurrentSession(res.result.init_session)
                    else {
                        setActionData(res.result.args)
                        setInfoMessage(res.result.info_message)
                    }
                }
                else if (res.error) {
                    setError(res.error)
                }
            }).finally(() => {
                setPromptWorking(undefined)
            })
        }
    }
    const [modal, setModal] = useState<JSX.Element>()

    function confirmAndFinish(maxCount: number = undefined, skipConfirmChanges: boolean = false) {
        if (!skipConfirmChanges && (JSON.stringify(actionData) !== JSON.stringify(sentActionData) && (previewWidget && typeof (previewWidget) !== "string"))) {
            setModal(<ConfirmModal caption="Confirm changes" question="The inputs have changed, are you sure you want to confirm operation without executing preview first?" onOk={() => {
                confirmAndFinish(maxCount, true)
                setModal(undefined)
            }}
                onCancel={() => {
                    setModal(undefined)
                }}
            />)

        }
        else {
            setAwaitingAction("finish")
            chatService.sendCommand({
                "type": "command", "action_id": "accept-preview", "args": {
                    "list_op": currentListOp || previewWidget.list_op, "preview_data": previewWidget?.preview_data, "$metadata": {
                        pause_after_steps: maxCount || finishMaxCount
                    }
                }
            }, { waitForResult: true, returnResultMessage: true }).then((res: CommandResultMessage) => {
                if (res.success) {
                    onClose()
                }
                else if (res.error) {
                    setError(res.error)
                }
                else if (res.result?.command_accepted) {
                    setError("Error approving changes")
                }
            }).finally(() => {
                setAwaitingAction(undefined)
            })
        }
    }

    return (
        windowState == "minimized" ? (
            <Portal>
                <Box position="absolute" bottom="15px" shadow="xl" height="60px" left="15px" m="15ppx" zIndex={15} >
                    <HStack p="10px 0px 10px 20px" borderWidth="6px"
                        onClick={() => setWindowState("normal")} cursor="pointer" borderRadius={8} width="100%" display="flex" justifyContent="center" alignItems="center">

                        <Text fontSize="xl">{opType}</Text>
                        <IconButton mr="10px" justifySelf="end" variant="ghost" aria-label="minimize" icon={<IconWindowMaximize />}

                        />
                    </HStack>
                </Box>
            </Portal >
        ) : (
            <Modal
                isCentered
                autoFocus={false}
                onClose={() => {
                    close()
                }}
                trapFocus={false}
                closeOnOverlayClick={false}
                size={windowState == "normal" ? previewWidget ? "6xl" : "5xl" : (windowState == "maximized" ? "full" : "sm")}
                isOpen={true}>
                <ModalOverlay />
                <ModalContent width={["full", "full", windowState == "maximized" ? "full" : "80vw"]} >
                    <HStack width="100%" justify="space-between" >
                            <ModalHeader flexGrow={1} width="92%">{opInfoFetch.data?.name || <Spinner size="sm" />}</ModalHeader>
                        <HStack spacing={0}>

                            <IconButton justifySelf="end" variant="ghost" aria-label="minimize" icon={<IconBoxAlignBottomLeftFilled />}
                                onClick={() => setWindowState("minimized")}
                            />
                            < IconButton justifySelf="end" variant="ghost" icon={windowState == "maximized" ? <IconArrowsDiagonalMinimize size="20px" /> : <IconArrowsDiagonal2 size="20px" />} aria-label={windowState ? "Shrink" : "Maximize"} onClick={() => setWindowState(windowState == "maximized" ? "normal" : "maximized")} />
                            <IconButton mr="10px" justifySelf="end" variant="ghost" aria-label="minimize" icon={<IconX />}
                                onClick={() => close()}
                            />
                        </HStack>

                    </HStack>
                    <ModalBody>
                            <GeniouslyThemeProvider>
                        <Stack spacing={4}>
                                {modal}
                                {currentInitSession && (
                                    <Stack overflow="hidden" maxW="100%">
                                        <Stepper index={currentSessionStageIndex} colorScheme='red'>
                                            {currentInitSession.list_op.stages.map((stage, index) => (
                                                <Step key={index}
                                                    style={currentSessionStageIndex !== index ? { cursor: "pointer" } : undefined}
                                                    onClick={() => {
                                                        setInitCurrentSession({ ...currentInitSession, current_stage_id: stage.id })
                                                    }}>
                                                    <StepIndicator background={currentSessionStageIndex == index ? "red.500" : undefined} color="white" >
                                                        {currentSessionStageIndex == index ? <IconMapPinFilled /> :
                                                            <StepStatus
                                                                complete={<StepIcon />}
                                                                incomplete={<StepNumber />}
                                                                active={<StepNumber />}
                                                            />}
                                                    </StepIndicator>

                                                    <Box flexGrow={1} mt="15px" alignSelf="start" ml="-10px">
                                                        <StepSeparator />
                                                        {index !== currentSessionStageIndex && <StepTitle><Text ml="10px" fontSize={"xs"} textOverflow="ellipsis" whiteSpace="nowrap" >{stage.name}</Text></StepTitle>}

                                                    </Box>

                                                </Step>
                                            ))}

                                            {pending_stages?.length > 0 && pending_stages.map((stage_name, i) => (
                                                <Step key={i}>
                                                    <StepIndicator >
                                                        <StepStatus
                                                            complete={<StepIcon />}
                                                            incomplete={<StepNumber />}
                                                            active={<StepNumber />}
                                                        />
                                                    </StepIndicator>

                                                    <Box flexGrow={1} mt="15px" alignSelf="start" ml="-10px">
                                                        <StepSeparator />
                                                        <StepTitle><Text color="gray.500" ml="10px" fontSize="xs">{stage_name}</Text></StepTitle>

                                                    </Box>
                                                </Step>
                                            ))
                                            }
                                            <Step >
                                                <StepIndicator >

                                                </StepIndicator>


                                            </Step>
                                        </Stepper>
                                    </Stack>
                                )
                                }
                                {currentSessionStage && <Text fontWeight={600}>{currentSessionStage.name}</Text>}
                                <HStack spacing={1} align="stretch" >
                                    <Stack flexGrow={1} overflow="auto" minW="400px" maxW={previewWidget ? "35%" : "100%"} height="100%"  >
                                        <Stack spacing={1} width="100%" justify="space-between" height="100%" maxH="80vh" overflow="auto">

                                            {opInfoFetch.data?.supports_prompting && <HStack m="18px 0px">
                                                <InputGroup>
                                                    <InputLeftElement pointerEvents='none' m="-3px" >
                                                        <IconSparkles size="20px" color="gray" />
                                                    </InputLeftElement>
                                                    <Input shadow="md" value={prompt} onChange={e => setPrompt(e.target.value)} borderColor="gray.800" size="sm" placeholder="Just describe your instructions here" borderRadius="4px"
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                generatePrompt()
                                                            }
                                                        }}
                                                    />
                                                </InputGroup>
                                                <Button
                                                    isLoading={promptWorking}
                                                    size="sm" colorScheme="red" shadow="sm" onClick={() => generatePrompt()}>Generate</Button>
                                            </HStack>}
                                            {(currentSessionStage?.info_message || info_message) && <Stack rounded="md" backgroundColor="blue.50" p="8px 20px" maxH="120px" overflow="auto">
                                                <Text fontWeight={500} color="blue.900" fontSize="xs" whiteSpace="pre-wrap">

                                                    {currentSessionStage?.info_message || info_message}
                                                </Text>
                                            </Stack>}
                                            {/* <Text whiteSpace="pre-line">{JSON.stringify(opInfoFetch.data, null, " ")}</Text> */}
                                            <AutoUI value={actionData}
                                                allowAddNew

                                                layout="vertical"
                                                onValueChange={(val) => {
                                                    if (typeof (val) === "object")
                                                        setActionData({ ...actionData, ...val })
                                                    else
                                                        setActionData(val)
                                                }}
                                                schema={currentSessionStage?.args_schema || argSchema}
                                                handle_lookup={(lookup, fieldSchema, fieldName) => {
                                                    if (!fieldSchema?.lookup_datasource)
                                                        return
                                                    const filteredActionData = Object.entries(actionData).reduce((result, [k, v]) => {
                                                        if (!k.startsWith("$")) {
                                                            result[k] = v;
                                                        }
                                                        return result;
                                                    }, {});
                                                    return chatService.sendCommand({ "type": "command", "action_id": "op-args-lookup", "args": { "list_op": currentListOp, "op_type": opType, "lookup_datasource_key": fieldSchema.lookup_datasource, "args": filteredActionData, "query": lookup } }, { waitForResult: true, returnResultMessage: true }).then((res: CommandResultMessage) => {
                                                        if (res.success) {
                                                            return res.result
                                                        }
                                                        else {
                                                            return []
                                                        }
                                                    })
                                                }}
                                            />




                                            {(error || currentSessionStage?.validation_error) && <Stack rounded="md" backgroundColor="red.200" p="8px 20px" maxHeight="200px" overflow="auto">
                                            <Text fontWeight={600} color="red.900" fontSize="xs" whiteSpace="pre-wrap">

                                                    {error || currentSessionStage?.validation_error}
                                            </Text>
                                            </Stack>
                                            }

                                            <HStack justify="space-between" mb="10px">
                                                <HStack>
                                                    {currentSessionStage?.actions && currentSessionStage.actions.map((action, i) => (

                                                        <Button size="md"
                                                            //isLoading={awaitingAction == action.action_id}
                                                            onClick={() => callAction(action)}>{action.title}</Button>
                                                    ))}
                                                </HStack>

                                                <HStack>
                                                    {/* <Text>{JSON.stringify({ interactive: interactive || false, is_final: currentSessionStage?.is_final })}</Text> */}
                                                    {!interactive || currentSessionStage?.is_final ? (<>
                                                        {supports_preview && (
                                                            <ButtonGroup colorScheme="black" size="sm" isAttached>
                                                                <Button

                                                                    isLoading={awaitingAction == "preview"}
                                                                    leftIcon={!previewWidget ? <IconEyeCheck /> : <IconRefresh />}
                                                                    alignSelf={"end"}
                                                                    // backgroundColor="blackAlpha.800"
                                                                    // color="white"

                                                                    onClick={previewWidget! ? () => {

                                                                        setAwaitingAction("preview")
                                                                        callCommand(actionData)
                                                                    } : () => {

                                                                        setAwaitingAction("preview")
                                                                        setStatusMessage(undefined)


                                                                        callCommand(actionData).finally(() => {
                                                                            setAwaitingAction(undefined)
                                                                        })

                                                                    }}
                                                                >
                                                                    <Stack spacing={0} align="start">

                                                                        <Text>{!previewWidget ? "Show preview" : "Retry"}</Text>:
                                                                        {previewMaxCount && <Text fontSize="10px" fontWeight={400}>First {previewMaxCount} rows</Text>}
                                                                    </Stack>
                                                                </Button>
                                                                <Menu variant="outline" placement='bottom-end'>
                                                                    <MenuButton as={IconButton} icon={<IconChevronDown size="15px" />} borderLeft="1px solid #cacaca" />
                                                                    <MenuList >
                                                                        {[1, 3, 5, 10, 50].map(n => (
                                                                            <MenuItem fontSize="xs" onClick={() => setPreviewMaxCount(n)}>First {n} rows</MenuItem>
                                                                        ))}



                                                                    </MenuList>
                                                                </Menu>
                                                            </ButtonGroup>
                                                        )}
                                                        {/* {<Text>{opInfoFetch.data?.category}</Text>} */}
                                                        {(!!previewWidget || !supports_preview) && (opInfoFetch.data?.category === "import" ? (
                                                            <Menu variant="outline" placement='bottom-end'>
                                                                <MenuButton as={Button} colorScheme="green" size="sm" leftIcon={<IconTablePlus />} rightIcon={<IconChevronDown size="15px" />} borderLeft="1px solid #cacaca" >
                                                                    Import
                                                                </MenuButton>
                                                                <MenuList >
                                                                    {Array.from(new Set([previewMaxCount, 50, 100, 250, 500, 1000, 1500, 2000, 2500, 3500, 5000])).map(n => (
                                                                        <MenuItem fontSize="xs" onClick={() => confirmAndFinish(n)}>{n ? (n == previewMaxCount ? "Save and finish later" : `First ${n} rows`) : "All rows"}</MenuItem>
                                                                    ))}



                                                                </MenuList>
                                                            </Menu>
                                                        ) : <ButtonGroup size='sm' isAttached colorScheme="green">
                                                            <Button
                                                                alignSelf={"start"}
                                                                isLoading={awaitingAction == "finish"}
                                                                //isDisabled={supports_preview/* && (JSON.stringify(actionData) !== JSON.stringify(sentActionData) || (typeof (previewWidget) !== "object"))*/}
                                                                leftIcon={<IconPlayerPlay />}
                                                                onClick={() => {
                                                                    confirmAndFinish()
                                                                }}
                                                            >
                                                                <Stack spacing={0} align="start">
                                                                    {finishMaxCount && finishMaxCount == previewMaxCount ? <Text>Save and finish later</Text> : <>
                                                                        <Text>Confirm and finish</Text>
                                                                        {finishMaxCount && <Text fontSize="10px" fontWeight={400}>First {finishMaxCount} rows</Text>}
                                                                    </>}
                                                                </Stack>

                                                            </Button>

                                                            <Menu variant="outline" placement='bottom-end'>
                                                                <MenuButton as={IconButton} icon={<IconChevronDown size="15px" />} borderLeft="1px solid #cacaca" />
                                                                <MenuList >
                                                                    {Array.from(new Set([null, previewMaxCount, 50, 100, 250, 500, 1000])).map(n => (
                                                                        <MenuItem fontSize="xs" onClick={() => setFinishMaxCount(n)}>{n ? (n == previewMaxCount ? "Save and finish later" : `First ${n} rows`) : "All rows"}</MenuItem>
                                                                    ))}



                                                                </MenuList>
                                                            </Menu>

                                                        </ButtonGroup>)}

                                                    </>) : (

                                                        <HStack justify="end">
                                                            <Button
                                                                isLoading={awaitingAction == "next"}
                                                                alignSelf={"start"}

                                                                size="sm"
                                                                leftIcon={<IconChevronRight />}
                                                                onClick={() => {
                                                                    nextStep()

                                                                }}
                                                                colorScheme="brand">Next</Button>
                                                        </HStack>

                                        )}
                                                </HStack>
                                            </HStack>
                                    </Stack>
                                    </Stack>

                                    {previewWidget && !(currentSessionStage && !currentSessionStage.is_final) && (
                                        <Stack flexGrow={1} width="70%" border="1px solid gray" rounded="md" p="4px" >
                                        <Accordion defaultIndex={[0]} allowToggle index={logs?.length ? undefined : 0}>
                                            <AccordionItem>
                                                <AccordionButton>
                                                    <Box as='span' flex='1' textAlign='left'>
                                                        <Text fontSize='sm' fontWeight='bold'>Preview</Text>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>

                                                    <AccordionPanel pb={4} >
                                                    {(previewWidget == "loading" || !previewWidget.preview_data ? (
                                                            <HStack height="100%" maxH="80vh" p="5px" m="10px" justify="start">

                                            <Stack width="50%">
                                                <Skeleton height='20px' />
                                                <Skeleton height='20px' opacity={0.5} />
                                                <Skeleton height='20px' />
                                                <Skeleton height='20px' opacity={0.5} />
                                                <Skeleton height='20px' />
                                                <Skeleton height='20px' opacity={0.5} />
                                                <Skeleton height='20px' />
                                                <Skeleton height='20px' opacity={0.5} />
                                            </Stack>
                                            <Stack width="50%">
                                                <Skeleton height='20px' />
                                                <Skeleton height='20px' opacity={0.5} />
                                                <Skeleton height='20px' />
                                                <Skeleton height='20px' opacity={0.5} />
                                                <Skeleton height='20px' />
                                                <Skeleton height='20px' opacity={0.5} />
                                                <Skeleton height='20px' />
                                                <Skeleton height='20px' opacity={0.5} />
                                            </Stack>

                                        </HStack>
                                                        ) :
                                                            <Stack flexGrow={1} overflow="auto" maxH="80vh">
                                                                <AutoUI
                                                                    layout="table"


                                                                    value={previewWidget?.preview_data}
                                                                    showOnlySchemaFields

                                                                    schema={previewWidget?.columns_schema}
                                                                />
                                                            </Stack>
                                                        )}
                                                </AccordionPanel>
                                            </AccordionItem>


                                            <AccordionItem>
                                                <AccordionButton>
                                                    <HStack justify="space-between" width="100%">
                                                        {statusMessage && statusMessage.status_type != "waiting" ? <ChatStatusBadge statusMessage={statusMessage} /> : <></>}
                                                        {logs?.length && <HStack>
                                                            <Text fontSize='xs' >Logs</Text>
                                                            <AccordionIcon />
                                                        </HStack>}
                                                    </HStack>
                                                </AccordionButton>

                                                <AccordionPanel pb={4}>
                                                    <Stack spacing={1} overflow="auto" maxH="50vh"  >
                                                        <Accordion defaultIndex={[0]} allowToggle index={logs?.length ? undefined : 0} border="0px solid transparent" >
                                                            {logsGrouped && Object.keys(logsGrouped).map((row_index, iGrp) => (

                                                                <>
                                                                    {logsGrouped[row_index].map((log, i) => (

                                                                        <AccordionItem >
                                                                            <AccordionButton overflow="hidden" border="0px solid transparent" p="8px">
                                                                                <Stack align="start">

                                                                                    {i == 0 && <Text fontSize="xs" fontWeight="bold">Row {row_index} {logsGrouped[row_index]?.length}</Text>}
                                                                                    <Text key={i} maxH="1.3em" fontSize="xs" color="gray.600" textAlign="start" textOverflow="ellipsis">■ {log}</Text>
                                                                                </Stack>


                                                                            </AccordionButton>

                                                                            <AccordionPanel pb={4} backgroundColor="#f5f5f5">
                                                                                <MarkdownContent>
                                                                                    {log}
                                                                                </MarkdownContent>
                                                                            </AccordionPanel>
                                                                        </AccordionItem>
                                                                    ))}
                                                                </>

                                                            ))}

                                                        </Accordion>

                                                    </Stack>
                                                </AccordionPanel>

                                            </AccordionItem>

                                        </Accordion>

                                    </Stack>)}

                            </HStack>
                        </Stack>
                            </GeniouslyThemeProvider>
                    </ModalBody>
                </ModalContent>
            </Modal>)
    );


}


export { ListOpDialog }