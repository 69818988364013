



import { useState, useEffect, useMemo, useCallback } from "react";
import { ObjectInspector } from 'react-inspector';
import Editor, { useMonaco } from "@monaco-editor/react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Badge,
    Text,
    Stack,
    Box,
    Input,
    Checkbox,
    Spinner,
    Switch,
  } from '@chakra-ui/react'
import { getApi } from "../../apiService";
import { AutoUI } from "../AutoUI/AutoUI";
import { ConfirmModal } from "../ModalDialogs/confirmModal";
import { ChatComponent, ChatService, GeniouslyThemeProvider } from "geniously-chat-ui";
import { TaskRecipe } from "./workflowsSchema";
import { AutosizeTextArea } from "../AutoUI/AutosizeTextArea";
import useSWR from "swr";

interface CodeFeedback{
    is_error:boolean
    lineno:number
    step_name:string
    text:string
}

export const ExecutionLogDetail= ({execution}:{
    execution:{task_id:string, execution_id:string}
    
})=>{

const {data:logs, isLoading: isLoadingLogs} = useSWR(`executionlogs/${execution?.task_id}/${execution.execution_id}`,()=>{
    return getApi().getWorkflowExecutionLogs(execution.task_id,execution.execution_id)
})





return (
   <Stack>
    {isLoadingLogs?<Spinner/>:(
        <Stack>
            {logs?.map((log, i)=>(
                <Stack key={i}>
                    <HStack>
                    <Badge colorScheme={log.severity==="error"?"red":(log.severity=="warning"?"orange":"gray")}>{log.severity}</Badge>
                    <Text fontWeight={900}>{log.step_name}</Text>
                    {log.step_exec_info?.progress_index!=undefined && <Badge><Text fontWeight={900}>{log.step_exec_info?.progress_index}/{log.step_exec_info?.progress_total}</Text></Badge>}
                    </HStack>
                    
                    <Text>{log.log_message}</Text>
                    {log.state_changes && <code>
                        <ObjectInspector
                            data={log.state_changes}
                            // #onChange={this.setValue}
                        />
                    </code>}
                </Stack>
            ))}
        </Stack>
    )}
    </Stack>
)
}
