

import { useCallback, useEffect, useState } from "react";



import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Editable, EditableInput, EditablePreview } from "@chakra-ui/react";

import { useAppContext } from "../appContext";
import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Checkbox, CloseButton, Flex, HStack, Heading, IconButton, MenuGroup, Portal, Progress, SimpleGrid, Spinner, Stack, Tag, Text, Wrap, WrapItem, background, keyframes, useToast } from "@chakra-ui/react";

import { TopicInsightsBox } from "../components/Insights/topicInsights";
import { AutoUI, ChatInput, GeniouslyCommander, ChatService, ChatStatusBadge, GeniouslyThemeProvider, StatusMessage, CommandResultMessage, WidgetContainer } from "geniously-chat-ui";
import { useApiEndpoint, useApiFetch } from "../utils/useApiHook";
import { IconArrowsDiagonal2, IconBoxAlignBottomLeftFilled, IconChevronDown, IconChevronRight, IconChevronUp, IconCircleCheckFilled, IconExclamationCircle, IconPlayerPauseFilled, IconPlayerPlay, IconPlus, IconRefresh, IconStack2, IconThumbUp } from "@tabler/icons-react";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import { Reorder } from "framer-motion";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import useSWR, { mutate } from "swr";
import { ConfirmModal } from "../components/ModalDialogs/confirmModal";
import { EditModal } from "../components/ModalDialogs/editModal";
import { IconFilter } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { IconChevronLeft } from "@tabler/icons-react";
import { IconDotsVertical } from "@tabler/icons-react";
import { InfoModal } from "../components/ModalDialogs/infoModal";
import Navigation from "../components/Navigation/navigation";
import GenericList from "../components/GenericList/genericList";
import { IconArrowsDiagonalMinimize } from "@tabler/icons-react";
import { IconWindowMaximize } from "@tabler/icons-react";

import { IconCircleDashed } from "@tabler/icons-react";


export default function NewListPage() {



    const navigate = useNavigate()
    const { listId, listVersion } = useParams()

    const [searchParams, setSearchParams] = useSearchParams()

    // const fileToBase64 = (file: File) => new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsArrayBuffer(file);
    //     reader.onload = () => resolve([file.name, reader.result]);
    //     reader.onerror = reject;
    // });


    async function fileToBase64(file) {
        const buffer = await file.arrayBuffer();
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return [file.name, btoa(binary)];
    }

    const tableBackgroundCss = `
    repeating-linear-gradient(
        to right,
        transparent,
        transparent 250px,
        #c8c8c85f 250px,
        #c8c8c85f 251px
    ),
    repeating-linear-gradient(
        to bottom,
        transparent,
        transparent 30px,
        #c8c8c8a0 30px,
        #c8c8c8a0 31px,
        #ffffffa1 32px,
        #ffffffa1 61px,
        #c8c8c8a0 62px,
        #c8c8c8a0 63px
    ),
    linear-gradient(-45deg, white,#eaeaeaa0);
    `
    const createEndpoint = useApiEndpoint<Blob>("POST", `/lists`, true, true)

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         if (pendingUpdate && data) {
    //             save()
    //         }
    //     }, 2000)
    //     return () => {
    //         clearTimeout(timeout)
    //     }
    // }, [pendingUpdate])
    const [attachments, setAttachments] = useState<any[]>([])

    return (<Flex className="page" direction="column" flexGrow={1} width="100%" height="90%" >

        <HStack justify="space-between" m="10px">
            <HStack>

                <Text>Create new list</Text>



            </HStack>


        </HStack>

        <Stack align="center" justify="end" background={tableBackgroundCss} height="80vw" border="2px solid lightgray" borderRadius={8} m="10px">
            <GeniouslyThemeProvider>

                <Stack direction="column" p="4px" border="1px solid #c8c8c8" mb="30px" shadow="xl" borderRadius="4px" background="white" minW={["500px", "700px", "800px"]}>

                    {createEndpoint.isRunning && <Spinner />}
                    <ChatInput placeholderText="What list are we gonna build?" attachments={attachments} onSend={createEndpoint.isRunning ? undefined : (msg) => {
                        Promise.all(attachments.map((a) => fileToBase64(a.file))).then(filesAsBase => Object.fromEntries(filesAsBase as any)).then(files => {

                            createEndpoint.execute({}, {
                                initial_request: msg,
                                files: files
                            }).then(res => {
                                if (res.id) {
                                    navigate(`/lists/${res.id}`)
                                }
                            })
                        })
                    }} allowFileAttachments={true} onAttachmentsAdd={(a) => {
                        setAttachments([...attachments, ...a])
                    }} onAttachmentRemove={(i, a) => setAttachments(attachments.filter(aa => aa !== a))} />

                </Stack>
            </GeniouslyThemeProvider>
        </Stack>
    </Flex>

    )
}

