



import { useState, useEffect, useMemo, useCallback } from "react";

import Editor, { useMonaco } from "@monaco-editor/react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Badge,
    Text,
    Stack,
    Box,
    Input,
    Checkbox,
    Spinner,
    Switch,
    IconButton,
  } from '@chakra-ui/react'
import { getApi } from "../../apiService";
import { AutoUI } from "../AutoUI/AutoUI";
import { ConfirmModal } from "../ModalDialogs/confirmModal";
import { ChatComponent, ChatService, GeniouslyThemeProvider } from "geniously-chat-ui";
import { TaskRecipe } from "./workflowsSchema";
import { AutosizeTextArea } from "../AutoUI/AutosizeTextArea";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
    AutoCompleteTag,
    AutoCompleteCreatable
} from "@choc-ui/chakra-autocomplete";
import { useApiFetch } from "../../utils/useApiHook";
import { IconChevronUp, IconX } from "@tabler/icons-react";
import { IconChevronDown } from "@tabler/icons-react";

interface CodeFeedback{
    is_error:boolean
    lineno:number
    step_name:string
    text:string
}

export const RecipeDetail= ({recipe, onChange}:{
    recipe:TaskRecipe, 
    onChange:(recipe:{})=>void|Promise<TaskRecipe>
})=>{
const [wait, setWait] = useState(false);


const [isSubmitting, setIsSubmitting] = useState(false);
    const [codeValueState, _setCodeValue] = useState<string>(recipe?.recipe_definition || "\n");
const setCodeValue = (value:string)=>{
    _setCodeValue(value)
    onChange({...recipe, recipe_definition:value})
    //verifyCode(value)
}
const [modal, setModal] = useState<any>(null);
const [codeEvaluation, setCodeEvaluation] = useState<any>();

const [errorMsg, _setErrorMsg] = useState<{[key:string]:string}>({});
const setErrorMsg = (key:string,error:string)=>{
    let errObj={}
    errObj[key]=error
    _setErrorMsg({...errorMsg, ...errObj})
}

    const tagsRequest = useApiFetch<string[]>("/tasks/tags");

const monaco = useMonaco();
const [editorInstance, setEditorInstance] = useState<any>();
const [editInputsAsJson, setEditInputsAsJson] = useState<boolean>();

//   const monacoModel = useMemo(() => {
//     if (codeValueState){

//         return monaco.editor.createModel(
//             codeValueState
//             );
//         }
//   }, [codeValueState]);

// const [verifiedCode, setVerifiedCode] = useState<string>(codeValue);
// const pendingVerification = useMemo(()=>codeValueState!=verifiedCode, [codeValueState,verifiedCode])
// const [runningVerification, setRunningVerification] = useState<boolean>(false);
// function verifyCode(value:string){
//     setRunningVerification(true)
//     return getApi().analyzeWorkflowCode(task_id,task_workflow_id, codeValueState, taskInputsState).then(codeEvaluation=>{
//         if (codeEvaluation){
//             setRunningVerification(false)
//             setErrorMsg("Code analysis",codeEvaluation.valid?null:"Failed")
//             setVerifiedCode(value)
//             let monacoModel = monaco.editor.getModel(monaco.Uri.parse("inmemory://model/1"))
//             if (monacoModel){
//                 setCodeEvaluation(codeEvaluation)
//                 monaco.editor.setModelMarkers(monacoModel, "parent", codeEvaluation.feedback.map((feedback:CodeFeedback)=>({
//                     startLineNumber: feedback.lineno,
//                     endLineNumber: feedback.lineno,
//                     startColumn: 1,
//                     endColumn: 100,
//                     message: feedback.text,
//                     severity: feedback.is_error ? monaco.MarkerSeverity.Error : monaco.MarkerSeverity.Warning
//                 })))
//                 editorInstance.createDecorationsCollection(codeEvaluation.feedback.map((feedback:CodeFeedback)=>({
//                     range: new monaco.Range(feedback.lineno, 1, feedback.lineno, 1),
//                     options: {
//                         isWholeLine: true,
//                         className: feedback.is_error ? 'myContentClassError' : 'myContentClassInfo',
//                         glyphMarginClassName: feedback.is_error ? 'myGlyphMarginClassError' : 'myGlyphMarginClassInfo',
//                         hoverMessage: { value: feedback.text }
//                     }
//                 })))
                
//             }
//         }
//         else{
//             setErrorMsg("Code analysis","Failed to execute")
//         }
//     })
    //getApi().verifyCode(value).then((result)=>{})
// }

function validate(){
    if (!recipe.recipe_instructions){
        return false
    }
    return true
}




return (
   <Stack>
        {/* {errorMsg&&Object.values(errorMsg)&&Object.keys(errorMsg).filter(errKey=>errorMsg[errKey]).map((errKey,i)=>(<Badge colorScheme="red"><Text color="red.700">{errKey}: {errorMsg[errKey]}</Text></Badge>))} */}
        
          {isSubmitting?(
            <Spinner/>
          ):<Tabs maxH="80vh" overflow="auto" maxW="95vw" flex="auto"
          height="100%" alignItems="stretch" alignSelf="stretch" justifySelf="stretch" justifyItems="stretch">
            <TabList>
                <Tab>Recipe info</Tab>
                <Tab>Recipe Code example</Tab>
                
                {codeEvaluation && <Tab>Code analysis <Badge colorScheme={!codeEvaluation.valid?"red":(codeEvaluation.connector_errors?.length?"orange": "blue")} >{codeEvaluation.feedback?.length + (codeEvaluation.connector_errors?.length||0) }</Badge></Tab>}
                
            </TabList>
            <TabPanels maxH="80vh">
                <TabPanel >
                    <Stack spacing={2} fontSize="sm">
                        <Stack spacing={0}>
                            <Text fontWeight={600}>Task name</Text>
                                <Input value={recipe?.task_name} onChange={(e) => onChange({ ...recipe, task_name: e.target.value })} />
                        </Stack>
                            <AutoComplete isLoading={tagsRequest.isLoading} creatable openOnFocus multiple values={recipe.tags || []} onChange={(val) => onChange({ ...recipe, tags: val })}>
                                <AutoCompleteInput placeholder="Pick tags..." >
                                    {({ tags }) =>
                                        tags?.map((tag, tid) => (
                                            <AutoCompleteTag
                                                key={tid}
                                                label={tag.label}
                                                onRemove={tag.onRemove}
                                            />
                                        ))
                                    }
                                </AutoCompleteInput>
                                <AutoCompleteList>
                                    {tagsRequest?.data?.map((country, cid) => (
                                        <AutoCompleteItem
                                            key={`option-${cid}`}
                                            value={country}
                                            textTransform="capitalize"
                                            _selected={{ bg: "whiteAlpha.50" }}
                                            _focus={{ bg: "whiteAlpha.100" }}
                                        >
                                            {country}
                                        </AutoCompleteItem>
                                    ))}
                                    <AutoCompleteCreatable >{({ value }) => <span>Create new tag `{value}`</span>}</AutoCompleteCreatable>
                                </AutoCompleteList>
                            </AutoComplete>
                        <Stack spacing={0}>
                            <Text fontWeight={600}>Task description</Text>
                                <AutosizeTextArea value={recipe?.task_description} onApply={(val) => onChange({ ...recipe, task_description: val })} />
                            </Stack>

                            <Stack spacing={0}>
                                <Stack >
                                    <HStack width="100%">
                                        <Stack width="100%">
                                            <Text fontWeight={600}>Task inputs</Text>
                                            <Text fontWeight={400} fontSize="xs" color="gray.800">inputs that we need to ask for</Text>

                                        </Stack>
                                        <Button colorScheme="brand" alignSelf="end" size="sm" onClick={() => {
                                            onChange({ ...recipe, inputs: [...(recipe.inputs || []), { input_name: "new_input_name" }], "new_input_name": "" })
                                        }}>Add</Button>

                                    </HStack>

                                    {recipe?.inputs?.length &&

                                        recipe.inputs.map((input, i) =>
                                            <Stack p="0px 30px" key={i} border="1px solid gray" rounded="md">
                                                <Stack mb="-80px" ml="-30px" >
                                                    <IconButton alignSelf="start" variant="ghost" size="xs" icon={<IconX size="15px" />} aria-label="Remove" onClick={() => {
                                                        onChange({ ...recipe, inputs: recipe.inputs.filter(i => i.input_name !== input.input_name) })
                                                    }} />

                                                    <IconButton alignSelf="start" variant="ghost" size="xs" icon={<IconChevronUp size="15px" />} aria-label="Move up" onClick={() => {
                                                        let newList = [...recipe.inputs]
                                                        let temp = newList[i]
                                                        newList[i] = newList[i - 1]
                                                        newList[i - 1] = temp
                                                        onChange({ ...recipe, inputs: newList })
                                                    }} />
                                                    <IconButton alignSelf="start" variant="ghost" size="xs" mt="-10px" icon={<IconChevronDown size="15px" />} aria-label="Move up" onClick={() => {
                                                        let newList = [...recipe.inputs]
                                                        let temp = newList[i]
                                                        newList[i] = newList[i + 1]
                                                        newList[i + 1] = temp
                                                        onChange({ ...recipe, inputs: newList })
                                                    }} />
                                                </Stack>

                                                <AutoUI value={input}
                                                    onValueChange={val => {
                                                        let newList = [...recipe.inputs]
                                                        newList[i] = { ...val }
                                                        onChange({ ...recipe, inputs: newList })
                                                    }}
                                                    schema={{
                                                        properties: {
                                                            "input_name": { type: "string", title: "Input name", description: "Name of the input", default: "", example: "some_input_name" },
                                                            "input_description": { type: "string", title: "Input description", description: "Explain the input, what to ask, what to look for and how to use it", maxLength: 10000, default: "" },
                                                            "generation_instructions": { type: "string", title: "Generation instructions", description: "How to generate proposed value if value has not been provided", default: "" },
                                                        },
                                                        required: ["input_name", "input_description"]
                                                    }} />
                                            </Stack>
                                        )
                                    }
                                </Stack>

                        </Stack>

                        <Stack spacing={0}>
                            
                                <Text fontWeight={600}>Information collection (instructions) </Text>
                                <AutosizeTextArea placeholder="Describe instruction on what datasource to search and what to ask user during initialization" value={recipe?.collect_information_instructions} onApply={(val) => onChange({ ...recipe, collect_information_instructions: val })} />

                        </Stack>
                        <Stack spacing={0}>
                            
                                <Text fontWeight={600} >Trigger (instructions) </Text>
                                <AutosizeTextArea value={recipe.trigger_instructions} onApply={(val) => onChange({ ...recipe, trigger_instructions: val })} />

                            </Stack>
                            <Stack spacing={0}>

                            <Text fontWeight={600} color={!recipe.recipe_instructions?"red":undefined}>Recipe steps (instructions) </Text>
                            <AutosizeTextArea value={recipe.recipe_instructions} onApply={(val)=>onChange({...recipe, recipe_instructions:val})} />
                                {!recipe?.recipe_instructions && <Text color="red" fontSize="xs">required</Text>}
                        </Stack>
                        
                    </Stack>
                </TabPanel>

                <TabPanel>
                        <Stack spacing={0} pl="50px">

                            <Text fontWeight={600} >Code generation instructions </Text>

                            <AutosizeTextArea value={recipe.recipe_code_generation_instructions} onApply={(val) => onChange({ ...recipe, recipe_code_generation_instructions: val })} />

                        </Stack>
                        <Text ml="50px" mb="10px" fontWeight={600} >Code example with explanation </Text>
            { codeValueState &&(<Editor
                height="70vh"
                defaultPath="inmemory://model/1"
                defaultLanguage="python"
                value={codeValueState}
                onMount={(editor, monaco) => {
                    setEditorInstance(editor)
                }}
                options={{
                
                    glyphMargin: true,
                }}
                onChange={(value:string)=>setCodeValue(value)}
                
            />)}
            </TabPanel>
         
            {codeEvaluation && <TabPanel>
                <Stack spacing={2} >
                {codeEvaluation.connector_errors?.length &&  codeEvaluation.connector_errors.map(c=>(
                    <HStack borderBottom="1px solid gray" mb="5px" align="stretch" >
                        
                        <HStack backgroundColor={"orange"} flexGrow={1} maxW="5px" p="5px"></HStack>
                        <Stack>
                            <Text fontWeight={900}> {c.connection}</Text>
                            <Text whiteSpace="pre-wrap" fontSize="sm">
                                {c.message}
                            </Text>
                        </Stack>
                    </HStack>
                ))}
                {codeEvaluation && codeEvaluation.feedback?.length && codeEvaluation.feedback.map(f=>(
                    <HStack borderBottom="1px solid gray" mb="5px" align="stretch" >
                        
                        <HStack backgroundColor={f.is_error?"red":"lightblue"} flexGrow={1} maxW="5px" p="5px"></HStack>
                        <Stack>
                            <Text fontWeight={900}> {f.step_name} (lineno: {f.lineno})
                            </Text>
                            <Text whiteSpace="pre-wrap" fontSize="sm">
                                {f.text}
                            </Text>
                        </Stack>
                    </HStack>
                ))}
                </Stack>
            </TabPanel>}
            </TabPanels>

            </Tabs>}

            </Stack>
)
}
