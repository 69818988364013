

import { useEffect, useState } from "react";



import { Tabs, TabList, TabPanels, Tab, TabPanel, Tag, HStack, Checkbox, Button, Link, Tooltip } from '@chakra-ui/react'
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";

import { useAppContext } from "../appContext";
import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { useApiEndpoint, useApiFetch } from "../utils/useApiHook";
import { IconBrandLinkedin, IconSquareRoundedCheckFilled, IconX } from "@tabler/icons-react";
import { IconCheck } from "@tabler/icons-react";


interface MessageWithContact {
  message: { id: string, text: string, subject?: string, timestamp: string, type: "message_in" | "message_out", scheduled_send_time?: string },
  contact: { id: string, full_name: string, linkedin_url: string }
}

interface PagedMessages {
  messages: MessageWithContact[],
  total: number
}

const MessageCard = ({ message, checkbox, isChecked, onCheckedChanged }: { message: MessageWithContact, checkbox?: boolean, isChecked?: boolean, onCheckedChanged?: (val: boolean) => any }) => {
  return <HStack align="start">
    {checkbox && <Checkbox colorScheme="brand" isChecked={isChecked} onChange={() => {
      onCheckedChanged(!isChecked)
    }} />}
    <Stack flexGrow={1} fontWeight={500} spacing={0} border="1px solid lightgray" backgroundColor="white" p="6px 10px" shadow="md" borderRadius="0px 10px 10px">
      <HStack justify="space-between" mb="4px">
        <HStack flexGrow={1} spacing={2}>
          <Link href={`/inbox/${message.contact?.id}`} target="_blank" >
            <Text>{message.contact?.full_name}</Text>
          </Link>
          {message.contact?.linkedin_url && <Link href={message.contact?.linkedin_url} target="_blank" >
            <IconBrandLinkedin color="blue" size="18px" />
          </Link>}

        </HStack>

        <Stack fontWeight={500} fontSize="xs" color="gray" textAlign="end">
          <Tooltip label={<Moment >{message.message?.scheduled_send_time || message.message?.timestamp}</Moment>} >
            <Box>

              <Moment fromNow>{message.message?.scheduled_send_time || message.message?.timestamp}</Moment>
            </Box>
          </Tooltip>
        </Stack>
      </HStack>
      <Stack flexGrow={1} >{message.message?.subject}</Stack>
      <Box><Text fontSize="sm" fontWeight={400} whiteSpace="pre-wrap">{message.message?.text}</Text></Box>
    </Stack>
  </HStack >
}

const MessageList = ({ messages, bulkOperation: bulkOperation, onSelectionChanged }: {
  messages: MessageWithContact[],
  bulkOperation?: { label: string, icon: any, onClick: (messages: { message: { id: string } }[]) => Promise<any> }[],
  onSelectionChanged?: (val: boolean) => any
}) => {
  const [selection, setSelection] = useState<MessageWithContact[]>([])
  const [runningAction, setRunningAction] = useState<string>()
  return <Stack maxH="75vh" overflow="auto">
    {bulkOperation && <HStack width="100%" justify="space-between">
      <HStack >

        {bulkOperation && <Checkbox colorScheme="brand" isChecked={selection?.length == messages?.length} onChange={() => {
          setSelection(!selection?.length ? messages : [])
        }} />}
        {selection?.length && <Text fontWeight={500} fontSize="xs">{selection?.length} selected</Text>}
      </HStack>
      <HStack justifySelf="flex-end">
        {selection?.length && bulkOperation.map((operation) => <Button
          isLoading={runningAction == operation.label}
          colorScheme="brand" variant="outline" size="xs" onClick={() => {
            setRunningAction(operation.label)

            setRunningAction(operation.label)
            operation.onClick(selection).then(() => {
              setSelection([])
            }).finally(() => {
              setRunningAction(undefined)

            })

          }}>{operation.icon} {operation.label}</Button>)}
      </HStack>
    </HStack>}

    {messages?.map((message) => <MessageCard message={message} checkbox={!!bulkOperation}
      isChecked={selection?.includes(message)}
      onCheckedChanged={(val) => {
        if (val) {
          setSelection([...(selection || []), message])
        }
        else {
          setSelection(selection?.length ? selection.filter((item) => item != message) : null)
        }
      }} />)}
  </Stack>
}

const PendingVerification = () => {


  return (
    <Stack>
      <Text>Pending verification</Text>
    </Stack>
  )
}



export default function HomePage() {

    const { currentUser, logOut } = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    // useEffect(()=>{
    //     if (currentUser && searchParams.get("force")!="true" ){
    //         navigate("/home")
    //     }
    //     else{
    //         navigate("/login")
    //     }
    // },[currentUser])

  const pendingVerification = useApiFetch<PagedMessages>("/inbox/messages/pending-verification", { swrOptions: { refreshInterval: 3 * 60 * 1000 } })
  const receivedMessages = useApiFetch<PagedMessages>("/inbox/messages/received?last_n_hours=48", { swrOptions: { refreshInterval: 3 * 60 * 1000 } })
  const scheduledMessages = useApiFetch<PagedMessages>("/inbox/messages/scheduled?next_n_hours=24", { swrOptions: { refreshInterval: 3 * 60 * 1000 } })
  const sentMessages = useApiFetch<PagedMessages>("/inbox/messages/sent?last_n_hours=24", { swrOptions: { refreshInterval: 3 * 60 * 1000 } })

  const patchEndpoint = useApiEndpoint("POST", "/inbox/messages/actions")




    return (
      <Stack overflow="auto" h='calc(100% - 40px)' w="calc(100% - 10px)" p="10px" className="homePage" background="dark-1">

        <Grid
          h='100%'
          templateRows='repeat(2, 1fr)'
          templateColumns='repeat(3, 1fr)'
          gap={4}
        >
          <GridItem rowSpan={2} colSpan={2}  >
            <Text>Messages</Text>
            <Tabs colorScheme="brand">
              <TabList>
                {pendingVerification.data?.total > 0 && <Tab>Pending verification {pendingVerification.data?.total > 0 && <Tag colorScheme="brand" fontSize="xs" size="sm" m="2px">{pendingVerification.data?.total}</Tag>}</Tab>}

                <Tab>Received recently {receivedMessages.data?.total > 0 && <Tag colorScheme="brand" fontSize="xs" size="sm" m="2px">{receivedMessages.data?.total}</Tag>}</Tab>
                <Tab>Scheduled  {scheduledMessages.data?.total > 0 && <Tag colorScheme="brand" fontSize="xs" size="sm" m="2px">{scheduledMessages.data?.total}</Tag>}</Tab>
                <Tab>Sent recently  {sentMessages.data?.total > 0 && <Tag colorScheme="brand" fontSize="xs" size="sm" m="2px">{sentMessages.data?.total}</Tag>}</Tab>
              </TabList>

              <TabPanels>
                {pendingVerification.data?.total > 0 && <TabPanel>
                  <Stack>

                    <MessageList messages={pendingVerification.data?.messages} bulkOperation={[
                      {
                        label: "Approve", icon: <IconCheck />, onClick: (messages) => patchEndpoint.execute({}, {
                          message_ids: messages.map((message) => message.message.id),
                          action: "verify"
                        }

                        ).then(() => {
                          pendingVerification.mutate()
                        })
                      },


                      {
                        label: "Reject", icon: <IconX size="20px" />, onClick: (messages) => patchEndpoint.execute({}, {
                          message_ids: messages.map((message) => message.message.id),
                          action: "reject"
                        }).then(() => {
                          pendingVerification.mutate()
                        })
                      },
                    ]} />

                  </Stack>
                </TabPanel>}
                <TabPanel>
                  <Text fontSize="xs" >Received in last 48h</Text>
                  <MessageList messages={receivedMessages.data?.messages} />
                </TabPanel>
                <TabPanel>
                  <MessageList messages={scheduledMessages.data?.messages} />
                </TabPanel>
                <TabPanel>
                  <MessageList messages={sentMessages.data?.messages} />
                </TabPanel>
              </TabPanels>
            </Tabs>


          </GridItem>
          {/* <GridItem rowSpan={2} colSpan={2} bg='red' /> */}
          <GridItem rowSpan={2} colSpan={1} bg='papayawhip' />

        </Grid>
     
     
      </Stack>
    );
  }

