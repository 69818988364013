

import { useEffect } from 'react';
import './App.css';
import PageRouter from './PageRouter';

import { AppContextProvider } from './appContext';
import { Box, ChakraProvider, extendTheme, withDefaultColorScheme } from '@chakra-ui/react'



  // // Add this in node_modules/react-dom/index.js
  // (window as any).React1 = require('react');

  // // Add this in your component file
  // require('react-dom');
  // (window as any).React2 = require('react');
  // console.log("react",(window as any).React1 === (window as any).React2);

// 2. Extend the theme with new layer styles
const chakraTheme = extendTheme({

  colors: {
    black: {
      50: "#747474",
      100: "#747474",
      200: "#747474",
      300: "##4b4b4b",
      400: "#4b4b4b",
      500: "#4b4b4b",
      600: "#3e3e3e",
      700: "#3e3e3e",
      800: "#313131",
      900: "#242424",
    },
    brand: {
      50: "#FFECEC",
      100: "#FFCBCB",
      200: "#FFBBBB",
      300: "#FFAAAA",
      400: "#FF8080",
      500: "#FF5555",
      600: "#E34E4E",
      700: "#C74646",
      800: "#8E3737",
      900: "#1C1919",
    },
  },
  /*Patch for issue with drawer if you want to keep interacting with the other parts of the page https://github.com/chakra-ui/chakra-ui/issues/2893*/
  components: {
    Drawer: {
      variants: {
        aside: {
          dialog: {
            pointerEvents: "auto"
          },
          dialogContainer: {
            pointerEvents: "none"
          }
        }
      }
    }

  },
  ...(withDefaultColorScheme({ colorScheme: 'brand' })),
})


function App() {

  
  return (
    <Box className="App"  height="100vh" width="100vw" overflow="hidden">
       {/* <Grommet theme={theme}> */}
       <ChakraProvider theme={chakraTheme}>
       
        <AppContextProvider>
        
          <PageRouter/>
        
        </AppContextProvider>
       </ChakraProvider>
       {/* </Grommet> */}
    </Box>
  );
}

export default App;
