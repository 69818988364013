import  { useMemo } from 'react'
import { useContext, createContext,useState, useEffect } from "react";
import { useNavigate } from 'react-router';

import { firebaseApp } from './firebase/firebaseApp';
import { getAuth, updatePassword, signOut } from "firebase/auth";
import { AppProject } from './models/dataModel';
import { useToast } from '@chakra-ui/react';
import { getApi } from './apiService';

interface UserInfo{
    id:string
    name:string
    tenant_id?:string
    email?:string
    photoURL?:string
    default_app_id?:string
    getAccessToken?:()=>Promise<string>
}



interface AppContext{
    
    setError:(err:string, title:string|undefined)=>any
    setInfo:(info:string, title:string|undefined)=>any
    currentUser?:UserInfo
    tempApiKey:string
    setTempApiKey:(key:string)=>any
    setCurrentUser:(val:UserInfo|any)=>any
    currentAppProject?:AppProject
    setCurrentAppProject:(val:AppProject)=>any
    isAppModified:boolean
    setIsAppModified:(val:boolean)=>any
    logOut:(redirectToLogin?:boolean, followUpUrl?:string)=>any
}

 
const AppContext = createContext(undefined);


export function useAppContext(){
    return useContext(AppContext) as AppContext;
}
const TEST_USER = {
    id:"1",
    name:"test",
    accessToken:"test",
}
export function AppContextProvider({children}:any){

   
    //const [projectInfo,setProjectInfo] = useState();
    
    const [currentAppProject,setCurrentAppProject] = useState<AppProject|undefined>();
    const [isAppModified,setIsAppModified] = useState<boolean>();
    const [tempApiKey,setTempApiKey] = useState<string>();

    const [currentUser,_setCurrentUser] = useState<UserInfo>();
    
    const setCurrentUser=(user:any)=>{
        if (!user){
            _setCurrentUser(null)
            return
        }
        getApi().getMyAccount().then((myAccount)=>{
            if (!user) {
                _setCurrentUser(null)
                return
            }
            if (user.id && user.name){
                _setCurrentUser(user)
            }
            else{
                _setCurrentUser({
                    id:user.uid,
                    name:user.displayName||user.email,
                    email:user.email,
                    photoURL:user.photoURL,
                    default_app_id:myAccount.default_app_id,
                    tenant_id:myAccount.tenant_id,
                    getAccessToken:()=>(user as any).getIdToken()
                })
            }
        }).catch((err)=>{

        })
        
    }

    useEffect(()=>{
        const auth = getAuth(firebaseApp);
        auth.onAuthStateChanged((user)=>{
            setCurrentUser(user)
        })
    },[])

    const navigate = useNavigate()
    function logOut(redirectToLogin:boolean=true, followUpUrl?:string){
        const auth = getAuth(firebaseApp);
        signOut(auth)
        setCurrentUser(null)
        if (redirectToLogin){
            if (followUpUrl)
                navigate("/login?force=true&followup="+encodeURIComponent(followUpUrl))
            else
                navigate("/login?force=true")
        }   
    }

    const toast = useToast()
    function setError(err:string, title:string|undefined=undefined){
        toast({
            title: title,
            description:err,
            status: 'warning',
            duration: 3000,
            isClosable: true,
          })
    }

    function setInfo(info:string, title:string|undefined=undefined){
        toast({
            title: title,
            description:info,
            status: 'info',
            duration: 3000,
            isClosable: true,
          })
    }

    const value = {
     
        setError,   
        setInfo,
        currentUser,
        tempApiKey,
        setTempApiKey,
        setCurrentUser,
        currentAppProject,
        setCurrentAppProject,
        isAppModified,
        setIsAppModified,
        logOut
    };
    

    return (
        <AppContext.Provider value={value}>
        {children}
        </AppContext.Provider>
        
        );
}