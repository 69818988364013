import { Menu, MenuButton, MenuList, MenuItem, MenuGroup, Button, Tag, Checkbox, Text, IconButton, Tooltip, Box, Stack, TagLabel } from "@chakra-ui/react"
import { IconChevronDown, IconFilter, IconX } from "@tabler/icons-react"

const FilterButton = ({ filters, isDisabled, isLoading, filterOps, onRemoveFilter, onFilterOp, onToggleFilter: onToggleFilter }: {
    filters: any,
    isDisabled?: boolean,
    isLoading?: boolean,
    onRemoveFilter?: (filterName: string) => Promise<void> | void
    filterOps?: { id: string, name: string }[]
    onFilterOp?: (actionId: string) => void
    onToggleFilter?: (filterName: string, value: boolean) => void
}) => {
    return <Menu size="sm">
        <MenuButton variant="outline"
            isLoading={isLoading}
            isDisabled={isDisabled}
            leftIcon={<IconFilter size="18px" />}
            colorScheme="brand" as={Button} rightIcon={<IconChevronDown size="15px" />}>
            Filter
            {filters && <Tag size="xs" fontSize="xs" p="0px 4px" colorScheme="brand">{Object.keys(filters).length}</Tag>}
        </MenuButton>
        <MenuList zIndex={15}>
            {filters && Object.keys(filters) && <MenuGroup title='Filters'>
                {Object.keys(filters).map((filterName) => (
                    <MenuItem as={Button} border="1px solid gray" p="0px 8px" m="4px 8px" justifyContent="space-between" key={filterName} >
                        <Checkbox size="sm" readOnly={!onToggleFilter} isChecked={!(filters[filterName]?.active === false)} onChange={(e) => onToggleFilter(filterName, e.target.checked)} />
                        <Text>
                            {filterName.replace(/_/g, " ")}
                        </Text>
                        {filters[filterName]?.reduction != undefined && <Tooltip label={`${filters[filterName]?.reduction} records filtered out by this filter`}>
                            <Stack>
                                <Tag size="xs" variant="outline" colorScheme="brand"><TagLabel m="2px 4px" fontSize="xs">-{filters[filterName]?.reduction}</TagLabel> </Tag>
                            </Stack>
                        </Tooltip>}
                        <IconButton size="xs" variant="ghost" aria-label="Remove" icon={<IconX size="15px" />} onClick={() => {
                        onRemoveFilter(filterName)
                        }} />
                    </MenuItem>
                ))}
            </MenuGroup>}
            {filterOps && filterOps.map((filterOp) => (

                <MenuItem justifySelf="start" key={filterOp.id} onClick={() => onFilterOp(filterOp.id)}>

                    {filterOp.name}

                </MenuItem>
            ))}

        </MenuList>
    </Menu>
}

export { FilterButton }