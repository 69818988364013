import React, { useEffect, useMemo } from 'react';
import { Box, Button, HStack, IconButton, Progress, Spinner, Stack, Tag, Text, Tooltip, Wrap, WrapItem } from "@chakra-ui/react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { IconListDetails, IconMathFunction, IconMessage, IconMessagePlus } from '@tabler/icons-react';
//import { AutoUI, AutosizeTextArea, AutosizeTextInput } from 'geniously-chat-ui';
import { AutoUI } from '../AutoUI/AutoUI';
import { AutosizeTextArea } from "../AutoUI/AutosizeTextArea";
import { AutosizeTextInput } from "../AutoUI/AutosizeTextInput";
import { Step, ValueDescription } from './workFlowSchema';





function StepValueDescriptionComponent({ valueDescription, value, collapsed, onValueChange, maxDepth = 1 }: {
  valueDescription: ValueDescription
  value: any
  collapsed?: boolean
  onValueChange?: (newValue: any) => void,
  maxDepth?: number
}) {

  function getValuePreview(value: any, maxLength: number | undefined = undefined) {
    maxLength = maxLength || 100
    if (typeof value === "string") {
      return value.length > maxLength ? value.slice(0, maxLength) + "..." : value
    }
    else if (typeof value === "number") {
      return value
    }
    else if (typeof value === "boolean") {
      return value
    }
    else if (Array.isArray(value)) {
      return value.length != 1 ? value.length + (" items") : (`[${getValuePreview(value[0], maxLength)}]`)
    }
    else {
      return getValuePreview(JSON.stringify(value), maxLength)
    }
  }
  const valuePreview = useMemo(() => getValuePreview(value, collapsed ? 100 : 10000), [value, collapsed])

  let res;
  if (valueDescription.type === "value-description") {
    res = (
      <HStack fontWeight={500} >
        {
          collapsed ? (
            <Tag variant="outline" color="black">

              <Text textOverflow="ellipsis" noOfLines={collapsed ? 1 : undefined} fontSize={"12px"} whiteSpace={collapsed ? "nowrap" : undefined}>{valuePreview}</Text>
            </Tag>
          ) : (
            valueDescription?.value && typeof (value) === "object" ? (
              <Box alignSelf="start" >



                {/* {JSON.stringify(value.value, null, 2)} */}
                <AutoUI value={Array.isArray(value) ? { items: value } : value} onValueChange={onValueChange} />


              </Box>
            ) : (
              value?.length > 50 ? (
                <AutosizeTextArea value={value} onApply={(val) => { value !== val && onValueChange && onValueChange(val) }} />
              ) : (
                <AutosizeTextInput value={value} onApply={(val) => { value !== val && onValueChange && onValueChange(val) }} />
              )

            ))}
      </HStack>
    )
  }
  else if (valueDescription.type === "calculated-value") {
    res = <Stack backgroundColor="gray.100" borderRadius="5px" p="2px 10px" lineHeight={1} border="1px solid gray" align="start" justify="center" gap={!collapsed ? 5 : 0} spacing={5} overflow="auto" >
      <HStack align="center" justify="start" >
        <IconMathFunction size="15px" />
        <Text justifySelf="start" as="code" whiteSpace="nowrap" fontSize="xs" >{valueDescription.name}</Text>



      </HStack>
      {!collapsed &&
        <Stack >
          <Text fontSize="12px" fontWeight={900}>Expression: </Text>
          <Stack display="inline-flex" fontSize="xs" justify="start" direction="row" gap="1p2">
            <Wrap>


              {valueDescription?.expression.split(/(\$[\w]+)/).map((part, index) => {
                let nestedInputDesc = valueDescription.inputs && valueDescription.inputs[part.slice(1)];
                if (maxDepth > 0 && valueDescription?.inputs && part?.length > 1 && part.startsWith("$") && nestedInputDesc) {
                  return <WrapItem key={index} >
                    <StepValueDescriptionComponent maxDepth={(maxDepth || 1) - 1} collapsed valueDescription={nestedInputDesc} value={nestedInputDesc.value} />
                  </WrapItem>

                }
                else {
                  return <WrapItem key={index}><Text justifySelf="start" as="code" lineHeight={1.5} >{part} </Text></WrapItem>
                }
              })}
            </Wrap>
          </Stack>
        </Stack>
      }
      {<HStack fontSize="xs" justify="space-between" width="100%">

        {collapsed ? (


          // <Tag ><Text whiteSpace="nowrap"></Text><Text noOfLines={1}>{valuePreview}</Text></Tag>
          <></>

        ) : (
          <Stack>
            {(valueDescription?.value && (typeof value) == "object") ? (
              <AutoUI value={{ value: value }} readOnly allowAddNew={false} />
            ) : (
              <Text fontSize="xs" color="coralRed">{value}</Text>
            )}
          </Stack>
        )}
      </HStack>}
      {!collapsed && <Stack alignSelf="end"><Text fontSize="xs" whiteSpace="nowrap">Source step: {valueDescription.step_name}</Text></Stack>}
    </Stack>
  }


  return <Stack >{res}</Stack>

}

function StepInputsComponent({
  step,
  onInputValuesChanged,
}: {
  step: Step;
  onInputValuesChanged?: (newVal: { [inputKey: string]: any }) => void;
}) {
  const [valuesPatch, setValuesPatch] = React.useState<{ [inputValKey: string]: any }>(); // describes different values request for specific inputs
  useEffect(() => {
    if (valuesPatch) {
      onInputValuesChanged && onInputValuesChanged(valuesPatch)
    }
  }, [valuesPatch])

  const iargs = useMemo(() => (step?.step_inputs ? Object.keys(step.step_inputs) : []).map((argKey, index) => {
    let label = argKey.split("_").join(" ")
    label = label.charAt(0).toUpperCase() + label.slice(1);

    return {
      key: argKey,
      value: (valuesPatch && valuesPatch[argKey] !== undefined) ? valuesPatch[argKey] : (step.step_inputs ? step.step_inputs[argKey].value : undefined),
      label: label,
      valueDescription: step.step_inputs[argKey]

    }
  }), [step, valuesPatch])


  return (
    <Stack>
      {!iargs?.length && <Text fontSize="sm" color="gray" >No inputs for this step</Text>}
      <Accordion allowMultiple defaultIndex={iargs.map((arg, i) => arg.valueDescription?.type == "value-description" ? i : -1).filter(i => i >= 0)}>
        {iargs.map((arg, index) => (


          <AccordionItem key={index}>
            {({ isExpanded }) => (
              <>
                <AccordionButton width="100%" >
                  <HStack justify="space-between" width="100%">

                    <HStack>
                      <Tooltip label={arg.key} aria-label={arg.key}>
                        <Text whiteSpace="nowrap" fontSize="xs" fontWeight={700}>{arg.label}:</Text>

                      </Tooltip>
                      {!isExpanded && <StepValueDescriptionComponent value={arg.value} valueDescription={arg.valueDescription} collapsed />}
                    </HStack>
                    <AccordionIcon alignSelf="end" />
                  </HStack>
                </AccordionButton>

                <AccordionPanel pb={4}>
                  <Stack>
                    {isExpanded && <StepValueDescriptionComponent value={arg.value} valueDescription={arg.valueDescription} onValueChange={(val) => setValuesPatch({ ...(valuesPatch || {}), [arg.key]: val })} />}
                  </Stack>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}

      </Accordion>


    </Stack>
  );
}

export { StepInputsComponent }