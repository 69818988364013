
import { Box, HStack, Input, Stack, Text, useDisclosure, Spinner, Wrap, WrapItem, Tag, TagCloseButton } from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react'

import  { useState, useRef, useEffect } from 'react';

export const AutosizeTextInput = ({
  value,
  selectedValue,
  onValueSelected,
  className,
  onValueChange,
  minWidth = null,
  onApply,
  placeholder,
  handleLookup,
  options,
  ...rest
}: {
  value?: string;
  selectedValue?: { value: string; label?: string; [key: string]: any };
  onValueSelected?: (value: {
    value: string;
    label?: string;
    [key: string]: any;
  }) => any;
  minWidth?: number;
  className?: string;
  placeholder?: string;
  onValueChange?: (val: string) => any;
  onApply?: (value: string) => any;
  handleLookup?: (
    value
  ) => Promise<{ value: string; label?: string; secondaryLabel?: string }[]>;
  options?: {
    lookupDelay: number;
    //showAllOptions?:boolean,
  };
  [key: string]: any;
}) => {
  const [tempValue, setTempValue] = useState("");

  const [width, setWidth] = useState(minWidth || 100);
  const span = useRef();

  useEffect(() => {
    if (span?.current) {
      let _width = (span.current as any).offsetWidth;
      if (_width) {
        let newWidth = Math.min(
          Math.max(minWidth | 100, _width * 1.2),
          document.body.clientWidth - 100
        );

        setWidth(newWidth);
      }
      //(span?.current as any).style.display = "none";
    }
  }, [tempValue]);

  useEffect(() => {
    if (selectedValue && selectedValue.label) {
      setTempValue(selectedValue.label);
    } else if (typeof value === "string") {
      setTempValue(value);
    } else if (value) {
      setTempValue((value as any)?.label || (value as any)?.value || "");
    } else {
      setTempValue("");
    }
  }, [value]);

  const changeHandler = (evt) => {
    setTempValue(evt.target.value);
    onValueChange && onValueChange(evt.target.value);
    if (handleLookup && evt.target.value) {
      popoverOpen();
      setLookupWorking(true);
      setLookupResults(undefined);

      // handleLookup(evt.target.value).then((results)=>{
      //   setLookupResults(results)
      //   setLookupWorking(false)
      // })
    } else {
      setLookupResults([]);
    }
  };

  useEffect(() => {
    if (tempValue /*||options?.showAllOptions*/ && isPopoverOpen) {
      let timeout = setTimeout(() => {
        handleLookup(tempValue).then((results) => {
          setLookupResults(results);
          setLookupWorking(false);
        });
      }, options?.lookupDelay || 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [tempValue]);

  function finishEditing() {
    if (selectedValue?.label === tempValue) {
      onApply && onApply(selectedValue.value);
    } else {
      onApply && onApply(tempValue);
    }
  }

  let componentResult = (
    <Box overflow="hidden" fontSize="xs">
      <span
        style={{
          maxWidth: "100%",
          maxHeight: "1.5em",
          background: "red",
          visibility: "hidden",
          position: "fixed",
        }}
        ref={span}
      >
        {tempValue || placeholder}
      </span>
      {/* {width} */}
      <Input
        {...rest}
        className={className}
        value={tempValue}
        p="2px"
        size="xs"
        placeholder={placeholder}
        onFocus={(e) => {
          // if (options?.showAllOptions){
          //   popoverOpen();
          //   (e.target as any).opened = (new Date()).getTime();
          // }
        }}
        type="text"
        style={{ width, maxWidth: "100%", fontSize: "11pt" }}
        autoFocus
        rounded={4}
        onChange={changeHandler}
        onBlur={(e) => {
          if (!isPopoverOpen) {
            // const currentTime =  (new Date()).getTime();
            // if (options?.showAllOptions && (e.target as any).opened&& (e.target as any).opened > currentTime-4000) {
            //   return; // if popover was just opened, don't close it
            // }
            finishEditing();
          }
        }}
        onKeyDown={(e) => {
          e.key === "Enter" && onApply && onApply(tempValue);
        }}
      />
    </Box>
  );
  const {
    isOpen: isPopoverOpen,
    onOpen: popoverOpen,
    onClose: popoverClose,
  } = useDisclosure();
  const [lookupResults, setLookupResults] = useState<any[]>([]);
  const [lookupWorking, setLookupWorking] = useState<boolean>();

  if (handleLookup /*||options?.showAllOptions*/) {
    return (
      <Popover
        //returnFocusOnClose={false}
        isOpen={isPopoverOpen}
        autoFocus={false}
        onClose={() => {
          popoverClose();
          finishEditing();
        }}
        placement="bottom-start"
        closeOnBlur={false}
        isLazy
        matchWidth={true}
      >
        <PopoverTrigger>{componentResult}</PopoverTrigger>
        <PopoverContent shadow="lg">
          <PopoverBody p="0px">
            {lookupWorking ? (
              <HStack p="5px">
                <Spinner size="sm" />
                <Text color="gray" size="sm">
                  Searching...
                </Text>
              </HStack>
            ) : !lookupResults?.length ? (
              <HStack p="5px">
                <Text color="gray" size="sm">
                  {tempValue
                    ? "No matching records"
                    : "Type to search for value"}
                </Text>
              </HStack>
            ) : (
              lookupResults.map((result) => (
                <Stack
                  width="100%"
                  maxW="100%"
                  spacing={0}
                  m="0px"
                  p="10px"
                  _hover={{ background: "rgba(0,0,0,0.05)" }}
                  onClick={() => {
                    onValueSelected(result);
                    popoverClose();
                  }}
                >
                  <Text fontSize="sm" textOverflow="ellipsis">
                    {result.label || result.value}
                  </Text>
                  {result.secondaryLabel && (
                    <Text
                      lineHeight="80%"
                      fontSize="xs"
                      textOverflow="ellipsis"
                    >
                      {result.secondaryLabel}
                    </Text>
                  )}
                </Stack>
              ))
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  } else {
    return componentResult;
  }
};