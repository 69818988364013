import { Box } from "@chakra-ui/react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { ImSearch } from "react-icons/im";
import { IconBox } from "../IconBox/iconBox";
import { BiSupport, BiWrench } from "react-icons/bi";
import { HiBolt } from "react-icons/hi2";
import { FaRegCompass } from "react-icons/fa";
import { TbClipboardText } from "react-icons/tb";
import { GrDocumentPdf } from "react-icons/gr";

import { IconWorldSearch,IconDatabaseSearch, IconApi, IconPlugConnected, IconHomeSearch, IconClipboardText } from '@tabler/icons-react';
import { IconCompass } from "@tabler/icons-react";


interface DataSourceIconProps {
    plain?: boolean;
    type: string;
    size?: "small" | "medium" | "large" | "xlarge";
    [key:string]:any
}

const INDEXED_TYPES=[
    "text_snippet",
    "pdf_files",
    "website",
    "sitemap"
]

export const DataSourceIcon = (props:DataSourceIconProps) => {
    function getSkillColor(type:string){
        if (INDEXED_TYPES.includes(type)) return "cyan.500"
        else return "purple.500"
    }

    
    function getIcon(type:string, size:string=undefined){
        if (type === "text_snippet") return <IconClipboardText size={size}/>
        else if (type === "pdf_files") return <GrDocumentPdf size={size}/>
        else if (type === "website") return <IconHomeSearch size={size}/>
        else if (type === "sitemap") return <IconCompass size={size}/>
        
        else if (type === "internet_search") return <IconWorldSearch size={size}/>
        else if (type === "remote_sql") return <IconDatabaseSearch size={size}/>
        else if (type === "remote_rest_api") return <IconApi size={size}/>
        else if (type === "odata") return <IconApi size={size}/>
        
        else return <IconPlugConnected size={size} />
    }
    return <IconBox  color={getSkillColor(props.type)} icon={(size)=>getIcon(props.type, size)} {...props}/>
}