import {
    Editable,
    EditableInput,
    EditablePreview,
    useEditableControls,
    IconButton,
    ButtonGroup,
    Flex,
    Input,
    EditableProps,
    Stack,
    HStack,
    Text,
    Button,
} from '@chakra-ui/react'
import { IconEdit, IconPlayerPlay } from '@tabler/icons-react'
import { IconCheck, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { ListOp } from './model'
import { ListOpDialog } from './listOpDialog'

export function ListOpsWidget({ widget, listId, onClickOpen, allAvailableCommands, chatService, setModal, ...rest }: {
    widget: { list_ops: ListOp[] },
    listId: string,


    [key: string]: any
}) {

    let listOps: ListOp[] = widget.list_ops


    return (

        <Stack border="1px solid lightgray" width="100%" borderRadius="4px" p="8px">

            {listOps.map((op, i) => (
                <Stack key={i} border="1px solid lightgray" borderRadius={4} mt="4px" dropShadow="lg" backgroundColor="gray.200" p="4px 8px" _hover={{ backgroundColor: "gray.100" }}
                    cursor="pointer"
                    onClick={(e: any) => {
                        setModal(<ListOpDialog
                            opType={op.op_type}
                            listOp={op as any}
                            list_id={listId}
                            chatService={chatService}
                            //interactive={allAvailableCommands[op.op_type].interactive}
                            //supports_prompting={allAvailableCommands[op.op_type].supports_prompting}
                            //supports_preview={allAvailableCommands[op.op_type].supports_preview}
                            //argSchema={allAvailableCommands[op.op_type].schema}
                            onClose={() => setModal(undefined)} />

                        )
                    }}
                    //onClick={() => {
                    //     onClickOpen(op)
                    // }}
                >
                    <HStack justify="space-between">
                        <HStack>
                            <Text fontWeight={600} fontSize="sm">{op.name || op.op_type}</Text>

                        </HStack>
                        <Button size="xs" leftIcon={<IconPlayerPlay size="15px" />}>Run</Button>

                    </HStack>
                </Stack>
            ))}

        </Stack>
    )
}