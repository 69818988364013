import { useState } from "react";
import { TaskWorkflow } from "./workFlowSchema";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, CloseButton, HStack, Spinner, Stack, Text } from "@chakra-ui/react";

import { ChatInput, ChatService, Step, CommandResultMessage } from "geniously-chat-ui"
import { getApi } from "../../apiService";
import { getStepPath } from "./helpers";
import { IconExclamationCircle } from "@tabler/icons-react";


export const AddStepInput = ({ whereToAdd, chatService, workFlow, onFinish, onCancel }: {
    whereToAdd: { afterStep: Step } | { beforeStep: Step },
    chatService: ChatService,
    workFlow: TaskWorkflow
    onFinish: (newSteps: Step[]) => any,
    onCancel: () => void
}) => {

    const [instruction, setInstructions] = useState<string>("")
    const [waiting, setWaiting] = useState(false)
    const [error, setError] = useState<string>()
    const [errorDetails, setErrorDetails] = useState<string>()



    function execute() {
        setWaiting(true)
        try {
            let step = whereToAdd["afterStep"] || whereToAdd["beforeStep"]
            let path = getStepPath(step, instruction, workFlow.workflow_steps as any)
            let where = whereToAdd["afterStep"] ? { afterStep: path } : { beforeStep: path }
            chatService.sendCommand({
                type: "command",
                action_id: "add-step",
                args: {
                    instruction: instruction,
                    where: where
                }
            }, {
                returnResultMessage: true,
                timeoutSec: 300,
                waitForResult: true

            }).then((res: CommandResultMessage) => {
                if (res.success) {
                    onFinish(res.result as Step[])
                }
                else {
                    if (Array.isArray(res.result)) {
                        setErrorDetails(res.result.map((r) => JSON.stringify(r)).join("\n"))
                    }
                    else {
                        setErrorDetails(JSON.stringify(res.result))
                    }
                }
            }).catch((error) => {

                setError("Ooops, something went wrong")
                setErrorDetails(JSON.stringify(error))

            })
        } catch (e) {

            setError("Ooops, something went wrong")
            setErrorDetails(JSON.stringify(e))
        }
    }
    return (
        <Stack >
            {!(waiting || error) ? (

                <HStack justify="stretch" spacing={0} minW="350px" align="start">
                    <Stack width="100%" >
                        <ChatInput placeholderText="Describe step you want to add here" onChange={setInstructions} onSend={(val) => {
                            execute()
                        }}
                            onBlur={() => {
                                if (!instruction) {
                                    onCancel()
                                }
                            }}
                        />
                    </Stack>

                    <CloseButton mt="2px" justifySelf="end" onClick={onCancel} />
                </HStack>
            ) : (
                (!error) ? (
                    <HStack p="5px"><Spinner size="sm" /> <Text fontSize="sm">Step is being generated</Text> <CloseButton mt="2px" justifySelf="end" onClick={onCancel} /></HStack>
                ) : (
                    <HStack p="5px" color="red.800" align="start">
                        <IconExclamationCircle />
                        <Stack mt="4px" spacing={0}>

                            <Text noOfLines={0} fontSize="sm">{error}</Text>


                            {errorDetails && (
                                <Accordion allowToggle>
                                    <AccordionItem border="0px">
                                        <AccordionButton p="0px" >
                                            <Text fontSize="10px">Details</Text>
                                        </AccordionButton>
                                        <AccordionPanel>
                                            <Text whiteSpace="pre-wrap" fontSize="sm">{errorDetails}</Text>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                            )}
                        </Stack>

                        <CloseButton justifySelf="end" onClick={onCancel} />
                    </HStack>
                )
            )}

        </Stack>
    )
}

