import { useState } from "react";
import { Box, Button, HStack, IconButton, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from "@chakra-ui/react";
import { IconArrowsDiagonal2, IconArrowsDiagonalMinimize } from "@tabler/icons-react";


export function InfoModal({ caption, onClose, size, withFullscreenSwitch, children }: {
    caption?: string | JSX.Element;
    size?: string,
    withFullscreenSwitch?: boolean;
    onClose: () => void;
    
    children:  JSX.Element;
}) {
    const [maximized, setMaximized] = useState(false);
    
    return (<Modal 
        autoFocus={false}

        size={maximized ? "full" : (size || ["full", "full", "full", "5xl"])}
        
        isOpen={true} onClose={() => {
            onClose();
    }}>
        <ModalOverlay />
        <ModalContent width="80vw" >
            <HStack width="100%" justify="space-between" >
                <ModalHeader whiteSpace="nowrap" textOverflow="ellipsis" maxW="70vw">{caption}

                </ModalHeader>
                {withFullscreenSwitch && <IconButton m="10px" justifySelf="end" variant="ghost" icon={maximized ? <IconArrowsDiagonalMinimize size="20px" /> : <IconArrowsDiagonal2 size="20px" />} aria-label={maximized ? "Shrink" : "Maximize"} onClick={() => setMaximized(!maximized)} />}
            </HStack>
            <ModalBody display="flex" maxH={maximized ? "85vh" : ["calc(100vh - 150px)", "calc(100vh - 150px)", "calc(100vh - 150px)", "60vh"]} overflow="auto">


                {children}

            </ModalBody>
            <ModalFooter>
          
                <Button
                    size={"sm"}
                    colorScheme='brand'
                    onClick={() =>onClose()}
                >
                    Close
                </Button>
                
            </ModalFooter>
        </ModalContent>
    </Modal>);
}
