

import { useEffect, useState } from "react";



import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";
import { useAppContext } from "../appContext";
import { Logo } from "../components/CommonIcons/commonIcons";
import { Box, Flex, Link } from "@chakra-ui/react";


export  default function LoginPage({mode}:{mode:"signup"|"login"}){
    
    const {currentUser, logOut} = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(()=>{
        if (currentUser && !searchParams ){
            getApi().getMyAccount().then((res)=>{
                navigate("/home")
            })
            .catch((err)=>console.error(err))

        }
    },[currentUser])

    


    return (
        <Flex  justify="stretch" align="center" direction="column" className="general-page" height="100vh"  overflow="auto">
            <Flex flexGrow={0} alignSelf="start">
                <Link href="/">
                <Logo className="logo-icon" size={"large"} withText/>
                </Link>

            </Flex>
            <Flex direction="column" align="center" >


                <LoginComponent mode={mode}/>
            </Flex>
        </Flex>
    )
}