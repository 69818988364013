import { useEffect, useMemo, useState } from "react";

import { v4 as uuidv4 } from "uuid";
import Editor from "@monaco-editor/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useSWR from "swr";

import {
  Link,
  Select,
  Tag,
  TagCloseButton,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  HStack,
  Heading,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import {
  IconBolt,
  IconBook,
  IconDatabasePlus,
  IconPlugConnected,
  IconPlus,
  IconSquareRoundedPlus,
  IconWebhook,
} from "@tabler/icons-react";

import { getApi } from "../../apiService";
import { AppProject, Integration } from "../../models/dataModel";

import { IconUsersPlus } from "@tabler/icons-react";
import IntegrationIcon from "./integrationIcon";
import { AutoUI } from "../AutoUI/AutoUI";
import { EditModal } from "../ModalDialogs/editModal";
import JsonObjectEditor from "../codeBlock/jsonEditor";

interface FieldDescription {
  name: string;
  description: string;
  type: "string" | "number" | "boolean" | "array";
  item_type?: { type: "string" | "number" };
  required: boolean;
}
interface RequestTemplate {
  url?: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  headers?: Record<string, any>;
  body?: Record<string, any>;
  query?: Record<string, any>;
  extract_response?: Record<string, any>;
}

interface ResponseRetriever {
  type: "initial" | "polling" | "webhook";
  response?: string;
  polling_test?: string;
  polling_timeout_hours?: number;
  polling_request?: RequestTemplate;
  webhook_id?: string;
  webhook_extract_data?: Record<string, any> | null;
  webhook_test?: string | null; // This should be an eval-ed function for now
  webhook_await_timeout_minutes?: number;
}
interface RestEndpointPayload {
  action_name?: string;
  action_description?: string;
  name?: string;
  description?: string;
  arguments?: FieldDescription[];
  filters?: FieldDescription[];
  request: RequestTemplate;
  response_retriever?: ResponseRetriever;
}

class RunTestClient {
  private wsEndpoint: string;
  private socket: WebSocket | undefined = undefined;
  private ready: boolean = false;
  public constructor(connector: string) {
    this.wsEndpoint =
      (process.env.REACT_APP_API_URL?.replace("http", "ws") ||
        "ws://localhost:4080") + `/integrations/${connector}/test-run`;

    // this.socket.onmessage = (ev) => this.onSocketMessage(ev);
    // this.socket.onerror = (ev) => this.onSocketError(ev);
  }

  private sendMsg(msg: any) {
    return this.socket.send(JSON.stringify(msg));
  }
  private onSocketOpen() {
    return getApi()
      .authorize()
      .then((auth_data) => {
        this.sendMsg({
          auth_data: auth_data,
        });
        this.ready = true;
        return true;
      });
  }

  public cancel() {
    this.socket.close(3999, "Canceled");
  }

  public runTest(
    testPayload: RestEndpointPayload,
    args: any,
    onUpdate?: (val) => any
  ) {
    let url = new URL(this.wsEndpoint);
    if (args) {
      Object.keys(args).forEach((key) =>
        url.searchParams.append(key, args[key])
      );
    }

    this.socket = new WebSocket(url.toString());
    return new Promise((resolve, reject) => {
      return (this.socket.onopen = () =>
        this.onSocketOpen().then(() => {
          this.sendMsg(testPayload);

          this.socket.onmessage = (ev) => {
            const msg = JSON.parse(ev.data);
            if (msg.test_status) {
              onUpdate && onUpdate(msg.test_status);
            } else {
              resolve(msg);
            }
          };
          this.socket.onerror = (ev) => {
            reject(ev);
          };
          this.socket.onclose = (ev) => {
            if (ev.code == 3999) {
              reject("Canceled");
            } else if (ev.code != 1) {
              reject("Connection closed");
            }
          };
        }));
    })
      .then((res) => res)
      .finally(() => {
        this.socket.onmessage = undefined;
        this.socket.onerror = undefined;
        this.socket.onclose = undefined;
        if (this.socket.readyState == WebSocket.OPEN) {
          try {
            this.socket.close(1);
          } catch (e) {}
        }
        return false;
      });
  }
}

const RestApiIntegrationDetail = ({
  integration: _integration,
}: {
  integration: Integration;
}) => {
  const [modal, setModal] = useState<any>();
  const [isWorking, setIsWorking] = useState(false);

  function openDataSourceDialog(open: RestEndpointPayload = undefined) {
    setModal(
      <EditModal
        value={
          open ||
          ({
            arguments: [],
            request: {
              method: "GET",
            },
          } as RestEndpointPayload)
        }
        caption={"Add new REST Datasource"}
        onCancel={() => setModal(null)}
        onOk={(val) => {
          getApi()
            .postIntegrationDatasource(_integration.connector, val as any)
            .then(() => {
              setModal(null);
              refreshIntegration();
            });
        }}
      >
        {(val, setVal) => (
          <RestCallDetail value={val} type="Datasource" onChange={setVal} />
        )}
      </EditModal>
    );
  }

  function openCommandActionDialog(open: RestEndpointPayload = undefined) {
    setModal(
      <EditModal
        value={
          open ||
          ({
            arguments: [],
            request: {
              method: "POST",
            },
          } as RestEndpointPayload)
        }
        caption={"Add new REST action"}
        onCancel={() => setModal(null)}
        onOk={(val) => {
          getApi()
            .postIntegrationAction(_integration.connector, val as any)
            .then(() => {
              setModal(null);
              refreshIntegration();
            });
        }}
      >
        {(val, setVal) => (
          <RestCallDetail value={val} type="Action" onChange={setVal} />
        )}
      </EditModal>
    );
  }

  const [integration, setIntegration] = useState<Integration>();

  function refreshIntegration() {
    setIsWorking(true);
    return getApi()
      .getIntegration(_integration.connector)
      .then((res) => {
        setIntegration(res);
        setIsWorking(false);
        return res;
      });
  }
  useEffect(() => {
    refreshIntegration();
  }, []);
  const show_spinner = !integration || isWorking;
  return show_spinner ? (
    <>
      <Spinner />
    </>
  ) : (
    <Stack align="start">
      <Stack direction="row">
        {<IntegrationIcon type={integration.connector} />}
        <Heading size="md">{integration.name}</Heading>
      </Stack>
      {modal}
      <Text fontWeight={700}>Endpoints as datasources</Text>
      {integration.datasources?.length > 0 && (
        <Stack align="start">
          <HStack align="start">
            <Wrap>
              {integration.datasources?.map((ds) => (
                <WrapItem>
                  <Tag
                    border="1px solid gray"
                    rounded="3px"
                    p="0px 5px"
                    backgroundColor="white"
                    cursor="pointer"
                    onClick={() => {
                      openDataSourceDialog(ds as any);
                    }}
                  >
                    <IconBook size="15px" />
                    <Text fontSize="xs" fontWeight={500} whiteSpace="nowrap">
                      {ds.name}
                    </Text>
                    <TagCloseButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsWorking(true);
                        getApi()
                          .deleteIntegrationDatasource(
                            integration.connector,
                            ds.datasource_id
                          )
                          .then(() => {
                            setModal(null);
                            refreshIntegration();
                            setIsWorking(true);
                          })
                          .catch(() => {
                            setIsWorking(true);
                          });
                      }}
                    />
                  </Tag>
                </WrapItem>
              ))}
            </Wrap>
          </HStack>
          {/* {(app &&onAppChange && !dsAdded) &&  <Button p="0px 45px" colorScheme="green" 
            leftIcon={<IconSquareRoundedPlus/>}  onClick={addDataSources}>Add all datasources</Button>} */}
        </Stack>
      )}
      <Button
        leftIcon={<IconPlus />}
        size="xs"
        onClick={() => openDataSourceDialog()}
      >
        Add new datasource
      </Button>
      <Text fontWeight={700}>Endpoints as actions</Text>
      <Stack>
        {integration.actions?.length > 0 && (
          <Stack align="start">
            <HStack align="start">
              <Wrap>
                {integration.actions?.map((action) => (
                  <WrapItem>
                    <Tag
                      border="1px solid gray"
                      rounded="3px"
                      p="0px 5px"
                      backgroundColor="white"
                      cursor="pointer"
                      onClick={() => {
                        openCommandActionDialog(action as any);
                      }}
                    >
                      <IconBolt size="15px" />
                      <Text fontSize="xs" fontWeight={500} whiteSpace="nowrap">
                        {action.action_name}
                      </Text>
                      <TagCloseButton
                        onClick={(e) => {
                          e.stopPropagation();
                          getApi()
                            .deleteIntegrationAction(
                              integration.connector,
                              action.action_id
                            )
                            .then(() => {
                              setModal(null);
                              refreshIntegration();
                              setIsWorking(true);
                            })
                            .catch(() => {
                              setIsWorking(true);
                            });
                        }}
                      />
                    </Tag>
                  </WrapItem>
                ))}
              </Wrap>
            </HStack>
            {/* {(app &&onAppChange && !actionsAdded) &&  <Button   p="0px 45px" colorScheme="green"  
            leftIcon={<IconSquareRoundedPlus/>} onClick={addCommandActions}>Add all actions</Button>} */}
          </Stack>
        )}
      </Stack>
      <Button
        leftIcon={<IconPlus />}
        onClick={() => openCommandActionDialog()}
        size="xs"
      >
        Add new action
      </Button>
      <Stack m="10px 0px"></Stack>
    </Stack>
  );
};

const HELP_TEXT = `
# Iterating over data
You can iterate over data using the $foreach keyword.
and object with $foreach keyword (\`{"$foreach": {...} }\`) will be represented as array of items returned 
{
  "$foreach": {
    "$item": "$data",
    "first_column": "$item.0",
    "second_column": "$item.1"
  }
}
`;

const RestCallDetail = ({
  value,
  onChange,
  type,
}: {
  value: RestEndpointPayload;
  onChange: (val: RestEndpointPayload) => void;
  type: "Action" | "Datasource";
}) => {
  const [valueState, setValueState] = useState<RestEndpointPayload>(value);
  useEffect(() => {
    onChange(valueState);
  }, [valueState]);

  const [testResults, setTestResults] = useState<any>();
  const [responseRetrievalType, _setResponseRetrievalType] =
    useState<any>("initial");

  useEffect(() => {
    if (valueState?.response_retriever?.type) {
      _setResponseRetrievalType(valueState.response_retriever.type);
    }
  }, [valueState]);
  const setResponseRetrievalType = (val: any) => {
    setValueState({
      ...valueState,
      response_retriever:
        val == "polling"
          ? {
              ...(valueState?.response_retriever?.type == "polling"
                ? valueState?.response_retriever
                : {
                    polling_request: {
                      url: valueState.request.url,
                      method: "GET",
                    },
                  }),
              type: "polling",
            }
          : { type: val },
    });
  };
  const [testResultsPending, setTestResultsPending] = useState<any>();
  const [testArguments, setTestArguments] = useState<any>();
  const argsField =
    (type == "Action" ? valueState.arguments : valueState.filters) || [];

  const runTestClient = useMemo(() => new RunTestClient("external_api"), []);
  return (
    <Stack>
      {type == "Action" ? (
        <AutoUI
          value={{
            action_name: valueState.action_name,
            action_description: valueState.action_description,
            method: valueState.request?.method,
            url: valueState.request?.url,
          }}
          schema={{
            type: "object",
            required: ["action_name", "method", "url"],
            properties: {
              action_name: {
                type: "string",
                example: "Name of my " + type,
                title: type + " name",

                description:
                  "This should be descriptive enough for LLM to be able to pick it up",
              },
              action_description: {
                type: "string",
                title: "Description",
                example: "Use this to ...",
              },
              url: {
                type: "string",
                title: "URL",
                description:
                  "You can reference arguments in the URL using {arg_name} placeholder",
                example: "https://api.my-app.com/v1/path/to/endpoint",
              },
              method: {
                type: "string",
                title: "Method",
                enum: ["GET", "POST", "PUT", "DELETE"],
              },
            },
          }}
          onValueChange={(newVal) => {
            setValueState({
              ...valueState,
              action_name: newVal.action_name,

              action_description: newVal.action_description,

              request: {
                ...valueState.request,
                method: newVal.method,
                url: newVal.url,
              },
            } as any);
          }}
        />
      ) : (
        <AutoUI
          value={{
            name: valueState.name,
            description: valueState.description,
            method: valueState.request?.method,
            url: valueState.request?.url,
          }}
          schema={{
            type: "object",
            required: ["name", "method", "url"],
            properties: {
              name: {
                type: "string",
                example: "Name of my " + type,
                title: type + " name",

                description:
                  "This should be descriptive enough for LLM to be able to pick it up",
              },
              description: {
                type: "string",
                title: "Description",
                example: "Use this to ...",
              },
              url: {
                type: "string",
                title: "URL",
                description:
                  "You can reference arguments in the URL using {arg_name} placeholder",
                example: "https://api.my-app.com/v1/path/to/endpoint",
              },
              method: {
                type: "string",
                title: "Method",
                enum: ["GET", "POST", "PUT", "DELETE"],
              },
              search_arg: {
                type: "string",
                title: "Search argument name",
                description:
                  "Name of the argument that will be used for search",
              },
            },
          }}
          onValueChange={(newVal) => {
            setValueState({
              ...valueState,

              name: newVal.name,

              description: newVal.description,
              request: {
                ...valueState.request,
                method: newVal.method,
                url: newVal.url,
              },
            } as any);
          }}
        />
      )}
      <Stack>
        <Text fontWeight={700}>
          {type == "Action" ? "Arguments" : "Filter"}
        </Text>

        <Stack direction="row" align="center">
          <AutoUI
            layout="table"
            value={{
              arguments:
                type == "Action" ? valueState.arguments : valueState.filters,
            }}
            showOnlySchemaFields
            schema={{
              type: "object",
              properties: {
                arguments: {
                  type: "array",
                  title: "Arguments",
                  items: {
                    type: "object",
                    required: ["name", "description", "type"],
                    properties: {
                      name: { type: "string", title: "arg_name" },
                      description: {
                        type: "string",
                        title: "description",
                      },
                      type: {
                        type: "string",
                        title: "data type",
                        one_of: ["string", "number", "boolean", "array"],
                      },
                      required: {
                        type: "boolean",
                        title: "Is required",
                      },
                    },
                  },
                },
              },
            }}
            allowAddNew={false}
            onValueChange={(newVal) => {
              if (type == "Action") {
                valueState.arguments = newVal.arguments.filter((a) => a.name);
              } else {
                valueState.filters = newVal.arguments.filter((a) => a.name);
              }
              setValueState({ ...valueState });
            }}
          />
        </Stack>

        <Button
          alignSelf="start"
          leftIcon={<IconPlus />}
          size="xs"
          onClick={() => {
            argsField.push({
              name: "",
              description: "",
              type: "string",
              required: true,
            });
            setValueState({ ...valueState });
          }}
        >
          Add
        </Button>
      </Stack>
      <Accordion allowToggle index={undefined}>
        <AccordionItem>
          <h2>
            <AccordionButton p="8px">
              <Text>Headers</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p="0px">
            <JsonObjectEditor
              height="200px"
              value={valueState.request?.headers || {}}
              onChange={(v) =>
                setValueState({
                  ...valueState,
                  request: { ...valueState.request, headers: v },
                })
              }
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton p="8px">
              <Text>Query params</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p="0px">
            <JsonObjectEditor
              height="200px"
              value={valueState.request?.query || {}}
              onChange={(v) =>
                setValueState({
                  ...valueState,
                  request: { ...valueState.request, query: v },
                })
              }
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton p="8px">
              <Text>Body</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p="0px">
            <JsonObjectEditor
              height="200px"
              value={valueState.request?.body || {}}
              onChange={(v) =>
                setValueState({
                  ...valueState,
                  request: { ...valueState.request, body: v },
                })
              }
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton p="8px">
              <Text>Extract from response</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p="0px">
            <Stack spacing="5px" p="0px 10px">
              {type == "Action" && (
                <Stack align="start" spacing={0} p="0px 10px">
                  <Text fontSize="sm" color="blue.600">
                    Response extraction method
                  </Text>
                  <Select
                    size="sm"
                    alignSelf="start"
                    width="auto"
                    value={responseRetrievalType}
                    onChange={(e) => {
                      setResponseRetrievalType(e.target.value);
                    }}
                  >
                    <option value="initial">
                      Process request response (default)
                    </option>
                    <option value="polling">Additional polling request</option>
                    <option value="webhook">
                      Wait for webhook call with response
                    </option>
                  </Select>
                  <Text fontSize="11px" color="gray.700">
                    {responseRetrievalType == "initial" &&
                      "Extract action result from direct HTTP response to our request"}
                    {responseRetrievalType == "polling" && (
                      <>
                        {
                          "Extract action result by polling additional HTTP request after the initial one is done. "
                        }
                        <br />
                        {
                          "This is useful for long-running actions or when the result is not available immediately after the action is done."
                        }
                      </>
                    )}
                    {responseRetrievalType == "webhook" &&
                      "The result of the action will be sent be the other party to our webhook endpoint"}
                  </Text>
                  {responseRetrievalType == "polling" && (
                    <Text fontSize="sm" m="15px 0px 0px" color="blue.600">
                      (Optionally) collect data from immediate response to use
                      them in subsequent polling request{" "}
                    </Text>
                  )}
                </Stack>
              )}
              <JsonObjectEditor
                height="150px"
                value={valueState.request?.extract_response || {}}
                onChange={(v) =>
                  setValueState({
                    ...valueState,
                    request: { ...valueState.request, extract_response: v },
                  })
                }
              />

              {type == "Action" && responseRetrievalType == "polling" && (
                <Box p="10px" rounded="5px" border="1px solid gray">
                  <Text>Polling settings</Text>
                  <PollingSettings
                    value={valueState.response_retriever}
                    onChange={(v) => {
                      setValueState({
                        ...valueState,
                        response_retriever: v,
                      });
                    }}
                  />
                </Box>
              )}

              {type == "Action" && responseRetrievalType == "webhook" && (
                <Box p="10px" rounded="5px" border="1px solid gray">
                  <Text>Webhook settings</Text>
                  <WebhookSettings
                    value={valueState.response_retriever}
                    onChange={(v) => {
                      setValueState({
                        ...valueState,
                        response_retriever: v,
                      });
                    }}
                  />
                </Box>
              )}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton p="8px">
              <Text>Test</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p="15px">
            <HStack align="start">
              <Stack width="50%">
                <Text fontSize="sm" color="blue.600">
                  Test arguments
                </Text>
                <AutoUI
                  value={testArguments}
                  schema={{
                    type: "object",
                    properties: argsField
                      ? Object.fromEntries(
                          argsField.map((arg, i) => [
                            arg.name || `<no_name_arg_${i}>`,
                            { type: arg.type },
                          ])
                        )
                      : {},
                  }}
                  onValueChange={setTestArguments}
                />
              </Stack>
              <Stack width="50%">
                <Text fontSize="sm" color="blue.600">
                  Test results
                </Text>
                <HStack justify="stretch">
                  <Button
                    flexGrow={1}
                    isLoading={testResultsPending}
                    size="sm"
                    onClick={() => {
                      setTestResultsPending(true);

                      runTestClient
                        .runTest(valueState as any, testArguments, (val) => {
                          setTestResults(val);
                        })
                        .then((res) => {
                          setTestResultsPending(false);
                          setTestResults(
                            !(res == undefined || res == null)
                              ? res
                              : "<no response data>"
                          );
                        })
                        .catch((err) => {
                          setTestResultsPending(false);
                          setTestResults("Error: " + err);
                        });
                    }}
                  >
                    Run Test
                  </Button>
                  {testResultsPending && (
                    <Button
                      size="sm"
                      onClick={() => {
                        runTestClient.cancel();
                        setTestResultsPending(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </HStack>
                {testResults && (
                  <JsonObjectEditor value={testResults} height="200px" />
                )}
              </Stack>
            </HStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};

const PollingSettings = ({
  value,
  onChange,
}: {
  value: ResponseRetriever;
  onChange: (val: ResponseRetriever) => void;
}) => {
  const [valueState, setValueState] = useState<ResponseRetriever>(value);
  useEffect(() => {
    onChange(valueState);
  }, [valueState]);

  return !valueState ? (
    <Spinner />
  ) : (
    <Stack>
      <AutoUI
        value={{
          polling_timeout_hours: valueState?.polling_timeout_hours,
          polling_test: valueState?.polling_test,
          url: valueState?.polling_request?.url,
          method: valueState?.polling_request?.method,
        }}
        excludeFields={["type"]}
        schema={{
          type: "object",
          required: ["method", "url"],
          properties: {
            polling_test: {
              type: "string",
              title: "Polling test response",
              description:
                "python lambda expression to test if the response is ready",
              example: 'response.status=="FINISHED" and response.data != None',
            },
            polling_timeout_hours: {
              type: "number",
              title: "Polling timeout (minutes)",
              description: "How long should we retry polling before giving up",
            },
            url: {
              type: "string",
              title: "URL",
              description:
                "You can reference data from previous response using `{previous_response.extracted_field}` placeholder",
              example: "https://api.my-app.com/v1/path/to/endpoint",
            },
            method: {
              type: "string",
              title: "Method",
              enum: ["GET", "POST", "PUT", "DELETE"],
            },
          },
        }}
        onValueChange={(newVal) => {
          setValueState({
            type: "polling",
            polling_timeout_hours: newVal.polling_timeout_hours,

            polling_test: newVal.polling_test,
            polling_request: {
              ...(valueState?.polling_request || {}),
              method: newVal.method,
              url: newVal.url,
            },
          } as any);
        }}
      />

      <Accordion allowToggle index={undefined}>
        <AccordionItem>
          <h2>
            <AccordionButton p="8px">
              <Text>Headers</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p="0px">
            <JsonObjectEditor
              height="200px"
              value={valueState.polling_request?.headers || {}}
              onChange={(v) =>
                setValueState({
                  ...valueState,
                  polling_request: {
                    ...valueState.polling_request,
                    headers: v,
                  },
                })
              }
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton p="8px">
              <Text>Query params</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p="0px">
            <JsonObjectEditor
              height="200px"
              value={valueState?.polling_request?.query || {}}
              onChange={(v) =>
                setValueState({
                  ...valueState,
                  polling_request: { ...valueState?.polling_request, query: v },
                })
              }
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton p="8px">
              <Text>Body</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p="0px">
            <JsonObjectEditor
              height="200px"
              value={valueState.polling_request?.body || {}}
              onChange={(v) =>
                setValueState({
                  ...valueState,
                  polling_request: { ...valueState.polling_request, body: v },
                })
              }
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton p="8px">
              <Text>Extract from response</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p="0px">
            <JsonObjectEditor
              height="200px"
              value={valueState.polling_request?.extract_response || {}}
              onChange={(v) =>
                setValueState({
                  ...valueState,
                  polling_request: {
                    ...valueState.polling_request,
                    extract_response: v,
                  },
                })
              }
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};

const WebhookSettings = ({
  value,
  onChange,
}: {
  value: ResponseRetriever;
  onChange: (val: ResponseRetriever) => void;
}) => {
  const [valueState, setValueState] = useState<ResponseRetriever>(value);
  useEffect(() => {
    onChange(valueState);
  }, [valueState]);

  return !valueState ? (
    <Spinner />
  ) : (
    <Stack>
      <AutoUI
        value={{
          webhook_await_timeout_minutes:
            valueState?.webhook_await_timeout_minutes,
          webhook_url: valueState?.webhook_id
            ? `${process.env.REACT_APP_API_URL}/integrations/webhooks/${valueState?.webhook_id}/trigger`
            : undefined,
          webhook_test: valueState?.webhook_test,
        }}
        excludeFields={["type"]}
        schema={{
          type: "object",

          properties: {
            webhook_test: {
              type: "string",
              title: "Webhook test response",
              description:
                "python lambda expression to test if the response is ready ... you can access the response from the original call at context.previous_response and reference any field from the current response ",
              example:
                'received_field=="FINISHED" and context.previous_response.run_id===run_id',
            },
            webhook_await_timeout_minutes: {
              type: "number",
              title: "Webhook timeout minutes",
              description:
                "How long should we wait for the webhook to be called",
            },
            webhook_url: {
              type: "string",
              title: "Webhook url",
              description: "Webhook URL to be called with POST request",
              example: "Save action to get the webhook URL",
            },
          },
        }}
        onValueChange={(newVal) => {
          setValueState({
            ...valueState,
            type: "webhook",
            webhook_id: valueState?.webhook_id,

            webhook_await_timeout_minutes: newVal.webhook_await_timeout_minutes,
            webhook_test: newVal.webhook_test,
          } as any);
        }}
      />
      <Text fontSize="xs" fontWeight={900}>
        Webhook extract sent data
      </Text>
      <JsonObjectEditor
        height="150px"
        value={valueState.webhook_extract_data}
        onChange={(v) =>
          setValueState({
            ...valueState,
            webhook_extract_data: v,
          })
        }
      />
    </Stack>
  );
};
export { RestApiIntegrationDetail };
