// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwmqX-D9rG7W9SNAsr67_4fBJFg3QjCNY",
  authDomain: "easypie-app.firebaseapp.com",
  projectId: "easypie-app",
  storageBucket: "easypie-app.appspot.com",
  messagingSenderId: "35781055481",
  appId: "1:35781055481:web:d2f07863ef851e2e836328",
  measurementId: "G-9DNN5N12WT"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export {firebaseApp, analytics}