

import { useEffect, useState } from "react";


import { Link as ReactRouterLink } from 'react-router-dom'
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";

import { useAppContext } from "../appContext";
import { FaUser } from "react-icons/fa";

import { MdLogout } from "react-icons/md";
import { Logo } from "../components/CommonIcons/commonIcons";
import { Box, Button, Card, Flex, FormControl, FormLabel, HStack, Heading, Input, Link, PinInput, PinInputField, Stack, Text, VStack } from "@chakra-ui/react";


export default function PasswordResetPage({  }) {

    const { currentUser, logOut } = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [sent, setSent]=useState(false)
    const [email, setEmail] = useState<string | undefined>(currentUser?.email||"")
    const [recoveryEmail, setRecoveryEmail] = useState<string | undefined>(currentUser?.email||"")
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const INVALID_CODE_DIGITS="Verification code seems to be invalid. You might have copied the link incorrectly."
    useEffect(() => {
        if (searchParams && searchParams.get("verification-code")) {
            const verifCode = searchParams.get("verification-code")
            setRecoveryEmail(searchParams.get("email"))
            setEmail(searchParams.get("email"))
            setVerificationCode(verifCode)
            if (verifCode.length!=8){
                setError(INVALID_CODE_DIGITS)
            }
        }
    },[searchParams])

    async function verifyEmailFormat(){
        if (email){
            if (email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)){
                return true
            }
        }
        setError("Invalid email format")
        throw new Error("Invalid email format")
    }

    function testPassMatch(){
        if (password?.length>0 && password2?.length>0){
            if (password!=password2){
                setError("Passwords do not match")
            }
            else if(error=="Passwords do not match"){
                setError(undefined)
            }

        }
    }

    const [sending, setSending] = useState(false)
    const [error, _setError] = useState<string | undefined>(undefined)
    function setError(error:string | undefined){
        setSending(false)
        _setError(error)
    }
    const [verificationCode, setVerificationCode] = useState<string | undefined>(undefined)
    useEffect(() => {
        if (error==INVALID_CODE_DIGITS && verificationCode?.length==8){
            setError(undefined)
        }
    },[verificationCode,error])
    return (
        <Flex justify="center" align="center" direction="column" className="general-page" height="100vh" >
            <Flex direction="row" justify="space-between" width="100%">
                <Box onClick={() => navigate("https://www.easypie.ai")} p="10px">
                    <Logo className="logo-icon" size={"large"} withText />
                </Box>
                <Button margin="5px" as={ReactRouterLink} to="/login" variant="ghost">Login</Button>
            </Flex>
            <Flex align="center" flexGrow={1}>


                <Card p="20px" shadow="2xl" width="400px" borderWidth={1} borderColor="gray.400">
                    <Stack spacing={4}>
                    <Heading size="lg">Reset password</Heading>
                        {!sent &&!recoveryEmail &&(<FormControl id="email">
                            <FormLabel>Email address</FormLabel>
                            <Input type="email" value={email} onChange={e=>setEmail(e.target.value)}/>
                        </FormControl>)}
                        {recoveryEmail &&(<Text>
                            {recoveryEmail}
                        </Text>)}
                            
                        {verificationCode!==undefined &&(<>
                            <FormControl id="password">
                        <FormLabel>Password</FormLabel>
                        <Input type="password" value={password} onChange={e=>setPassword(e.target.value)}  onBlur={()=>testPassMatch()}/>
                        </FormControl>

                

                        <FormControl id="password2">
                        <FormLabel>Repeat password</FormLabel>
                        <Input type="password" value={password2} onChange={e=>setPassword2(e.target.value)}  onBlur={()=>testPassMatch()}/>
                        </FormControl>

                            <FormControl id="verification_code">
                        <FormLabel>Verification code</FormLabel>
                        <HStack spacing={2}>
                        <PinInput value={verificationCode} onChange={setVerificationCode}>
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                        </PinInput>
                        </HStack>
                        </FormControl>
                        </>)}


                        <VStack spacing={2} align="stretch">
                            {error && <Text color="red.700">{error}</Text>}
                            {verificationCode?.length!==8 &&!sent &&<Button isLoading={sending}
                                colorScheme={'brand'}
                                onClick={() => {
                                    setSending(true)
                                    verifyEmailFormat().then(()=>getApi().passwordReset(email).then(()=>{
                                            setSent(true)
                                            setSending(false)
                                            setError(undefined)
                                        }).catch(e=>setError(e.message))
                                        ).catch(e=>setError(e.message))
                                    }
                                }
                            >
                                Request password reset
                            </Button>}
                            {sent&&!verificationCode&&(
                                <Text>An email with verification code / recovery link has been sent to {email}</Text>
                            )}

                            {verificationCode?.length===8 && <Button isLoading={sending}
                                colorScheme={'brand'}
                                onClick={() => {
                                    if (password!==password2){
                                        setError("Passwords do not match")
                                        return
                                    }
                                    setSending(true)
                                    verifyEmailFormat().then(()=>getApi().passwordReset(email,{
                                                verification_code:verificationCode,
                                                new_password:password
                                            }).then(()=>{
                                                navigate("/login")
                                            })
                                            ).catch(e=>setError(e.message))
                                        }
                                    }
                            >
                                Reset password
                            </Button>}


                            {verificationCode===undefined&&(

                            <Link fontSize="xs" alignSelf="center" onClick={()=>setVerificationCode("")} color={'blue.400'}  >I already have the verification code</Link>
                            )}
                        </VStack>
                    </Stack>
                </Card>
            </Flex>
        </Flex>
    )
}