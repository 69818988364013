import { useRef, useState } from "react";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ThemeTypings } from "@chakra-ui/react";

export function ConfirmModal({caption, question, children,size, colorScheme="brand", okButtonLabel,onOk, onCancel}:{
    caption?:string,
    question?:string,
    colorScheme?:ThemeTypings["colorSchemes"],
    okButtonLabel?:string,
    children?:any,
    size?:string,
    onOk?:()=>void,
    onCancel?:()=>void
}) {
    
    const cancelRef = useRef()
  
    return (
      <>

        <AlertDialog
          isOpen={true}
          leastDestructiveRef={cancelRef}
          onClose={onCancel}
          size={size||"3xl"}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                {caption||"Please confirm"}
              </AlertDialogHeader>
  
              <AlertDialogBody >
                {question}
                {children}
              </AlertDialogBody>
  
              <AlertDialogFooter>
              {onCancel && <Button ref={cancelRef} onClick={onCancel}>
                  Cancel
                </Button>}
                {onOk && <Button colorScheme={colorScheme} onClick={onOk} ml={3}>
                  {okButtonLabel||"OK"}
                </Button>}
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }