import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericList from '../components/GenericList/genericList';
import { HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Stack, Text, Tooltip } from '@chakra-ui/react';
import Moment from 'react-moment';
import { IconDotsVertical } from '@tabler/icons-react';
import { IconTrash } from '@tabler/icons-react';
import { useApiEndpoint } from '../utils/useApiHook';

const ListsPage: React.FC = () => {
    const navigate = useNavigate()
    const deleteEndpoint = useApiEndpoint("DELETE", "/lists/{listId}", true, true)
    return (
        <GenericList endpointPath="/lists" listTitle="Lists" primaryField="name" secondaryFields={["created_at", "category"]} onClickNew={() => navigate("/lists/new")}
            onSelected={() => { }}
            customCardRenderer={(item, index, isSelected, refresh) => {
                return (
                    <Stack
                        key={index}
                        p="10px"
                        m="5px"
                        border="1px solid gray"
                        borderRadius="8px"
                        color="gray"

                        cursor="pointer"
                        bg={isSelected ? "gray.100" : "white"}
                        _hover={{ shadow: "xl" }}
                        align="start"
                        onClick={(e) => {
                            if ((e.target as HTMLElement).closest('.listCardMenu')) {
                                return;
                            }
                            navigate(`/lists/${item.id}`)
                        }}
                    >
                        <HStack flexGrow={1} justify="space-between" width="100%">
                            <Text flexGrow={1} fontWeight={900}>{item.name}</Text>

                            <Menu >
                                <MenuButton className="listCardMenu" size="xs" variant="ghost" as={IconButton} aria-label='more' icon={<IconDotsVertical size="15px" />} />
                                <MenuList className='listCardMenu'>

                                    <MenuItem icon={<IconTrash size="15px" />} fontSize="sm"
                                        onClick={() => {
                                            deleteEndpoint.execute({ listId: item.id }).then(() => {
                                                refresh()
                                            })
                                        }}
                                    >Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Stack spacing={0}>
                            <Tooltip label={<Moment>{item.updated_at}</Moment>}><Text as={"span"} fontSize="xs">{<Moment fromNow>{item.updated_at}</Moment>}</Text></Tooltip>

                        </Stack>

                    </Stack>
                )
            }}
        />
    );
};

export default ListsPage;