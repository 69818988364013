

import { useEffect, useState } from "react";



import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useSWR from 'swr'

import { useAppContext } from "../appContext";
import { Box, Button, Card, CardBody, CardFooter, CardHeader, Flex, HStack, Heading, Input, SimpleGrid, Spinner, Stack, Text, Tooltip, Wrap, WrapItem } from "@chakra-ui/react";
import { SkillBox } from "../components/SkillBox/skillBox";
import { IconSquareRoundedPlus } from "@tabler/icons-react";
import { SkillIcon } from "../components/SkillBox/skillIcons";
import { DataSourceIcon } from "../components/DataSourcesEdit/dataSourceIcons";
import { DeleteWithConfirm } from "../components/DeleteWithConfirm/deleteWIthConfirm";

import { ConfirmModal } from "../components/ModalDialogs/confirmModal";
import { EditModal } from "../components/ModalDialogs/editModal";



export  default function AppsListPage(){
    
    //const {currentUser, logOut} = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [modal,setModal] = useState<any>(null)
   
    const loadApps = ()=>{
        
        
        return getApi().getAllApps().then((res)=>{
            return res
        })

    }

   

    const {data:allAps, isLoading, mutate: reloadApps} = useSWR(`apps`,()=>loadApps())


    


    return ( <Flex direction="column">
        {isLoading?(
            <Box p="40px" alignSelf="center">
                <Spinner size="xl"/>
            </Box>
        ):(

        
            <Wrap spacing="10px" margin={["10px", "25px"]} >
                {modal}
                {allAps?.map((app,i)=>(
                <WrapItem key={i}>
                <Card height="220px" width="300px" onClick={()=>navigate(`/app/${app.id}/setup`)} cursor="pointer" overflow="hidden">
                    <CardHeader flexDirection="row"  p="10px">
                        <HStack justify="space-between" align="start">
                        <Stack>
                            <Heading textAlign="start" size='md' noOfLines={1}>{app.name} </Heading>
                            <Text textAlign="start" fontSize="sm" color="blackAlpha.700">Chatbot</Text>
                        </Stack>
                        <DeleteWithConfirm 
                        onCancel={e=>{
                            e.stopPropagation()
                        }}
                        onClick={(e)=>{
                            e.stopPropagation()
                            getApi().deleteApp(app.id).then((res)=>{
                                reloadApps()
                            })
                        }}/>
                        </HStack>
                        {/* <Text textAlign="start" fontSize="sm">{app.namespace}</Text> */}
                    </CardHeader>
                    <CardBody p="0px 10px">
                        <Stack>
                        <Wrap>
                        {app.data_sources?.map((ds,i)=>(
                                <Tooltip  key={i} label={ds.name}>
                                    <WrapItem>
                                        <DataSourceIcon key={i} type={ds.type} size="medium" />
                                    </WrapItem>
                                </Tooltip>
                            ))}
                        </Wrap>

                        <Wrap>
                        {app.skills?.map((skill,i)=>(
                            
               
                            <Tooltip  key={i} label={skill.name}>
                                <WrapItem key={i}>
                                    <SkillIcon  type={skill.type} size="medium" />
                                </WrapItem>
                            </Tooltip>
                            
                            ))}
                        </Wrap>
                        </Stack>
                    </CardBody>
       
                </Card>
                </WrapItem>
                ))}
                 <WrapItem key={"add"}>
                    <Card height="220px" width="300px" onClick={()=>{
                        setModal(<EditModal 
                            caption="Create new AI"
                            value={{name:""}}
                            onOk={(value)=>{
                                getApi().saveApp(value)
                                setModal(undefined)
                            }}
                            onCancel={()=>setModal(undefined)}>
                                {((value,onChange)=>(
                                <NewAppModal value={value} onChange={onChange}/>
                                ))}
                            </EditModal>)
                    }} cursor="pointer" bgColor="whiteAlpha.800" shadow="none"> 
                        <CardHeader alignContent="center" p="20px 0px 0px" color="gray" >
                        
                            <Heading textAlign="center" size='md'>Create new AI</Heading>
                            <Text textAlign="center" fontSize="sm">new AI project</Text>
                        </CardHeader>
                        <CardBody p="0px 5px" alignItems="center" justifyItems="center">
                            <Flex justify="center" align="center" height="100%">

                            <IconSquareRoundedPlus size={"60px"} color="gray" />
                            </Flex>
                        </CardBody>
                            
                    </Card>
                </WrapItem>
            </Wrap>
        )}
    </Flex>
    
    )
}

const NewAppModal = ({value, onChange}:any)=>{
    
    const [withWizard,setWithWizard] = useState(false)

    

    return (
        <Stack maxW="700px" align="start">
            <Text>Name</Text>
            <Input size="sm" value={value.name} onChange={(e)=>onChange({...value,name:e.target.value})} placeholder="name of the AI project"/>
            {!withWizard ? <Button disabled={true} colorScheme="brand" variant="solid"  onClick={()=>setWithWizard(true)}>✨ Build with AI</Button>:(
            <Box>
                <Text>TODO</Text>
                {/* <GeniouslyControlModal onClose={()=>setWithWizard(false)}/> */}
            </Box>  )}
        </Stack>
    )
}