import { useEffect, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";



import { Link, Tag, Tooltip, useToast } from "@chakra-ui/react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Divider,
    Flex,
    HStack,
    Heading,
    Image,
    Input,
    SimpleGrid,
    Spinner,
    Stack,
    Switch,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from "@chakra-ui/react";

import {
    IconBolt,
    IconBook,
    IconPlugConnected,
    IconSquare,
    IconSquareCheck,
    IconWebhook,
} from "@tabler/icons-react";
import { IconApi } from "@tabler/icons-react";

import { IconArrowsExchange2 } from "@tabler/icons-react";
import { IconSettingsCode } from "@tabler/icons-react";
import { getApi } from "../../apiService";
import { AppProject, Integration } from "../../models/dataModel";

import { EditModal } from "../ModalDialogs/editModal";
import { IconUsersPlus } from "@tabler/icons-react";
import { ConfirmModal } from "../ModalDialogs/confirmModal";
import IntegrationIcon from "./integrationIcon";
import { AutoUI } from "../AutoUI/AutoUI";
import { LinkedinIntegrationDetail } from "./linkedinDetail";
import { useApiEndpoint } from "../../utils/useApiHook";


const IntegrationDetail = ({
    integration: _integration,
    app,
    icon,
    moreInfo,
    onAppChange,
    onConnect,
    onChange,
}: {
    integration: Integration;
    app?: AppProject;
    icon?: any;
    moreInfo: any;
    onAppChange?: (app: AppProject) => void;
    onConnect?: () => Promise<any> | void;
    onChange?: (val: Integration) => void;
}) => {
    const [dsAdded, setDsAdded] = useState(false);
    const [actionsAdded, setActionsAdded] = useState(false);

    const [integration, setIntegration] = useState<Integration>();
    const [modal, setModal] = useState<any>(null);
    useEffect(() => setIntegration(_integration), [_integration]);
    function getIntegration() {
        return getApi()
            .getIntegration(_integration.connector)
            .then((res) => {
                setIntegration(res);
                return res;
            });
    }
    const patchAccountEndpoint = useApiEndpoint<any>("PATCH", "/integrations/{connector}/accounts/{account}", false)
    return !integration ? (
        <></>
    ) : (
        <Stack>
            {modal}
            <Stack direction="row">
                {<IntegrationIcon type={integration.connector} />}
                <Heading size="md">{integration.name}</Heading>
            </Stack>
            {moreInfo ?? (
                <>
                    {typeof moreInfo == "string" ? (
                        <Text>{moreInfo}</Text>
                    ) : (
                        <Stack>{moreInfo} </Stack>
                    )}
                </>
            )}
            {integration.datasources?.length > 0 && (
                <Stack align="start">
                    <Text fontSize="xs" fontWeight={700}>
                        Available datasources
                    </Text>
                    <HStack align="start">
                        {integration.datasources.map((ds) => (
                            <HStack
                                key={ds.datasource_id}
                                direction="row"
                                border="1px solid gray"
                                rounded="3px"
                                p="0px 5px"
                            >
                                <IconBook size="15px" />
                                <Text fontSize="xs" fontWeight={500}>
                                    {ds.name}
                                </Text>
                            </HStack>
                        ))}
                    </HStack>
                    {/* {(app &&onAppChange && !dsAdded) &&  <Button p="0px 45px" colorScheme="green" 
                  leftIcon={<IconSquareRoundedPlus/>}  onClick={addDataSources}>Add all datasources</Button>} */}
                </Stack>
            )}
            {integration.actions?.length > 0 && (
                <Stack align="start">
                    <Text fontSize="xs" fontWeight={700}>
                        Available actions
                    </Text>
                    <HStack align="start">
                        {integration.actions.map((action) => (
                            <HStack
                                direction="row"
                                border="1px solid gray"
                                rounded="3px"
                                p="0px 5px"
                            >
                                <IconBolt size="15px" />
                                <Text fontSize="xs" fontWeight={500}>
                                    {action.action_name}
                                </Text>
                            </HStack>
                        ))}
                    </HStack>
                    {/* {(app &&onAppChange && !actionsAdded) &&  <Button   p="0px 45px" colorScheme="green"  
                  leftIcon={<IconSquareRoundedPlus/>} onClick={addCommandActions}>Add all actions</Button>} */}
                </Stack>
            )}
            {integration?.active && (
                <Text>
                    Integration is <Tag>active</Tag>
                </Text>
            )}
            <Stack m="10px 0px">
                {integration.support_multiple_accounts ? (
                    <Stack align="stretch" m="10px 0px">
                        {integration.accounts?.map((account) => (
                            <HStack
                                border="1px solid lightgray"
                                rounded="3px"
                                p="5px"
                                justify="space-between"
                            >
                                <Text fontSize="xs" fontWeight={700}>
                                    Account: {account.name}
                                </Text>
                                <HStack alignSelf="end">
                                    <Button
                                        size="xs"
                                        colorScheme="gray"
                                        variant="outline"
                                        onClick={() => onConnect()}
                                    >
                                        Reconnect
                                    </Button>
                                    {account.primary ? (
                                        <Badge colorScheme="gray">Primary</Badge>
                                    ) : (
                                        <>
                                            <Button
                                                size="xs"
                                                colorScheme="gray"
                                                variant="outline"
                                                onClick={() =>
                                                    getApi()
                                                        .patchIntegrationAccount(
                                                            integration.connector,
                                                            account.name,
                                                            { primary: true }
                                                        )
                                                        .then(() => getIntegration())
                                                }
                                            >
                                                Make primary
                                            </Button>
                                            <Button
                                                size="xs"
                                                colorScheme="red"
                                                variant="outline"
                                                onClick={() =>
                                                    getApi()
                                                        .patchIntegrationAccount(
                                                            integration.connector,
                                                            account.name,
                                                            { active: false }
                                                        )
                                                        .then(() => getIntegration())
                                                }
                                            >
                                                Remove
                                            </Button>
                                        </>
                                    )}
                                    <Badge
                                        cursor="pointer"
                                        onClick={() => {
                                            setModal(<ConfirmModal
                                                onCancel={() => setModal(null)}
                                                onOk={() => {
                                                    patchAccountEndpoint.execute({ "connector": integration.connector, "account": account.name },
                                                        { active: !account.active }
                                                    )
                                                        .then(() => getIntegration())
                                                    setModal(null)
                                                }}

                                                caption={account.active ? "Deactivate account" : "Activate account"} question={`Do you want to ${account.active ? "deactivate " : "activate account"} ${account.name} account? `} />)
                                        }} colorScheme={account.active ? "green" : "gray"} ><HStack>{account.active ? <IconSquareCheck color="gray" size="15px" /> : <IconSquare size="15px" />}<Text> {account.active ? "Active" : "Disabled"}</Text></HStack></Badge>
                                </HStack>
                            </HStack>
                        ))}
                        <Button
                            colorScheme="brand"
                            size="xs"
                            alignSelf="start"
                            leftIcon={<IconUsersPlus size="18px" />}
                            onClick={() => {
                                let res = onConnect();
                                if (res) {
                                    res.then(() => getIntegration());
                                }
                            }}
                        >
                            Connect another account
                        </Button>
                    </Stack>
                ) : (
                    <Button
                        colorScheme="brand"
                        mt="10px"
                        size="xs"
                        variant="outline"
                        alignSelf="start"
                        leftIcon={<IconPlugConnected />}
                        onClick={() => {
                            let res = onConnect();
                            if (res) {
                                res.then(() => getIntegration());
                            }
                        }}
                    >
                        {!integration?.active
                            ? "Connect integration"
                            : "Reconnect integration"}
                    </Button>
                )}
            </Stack>
            {integration.settings_schema && (
                <Stack>
                    <AutoUI
                        schema={integration.settings_schema}
                        value={integration.settings}
                        onValueChange={(val) => {
                            let newIntegration = { ...integration, settings: val };
                            setIntegration(newIntegration);

                            onChange(newIntegration);
                        }}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default IntegrationDetail;