// import { } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";



import   LoginPage from "./pages/loginPage";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import {
  AppSidebar,
  AppSidebarButton,
} from "./components/AppSidebar/appSidebar";
import HomePage from "./pages/homePage";

import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useAppContext } from "./appContext";
import Test from "./pages/testPage";
import NewAppPage from "./pages/newAppPage";
import AppsListPage from "./pages/appsListPage";
import AppEditPage from "./pages/appEditPage";
import AppHeader from "./components/AppHeader/appHeader";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import InsightsPage from "./pages/insightsPage";
import IntegrationsPage from "./pages/integrationsPage";
import ConversationsPage from "./pages/conversationsPage";
import PasswordResetPage from "./pages/passwordResetPage";
import AccountSettings from "./pages/accountSettingsPage";
import {
  IconDiscountCheck,
  IconUserCog,
  IconCreditCard,
  IconUsers,
  IconLayout2,
} from "@tabler/icons-react";
//import ShowCasePage from "./pages/showCasePage";
import IntegrationRedirectPage from "./pages/integrationRedirect";
import SimpleSettingsPage from "./pages/simpleSettingsPage";
import HumanLoopPage from "./pages/humanInTheLoop";

import ChatPage from "./pages/chatPage";
import WorkflowDebugPage from "./pages/workflowDebugPage";
import { getApi } from "./apiService";
import WorkflowDetailPage from "./pages/workflowDetailPage";
import ListEditPage from "./pages/listEditPage";
import Navigation from "./components/Navigation/navigation";
import GenericList from "./components/GenericList/genericList";
import RecipesPage from "./pages/recipesPage";
import InboxPage from "./pages/inboxPage";
import CampaignAnalytics from "./pages/analyticsPage";
import NewListPage from "./pages/newListPage";
import CrmAgentsSettings from "./components/CrmAgents/CrmAgentSettings";
import ListsPage from "./pages/listsPage";
import AdminOverview from "./pages/adminOverview";

const WithSidebar = ({ page, children, customMenuItems = undefined }: any) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  //const windowWidth=500
  const [menuOpen, setMenuOpen] = useState(false);
  const { currentUser } = useAppContext();
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update window width when resized
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mobile = windowWidth < 1000;
  return (
    <Box height="100vh">
      <AppHeader
        showMenuBtn={mobile}
        onMenuBtnClick={() => setMenuOpen(!menuOpen)}
      />

      <Grid
        templateAreas={
          !mobile
            ? `
                    "nav main"
                    `
            : `
                   
                    "main main"
                    `
        }
        gridTemplateRows={"calc(100vh - 40px)"}
        gridTemplateColumns={"60px 1fr"}
        height="100%"
        gap="0"
        color="blackAlpha.700"
        fontWeight="bold"
      >
        {!mobile && (
          <GridItem bg="white" area={"nav"}>
            {/* <AppSidebar currentUser={currentUser}>{customMenuItems}</AppSidebar> */}
            <Navigation />
          </GridItem>
        )}
        <GridItem area={"main"} overflow="auto">
          <Flex height="100%" pb="8px">
            {mobile && menuOpen && (
              <Box
                position="absolute"
                top="0px"
                left={0}
                bgColor="white"
                zIndex={9}

                p="50px 0px 0px"
                overflow="hidden"
                height="100%"
              >
                {/* <AppSidebar>{customMenuItems}</AppSidebar> */}
                <Navigation />
              </Box>
            )}
            {children || page}
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
// , (prevProps, nextProps)=>{

//   return prevProps.page===nextProps.page

// })

const LoggedInAsHeader = () => {
  const appContext = useAppContext();

  function setLoginOnAccountOfUser(value: string | null) {
    if (value === null) {
      localStorage.removeItem("loginOnAccountOfUser");
    } else {
      localStorage.setItem("loginOnAccountOfUser", value);
    }
    window.location.reload();
  }

  const [loginOnAccountOfUser, _setLoginOnAccountOfUser] = useState<
    string | null
  >();
  useEffect(() => {
    let _loginOnAccountOfUser = localStorage.getItem("loginOnAccountOfUser");
    let userEmail = getApi().currentUser?.email;

    const checkLogin = (userEmail: string) => {
      if (userEmail?.endsWith("@easypie.ai")) {
        _setLoginOnAccountOfUser(_loginOnAccountOfUser);
      } else if (!!_loginOnAccountOfUser && userEmail) {
        _setLoginOnAccountOfUser(null);
      }
    };
    if (userEmail) {
      return checkLogin(userEmail);
    } else {
      const auth = getAuth();
      onAuthStateChanged(auth, (currentUser) => {
        checkLogin(currentUser?.email);
      });
    }
  }, []);

  return loginOnAccountOfUser ? (
    <HStack background="blue.400" justify="center" top="0px" left="calc(50% - 150px)" position="fixed" zIndex={10000} p="2px 8px" borderRadius="8px">
      <Text>Logged in as </Text>
      <Text color="whiteAlpha.900">{loginOnAccountOfUser}</Text>
      <Button size="xs" onClick={() => setLoginOnAccountOfUser(null)} m="2px">
        Cancel
      </Button>
    </HStack>
  ) : (
    <></>
  );
};
const backgroundCss = "linear-gradient(90deg, white calc(15px - 1.5px), transparent calc(15px - 1.5px), transparent calc(15px * 2)) center / 15px 15px, linear-gradient(white calc(15px - 1.5px), transparent calc(15px - 1.5px), transparent 80%) center / 15px 15px, lightgray;"
const PageRouter = () => {
  const appContext = useAppContext();
  const navigate = useNavigate()

  return (
    <Stack
      css={`
    div.page {
      background: ${backgroundCss};
    }
      `}
    >

      <Routes>
        <Route path="/test" element={<Test />} />
        {/* <Route path="app/new"          element={<NewAppPage/>}/> */}
        <Route path="/welcome" element={<SimpleSettingsPage />} />
        <Route
          path="/settings"
          element={<WithSidebar page={<SimpleSettingsPage forwardIfSetUp={false} />} />}

        />


        <Route path="/home" element={<WithSidebar page={<HomePage />} />} />
        <Route path="/analytics/:campaignId?"

          element={<WithSidebar page={<CampaignAnalytics />} />}
        />
        <Route path="/home" element={<WithSidebar page={<AppsListPage />} />} />

        <Route
          path="app/:appId/setup"
          element={<WithSidebar page={<AppEditPage />} />}
        />
        <Route path="/login" element={<LoginPage mode="login" />} />
        <Route path="/signup" element={<LoginPage mode="signup" />} />
        <Route path="/password-reset" element={<PasswordResetPage />} />
        <Route
          path="/lists"
          element={<WithSidebar page={<ListsPage />} />}
        />
        <Route
          path="/workflows"
          element={<WithSidebar page={<GenericList endpointPath="/tasks" listTitle="Workflows" primaryField="task_name" secondaryFields={["created_at"]} />} />}
        />

        <Route path="/agents" element={<WithSidebar page={<CrmAgentsSettings />} />} />
        <Route path="/agents/:agentId" element={<WithSidebar page={<CrmAgentsSettings />} />} />

        <Route path="/inbox" element={<WithSidebar page={<InboxPage />} />} />
        <Route path="/inbox/:contactId" element={<WithSidebar page={<InboxPage />} />} />
        <Route path="/lists/new" element={<WithSidebar page={<NewListPage />} />} />
        <Route path="/lists/:listId" element={<WithSidebar page={<ListEditPage />} />} />
        <Route path="/lists/:listId/:listVersion" element={<WithSidebar page={<ListEditPage />} />} />

        <Route
          path="/recipes/:recipeId?"
          element={<WithSidebar page={<RecipesPage />} />}
        />

        <Route path="/admin" element={<WithSidebar page={<AdminOverview />} />} />
        <Route path="/admin/workflows" element={<WorkflowDebugPage />} />
        <Route path="/workflows/:workflowId" element={<WithSidebar page={<WorkflowDetailPage />} />} />

        <Route
          path="/settings/:settingsSection"
          element={
            <WithSidebar
              page={<AccountSettings />}
              customMenuItems={
                <>
                  <AppSidebarButton
                    icon={<IconLayout2 />}
                    name="Home"
                    path="/home"
                  />
                  <Text
                    p="10px 20px 0px"
                    fontWeight={500}
                    borderRight="1px solid rgba(0,0,0,0.15)"
                    fontSize="sm"
                    color="gray.400"
                  >
                    Settings
                  </Text>
                  <AppSidebarButton
                    icon={<IconUserCog />}
                    name="My account"
                    path="/settings/my-account"
                  />

                  <AppSidebarButton
                    icon={<IconCreditCard />}
                    name="Billing"
                    path="/settings/billing"
                  />
                  <AppSidebarButton
                    icon={<IconUsers />}
                    name="My team"
                    path="/settings/team"
                  />
                </>
              }
            />
          }
        />
        {/* <Route path="/"                     element={<HomePage/>}/>
        
        <Route path="/home"                 element={<WithSidebar page={<DashboardPage/>}/>}/>
        <Route path="/signup"               element={<LoginPage mode="signup"/>}/>
      */}

        {/* <Route path="app/:appId"       element={<WithSidebar page={<AppEditPage/>}/>}/>
         */}

        {/* <Route
          path="app/:appId/conversations"
          element={<WithSidebar page={<ConversationsPage />} />}
        />
        <Route
          path="app/:appId/insights"
          element={<WithSidebar page={<InsightsPage />} />}
        /> */}

        <Route
          path="app/:appId/integrations"
          element={<WithSidebar page={<IntegrationsPage />} />}
        />
        <Route path="app/:appId/connect" element={<IntegrationsPage />} />
        <Route path="chat/:sessionId?" element={<WithSidebar page={<ChatPage />} />} />
        {/* <Route path="app/:appId/showcase"       element={<ShowCasePage/>}/> */}
        {appContext?.currentUser ? (
          <>
            <Route path="/apps" element={<WithSidebar page={<AppsListPage />} />} />
            <Route path="/" element={<WithSidebar page={<SimpleSettingsPage />} />} />
          </>
        ) : (
          <Route path="/" element={<LoginPage mode="login" />} />
        )}
        <Route
          path="/integrations/:connector/connect/callback"
          element={<IntegrationRedirectPage />}
        />
        <Route path="*" element={<WithSidebar page={<HomePage />} />} />
      </Routes>
      <LoggedInAsHeader />
    </Stack>
  );
};

export default PageRouter;
