import { Box, Button, ButtonGroup, Checkbox, Flex, HStack, IconButton, Input, Link, Spinner, Stack, Tag, TagLabel, Text, Tooltip, useToast } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import GenericList from '../GenericList/genericList';
import { AutoUI, ChatContainer, ChatMessage, GeniouslyThemeProvider, AutoDatePicker, AutosizeTextArea } from 'geniously-chat-ui';
import { useApiEndpoint, useApiFetch } from '../../utils/useApiHook';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Moment from 'react-moment';
import { EditModal } from '../ModalDialogs/editModal';
import { IconArchive, IconBrandGmail, IconBrandLinkedin, IconChevronDown, IconClipboard, IconClipboardCheck, IconClock, IconEye, IconMail, IconMailExclamation, IconMailFilled, IconNote, IconPlus, IconRefresh, IconSend, IconSubtask } from '@tabler/icons-react';
import { ConfirmModal } from '../ModalDialogs/confirmModal';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
    AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import ContactCard from './ContactCard';
import { IconClockExclamation } from '@tabler/icons-react';
import { IconInbox } from '@tabler/icons-react';
import ContactDetail from './ContactDetail';
import CampaignsSelect from './CampaignSelect';
import { mutate } from 'swr';
import { channel } from 'diagnostics_channel';
import { ActivityCard, ArchivedActivity } from './activitiesCards';


interface ContactActivityBase {
    id: string;
    contact_id: string;
    type: "message_out" | "message_in" | "event" | "note" | "task";
    timestamp: string;
    account?: string;
    text?: string;
    metadata: Record<string, any>;
}

interface EventBase {
    id: string;
    contact_id: string;
    type: "event" | "note" | "task";
    event_type: string;
    timestamp: string;
    account?: string;
    text?: string;
    metadata: Record<string, any>;
    reminder?: string;
}

interface ExternalMessageBase extends ContactActivityBase {
    id: string;
    thread_id?: string;
    subject?: string;
    text?: string;  // to allow lazy generation
    attachments?: string[];
    has_been_read?: boolean;
    account: string;
    channel: "linkedin" | "email";

}

interface ExternalOutboundMessage extends ExternalMessageBase {
    type: "message_out";
    generation_info?: Record<string, any>;
    sent_at?: string;
    archived: boolean;
    archive_reason: string
    verification_status?: "pending" | "verified" | "rejected" | null;
    scheduled_send_time?: string;
    sending_error?: string;
    schedule_preconditions?: Record<string, any>;

}

const ActivityDetail = ({ activity, onChange }: { activity: any, onChange: (val: any) => void }) => {
    if (activity.type == "message_out" || activity.type == "message_in") {
        const message = activity as ExternalOutboundMessage //the most specific type


        return <Stack>
            <AutoUI value={activity}
                schema={{
                    type: "object",
                    properties: {
                        type: {
                            type: "string",
                            title: "Channel",
                            enum: ["message_out", "message_in", "event", "note"]

                        },
                        channel: {
                            type: "string",
                            title: "Channel",


                        },
                        timestamp: {
                            type: "string",
                            title: "Timestamp",
                            format: "date-time"
                        },
                        text: {
                            type: "string",
                            title: "Text",
                            maxLength: 10000
                        },
                        event_type: {
                            type: "string",
                            title: "Event type",
                            showConditions: { type: "event" }

                        }

                    }
                }}
            />

        </Stack>

    }
}

const ScheduleDetail = ({ activity, onChange }: { activity: ExternalOutboundMessage, onChange: (val: ExternalOutboundMessage) => void }) => {
    if (activity.type == "message_out" || activity.type == "message_in") {
        const message = activity as ExternalOutboundMessage //the most specific type
        //const sendEndpoint = useApiEndpoint("POST", `/inbox/contacts/${activity.contact_id}/activities/{activityId}/send`, true, true)
        return <Stack >
            <GeniouslyThemeProvider>
                <AutosizeTextArea value={activity.text} onApply={(val) => {
                    onChange({ ...activity, text: val })
                }} />
                <HStack justify="space-between">
                    <HStack spacing={2}>
                        <Text fontSize="xs" fontWeight={900}>
                            Scheduled send
                        </Text>

                        <AutoDatePicker value={message.scheduled_send_time} onApply={(val) => {
                            onChange({ ...message, scheduled_send_time: val })
                        }} />
                    </HStack>
                    {/* <Button size="xs" justifySelf="end" variant="outline" colorScheme="brand" onClick={() => {
                        onChange({ ...message, scheduled_send_time: undefined })
                    }} leftIcon={<IconSend size="15px" />}>Send now</Button> */}
                </HStack>
            </GeniouslyThemeProvider>
        </Stack>

    }
}



const Inbox = () => {

    const messages: ChatMessage[] = [
        {
            message: "Hello",
            type: "chat-request",
            timestamp: "2021-09-01T12:00:00Z",
            hidden: true
        }
    ]
    const { contactId } = useParams()
    const [modal, setModal] = React.useState<JSX.Element>()

    const activitiesRequest = useApiFetch<ContactActivityBase[]>(`/inbox/contacts/${contactId}/activities`, { swrOptions: { revalidateOnFocus: false }, shouldFetch: !!contactId })
    const contactDetails = useApiFetch<{ campaign_name: string, campaign_id: string, campaign_state: string, waiting_for_activity?: boolean }>(`/inbox/contacts/${contactId}/details`, { swrOptions: { revalidateOnFocus: false }, shouldFetch: !!contactId })
    const contactRequest = useApiFetch<any>(`/inbox/contacts/${contactId}`, { swrOptions: { revalidateOnFocus: false }, shouldFetch: !!contactId })
    const patchEndpoint = useApiEndpoint("PATCH", `/inbox/contacts/${contactId}/activities/{activityId}`, true, true)
    const sendEndpoint = useApiEndpoint("POST", `/inbox/contacts/${contactId}/send`, true, false)
    const refreshEndpoint = useApiEndpoint("POST", `/inbox/contacts/${contactId}/refresh`, true, false)
    const postActivityEndpoint = useApiEndpoint("POST", `/inbox/contacts/${contactId}/activities`, true, true)
    const createContactEndpoint = useApiEndpoint("POST", `/inbox/contacts`, true, false)
    const bulkArchiveEndpoint = useApiEndpoint("DELETE", "/inbox/contacts/activities/messages/bulk_archive_scheduled", true, true)
    const [currentChanelAccount, setCurrentChannelAccount] = React.useState<{ channel: string, account: string }>()
    const channels = [
        { id: "linkedin", label: "LinkedIn", icon: <IconBrandLinkedin color="#5090FF" /> },

        { id: "email", label: "Email", icon: <IconMail color="#5090FF" /> }
    ]
    const getChannelInfo = (channel: string) => {
        return channels.find(c => c.id == channel) || { label: channel, icon: <IconInbox /> }
    }
    //const accounts = useMemo(() => { activitiesRequest.data?.map(a => a.account) }, [activitiesRequest.data])
    const [fetchAvailableAccounts, setFetchAvailableAccounts] = React.useState(false)
    useEffect(() => {
        setFetchAvailableAccounts(false)
    }, [contactId])
    const getAvailableAccountOptionsEndpoint = useApiFetch<{
        channel: string,
        account: string,
        timestamp: string
    }[]>(`/inbox/contacts/${contactId}/channels`, { swrOptions: { revalidateOnFocus: false, revalidateOnReconnect: false, revalidateIfStale: false, refreshInterval: 9999999 }, shouldFetch: !!contactId && fetchAvailableAccounts })

    const [allChanelAccountOptions, setAllChanelAccountOptions] = React.useState<{ channel: string, account: string }[]>()
    useEffect(() => {
        if (activitiesRequest.data) {
            let activities = activitiesRequest.data.filter((a: any) => a.account && a.channel && !a.archived) as any[]
            let deduplicated: any = {}
            activities.forEach(a => {
                deduplicated[a.channel + a.account] = a
            })
            let options = Object.values(deduplicated).map((a: any) => ({ channel: a.channel, account: a.account }))
            if (activities && ((allChanelAccountOptions || JSON.stringify(allChanelAccountOptions) != JSON.stringify(options)))) {
                setAllChanelAccountOptions(options)
                if (activities.length > 0) {
                    let iLast = activities.length - 1
                    setCurrentChannelAccount({
                        channel: activities[iLast].channel, account: activities[iLast].account
                    })
                }

            }
        }


    }, [activitiesRequest.data])

    function getChannelIcon(channel: string) {
        switch (channel) {
            case "linkedin":
                return <IconBrandLinkedin color="#5090FF" />
            case "email":
                return <IconMail />
        }
    }
    const toast = useToast()

    const isEmail = useMemo(() => ["email", "imap", "gmail", "outlook"].includes(currentChanelAccount?.channel), [currentChanelAccount])
    const [replyTo, setReplyTo] = useState<{ thread_id?: string, subject?: string }>()
    useEffect(() => {
        if (currentChanelAccount && activitiesRequest.data) {

            let lastMessage = activitiesRequest.data.slice().reverse()?.filter(a => (a as any).channel == currentChanelAccount?.channel)[0] as any
            setReplyTo({ thread_id: lastMessage?.thread_id, subject: (lastMessage?.subject && !lastMessage?.subject.startsWith("RE:") ? "RE:" + lastMessage?.subject : lastMessage?.subject) })
        }
    }, [currentChanelAccount])

    function sentMessage(message: string) {
        if (!currentChanelAccount) {
            toast({
                title: "No account selected",
                description: "Please select account (bottom right corner) to send message",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
        let extra = {}
        if (isEmail) {
            extra = {
                thread_id: replyTo?.thread_id,
                subject: replyTo?.subject
            }
        }

        return sendEndpoint.execute({}, { text: message, ...currentChanelAccount, ...extra }).then(() => {
            activitiesRequest.mutate()
        })
    }
    const navigate = useNavigate()
    const activities = useMemo<(any)[]>(() => {
        if (activitiesRequest.data) {
            return activitiesRequest.data.map(activity => {
                if (activity.type == "message_out" || activity.type == "message_in") {
                    const message = activity as ExternalOutboundMessage //the most specific type
                    if (message.archived) {
                        return <ArchivedActivity activity={message as any} />
                    }
                    else
                        return {
                            message: activity.text,
                            type: activity.type == "message_out" ? "chat-request" : "chat-response",
                            timestamp: message.sent_at || activity.timestamp,
                            hidden: ((message.scheduled_send_time || (message.verification_status && message.verification_status != "verified")) && !message.sent_at),
                            original_message: message,
                            _extra: {
                                before: <HStack p="0px 30px">
                                    {message.sending_error && !message.sent_at && <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                        {"❌ Error "}</Text>
                                    }
                                    {message.verification_status && (
                                        <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                            {message.verification_status == "pending" ? "☑️ Pending verification" : message.verification_status == "rejected" ? "❌ Rejected " : "✅ Verified "}
                                        </Text>
                                    )}
                                    {message.sent_at ? <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                        {"Sent "}
                                        <Moment fromNow>{message.sent_at}</Moment>
                                    </Text> : <></>}
                                    {message.scheduled_send_time && !message.sent_at ? 
                                    <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                            {message.sending_error ? "🔁 Retry " : "⏱️ Scheduled "}
                                            <Moment fromNow>{message.scheduled_send_time}</Moment>
                                    </Text>
                                        : <></>}
                                    {message.scheduled_send_time && !message.sent_at ?
                                        <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                            {message.sending_error ? "🔁 Retry " : "⏱️ Scheduled "}
                                            <Moment fromNow>{message.scheduled_send_time}</Moment>
                                        </Text>
                                        : <></>}
                                    {message.has_been_read ? <Tag
                                        borderRadius='full'
                                        colorScheme="gray"

                                        size="xs"
                                        p="0px 4px"

                                    >
                                        <IconEye size="18px" color="gray" />
                                        <TagLabel ml="4px" color="gray" fontSize="xs">
                                            {typeof (message.has_been_read) == "boolean" ?
                                                <Text>Seen</Text> : (
                                                    <Tooltip
                                                        label={
                                                            <Text><Moment>{message.has_been_read}</Moment></Text>
                                                        }><Text>Seen</Text></Tooltip>
                                                )}
                                        </TagLabel>
                                    </Tag> : <></>}
                                    {message.metadata?.connect && <Text fontSize="xs" color="#5090FF" fontWeight={900}> Connection request </Text>}
                                    {getChannelIcon(message.channel)}

                                </HStack>
                            }
                        } as any


                }
                else if (["event", "task", "note"].includes(activity.type)) {
                    return <ActivityCard activity={activity as any} />
                }

                else {
                    return <Stack>
                        <AutoUI value={activity} />
                    </Stack>
                }
            })
        }
    }, [activitiesRequest.data])

    function showDialog(type: "new_message" | "new_event" | "schedule_message") {

        let newObj: any
        if (type == "schedule_message") {
            newObj = { contact_id: contactId, text: "", type: "message_out", channel: currentChanelAccount?.channel, account: currentChanelAccount?.account }
            setModal(<EditModal
                caption="Schedule message"
                value={newObj}
                onOk={(val) => {
                    postActivityEndpoint.execute({}, val).then(() => {
                        setModal(undefined)
                    }).catch(e => {
                        toast({
                            title: "Error",
                            description: e.text,
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                    })
                }}
                onCancel={() => {
                    setModal(undefined)
                }}>
                {(val, setVal) => {
                    return <ScheduleDetail activity={val as any} onChange={setVal} />
                }}
            </EditModal>)
        }
        else {
            newObj = (type == "new_message" ? ({ contactId: contactId, text: "", type: "message_out" }) : ({ contactId: contactId, text: "", type: "event" }))
            setModal(<EditModal
                caption={type == "new_message" ? "New message" : type == "new_event" ? "New event" : "Schedule message"}
                value={newObj}
                onOk={(val) => {
                    postActivityEndpoint.execute({}, val).then(() => {
                        setModal(undefined)
                    }).catch(e => {
                        toast({
                            title: "Error",
                            description: e.text,
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                    })
                }}
                onCancel={() => {
                    setModal(undefined)
                }}
            >{(val, setVal) => (
                <ActivityDetail activity={val as any} onChange={setVal} />
            )}
            </EditModal>)
        }
    }
    const [showContactDetails, setShowContactDetails] = useState<boolean>()


    const markHandledEndpoint = useApiEndpoint("PATCH", `/inbox/contacts/${contactId}/mark-as-handled`, true) 
    return (
        <HStack align="stretch" justify="stretch" height="calc(100% - 50px)">

            <GenericList collapsible endpointPath='/inbox/contacts' listTitle='Contacts' primaryField='full_name' secondaryFields={["last_activity", "headline", "position", "company"]}

                selectedId={contactId}
                customCardRenderer={(item, index, selected) => {
                    return <ContactCard key={index} color={item.waiting_for_activity ? "#e00f0f" : undefined} backgroundColor={selected ? "#eaeaea" : undefined} contact={item} />
                }}
                onSelected={(item) => {

                    navigate({
                        pathname: `/inbox/${item.id}`,
                        search: window.location.search
                    })
                }}
                extraFilterRender={(extraFilterValue, setExtraFilterValue) => {
                    return <Stack fontSize="xs" fontWeight="500" spacing={0}>
                        <CampaignsSelect campaignId={extraFilterValue.campaign} onChange={(val) => {
                            setExtraFilterValue({ campaign: val?.id })
                        }} />
                        <Checkbox size="sm" isChecked={extraFilterValue?.requires_attention} onChange={(e) => setExtraFilterValue({ requires_attention: e.target.checked ? true : undefined })} >Only unanswered</Checkbox>

                    </Stack>
                }}
                onClickNew={() => {
                    return new Promise((resolve, reject) => {
                        setModal(<EditModal
                            caption="Add new contact"
                            value={{}}
                            withFullscreenSwitch={false}
                            onOk={(val) => {
                                return createContactEndpoint.execute({}, val).then((res) => {
                                    setModal(undefined)
                                    resolve(undefined)
                                }).catch(e => {
                                    toast({
                                        title: "Error",
                                        description: e.text,
                                        status: "error",
                                        duration: 9000,
                                        isClosable: true,
                                    })
                                })
                            }}
                            onCancel={() => {
                                setModal(undefined)
                            }}>
                            {(val, setVal) => {
                                return <ContactDetail contact={val} onChange={setVal} />
                            }}
                        </EditModal>)
                    })
                }}
            />

            <HStack height="100%" flexGrow={1}>
                <Stack flexGrow={1} height="100%" border="1px solid lightgray" borderRadius={8} spacing={0} >
                {modal}
                    {contactRequest.data && <ContactCard contact={contactRequest.data}
                        detailsOpen={showContactDetails}
                        onToggleDetail={() => { setShowContactDetails(!showContactDetails) }}
                    ><Stack>
                    <HStack justify="space-between">
                        {contactDetails.data?.campaign_name ?
                            <HStack justify="start">
                        <Link href={`/analytics/${contactDetails.data.campaign_id}`}>
                            <Button alignSelf="start" variant="outline" colorScheme="brand" size="xs" >
                                Campaign: {contactDetails.data.campaign_name}
                            </Button>
                        </Link>
                        <Tag>

                            <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                State: {contactDetails.data.campaign_state}
                            </Text>
                        </Tag>
                            </HStack> : <HStack></HStack>

                        }
                        <HStack justifySelf="end">
                            {contactRequest.data?.waiting_for_activity && <Tooltip label="Mark as handled">
                                <Button leftIcon={<IconMailExclamation />} colorScheme='brand' size="xs"
                                    onClick={() => {
                                        markHandledEndpoint.execute({}).then((res) => {
                                            contactRequest.mutate()
                                        })
                                    }}
                                >Requires attention</Button>
                            </Tooltip>}

                        </HStack>
                    </HStack>

                </Stack></ContactCard>}

                <GeniouslyThemeProvider>
                        {activitiesRequest.isLoading ? <Stack align="center" justifySelf="center" alignSelf="center" flexGrow={1} height="100%" p="20vh"><Spinner />Loading...</Stack> : (

                        <ChatContainer


                            onSend={(message) => {
                                return sentMessage((message as any).message)
                            }}
                            getChatMessagesActions={((message: { original_message: ExternalOutboundMessage }) => {
                                return ((message.original_message?.scheduled_send_time || (message.original_message?.verification_status)) && !(message as any).original_message?.sent_at) ? [
                                    ...(message.original_message?.verification_status !== "pending" ? ([]) : [{
                                        label: "✅ Verify",
                                        primary: true,
                                        onClick: () => {
                                            patchEndpoint.execute({ activityId: (message as any).original_message.id }, { verification_status: "verified" }).then(() => {
                                                activitiesRequest.mutate()
                                            })
                                        }
                                    },
                                    {
                                        label: "❌ Reject",
                                        primary: true,
                                        onClick: () => {
                                            patchEndpoint.execute({ activityId: (message as any).original_message.id }, { verification_status: "rejected" }).then(() => {

                                                activitiesRequest.mutate()
                                            })
                                        }
                                    }]),

                                    ...((message.original_message.scheduled_send_time) ? [{
                                        label: "✉️ Send now",
                                        primary: true,
                                        onClick: () => {
                                            sendEndpoint.execute({}, message.original_message, { "force": true }).then(() => {

                                                activitiesRequest.mutate()
                                            })
                                        }
                                    }] : []),

                                    {
                                        label: "✏️ Edit",
                                        primary: true,
                                        onClick: () => {
                                            return new Promise((resolve, reject) => {
                                                setModal(<EditModal
                                                    caption={"Update schedule activity"}
                                                    value={(message as any).original_message}
                                                    onOk={(val) => {
                                                        patchEndpoint.execute({ activityId: (message as any).original_message.id }, { scheduled_send_time: val.scheduled_send_time, text: val.text }).then(
                                                            () => {
                                                                setModal(undefined)
                                                                resolve(undefined)
                                                                activitiesRequest.mutate()

                                                            }
                                                        )
                                                    }}
                                                    onCancel={() => {
                                                        setModal(undefined)
                                                        resolve(undefined)
                                                    }}
                                                >{(val, setVal) => {
                                                    return <ScheduleDetail activity={val as any} onChange={setVal} />
                                                }}
                                                </EditModal>)
                                            })
                                        }
                                    },


                                    // {
                                    //     label: "✏️ Edit",
                                    //     primary: true,
                                    //     onClick: () => {
                                    //         return new Promise((resolve, reject) => {
                                    //             setModal(<EditModal
                                    //                 caption={"Edit activity"}
                                    //                 value={(message as any).original_message}
                                    //                 onOk={(val) => {
                                    //                     setModal(undefined)
                                    //                     resolve(undefined)
                                    //                 }}
                                    //                 onCancel={() => {
                                    //                     setModal(undefined)
                                    //                     resolve(undefined)
                                    //                 }}
                                    //             >{(val, setVal) => {
                                    //                 return <ActivityDetail activity={val as any} onChange={setVal} />
                                    //             }}
                                    //             </EditModal>)
                                    //         })
                                    //     }
                                    // },
                                    {
                                        label: "✖️ Archive",
                                        primary: true,
                                        onClick: () => {
                                            setModal(<ConfirmModal caption='Archive' question='Do you want do archive this message (it wont be sent)?' onOk={() => {
                                                return patchEndpoint.execute({ activityId: (message as any).original_message.id }, { archived: true }).then(() => {
                                                    activitiesRequest.mutate()
                                                    setModal(undefined)
                                                })

                                            }}
                                                onCancel={() => {
                                                    setModal(undefined)
                                                }}
                                            />)
                                        }
                                    },
                                    ...[
                                        {
                                            label: "✖️ Archive all in campaign",
                                            primary: true,
                                            onClick: () => {
                                                setModal(<ConfirmModal caption='Archive all in this campaign' question='Do you want to archive all unsent messages in this campaign?' onOk={() => {
                                                    return bulkArchiveEndpoint.execute({}, { metadata_filter: { campaign_name: (message as any).original_message?.metadata?.campaign_name } }).then(() => {
                                                        activitiesRequest.mutate()
                                                        setModal(undefined)
                                                    })

                                                }}
                                                    onCancel={() => {
                                                        setModal(undefined)
                                                    }}
                                                />)
                                            }
                                        }
                                    ].filter(() => (message as any).original_message?.metadata?.campaign_name),
                                    ...[
                                        {
                                            label: "📑 Copy error id",
                                            primary: true,
                                            onClick: () => {
                                                return navigator.clipboard.writeText((message as any).original_message?.id)
                                            }
                                        }
                                    ].filter(() => !(message as any).original_message?.sent_at && (message as any).original_message?.sending_error),

                                ] : []
                            }) as any}
                            messages={activities}
                        />
                    )}
                </GeniouslyThemeProvider>
                <HStack justify="space-between">
                    <HStack justify="start" p="8px" flexGrow={1}>
                        {isEmail && replyTo &&
                            <Stack spacing={0} flexGrow={1}>
                                <HStack mt="-5px">

                                    <Checkbox size="sm" isChecked={!!replyTo?.thread_id} onChange={(e) => {
                                        if (e.target.checked) {

                                            let lastMessage = activitiesRequest.data.slice().reverse()?.filter(a => (a as any).channel == currentChanelAccount?.channel)[0] as any
                                            setReplyTo({ thread_id: lastMessage?.thread_id, subject: (lastMessage?.subject && !lastMessage?.subject.startsWith("RE:") ? "RE:" + lastMessage?.subject : lastMessage?.subject) })
                                        }
                                        else {
                                            setReplyTo({ subject: "" })
                                        }
                                    }} />
                                    <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                        Reply to last {currentChanelAccount.channel} message
                                    </Text>

                                </HStack>
                                <Input
                                        isDisabled={!!replyTo?.thread_id} border={!replyTo?.thread_id && !replyTo?.subject ? "1px solid red" : "1px solid gray"} size="xs"
                                    placeholder="Subject" value={replyTo?.subject} onChange={(e) => setReplyTo({ subject: e.target.value })} />



                            </Stack>
                        }
                    </HStack>
                <HStack justify="end" p="8px">
                            <Menu size="sm" variant="outline" >
                                <MenuButton size="xs" as={Button} leftIcon={<IconPlus size="15px" />} rightIcon={<IconChevronDown size="15px" />} variant="outline" colorScheme="blackAlpha">
                            Add message or event
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => showDialog("new_event")}>🔖 Add event</MenuItem>
                            <MenuItem onClick={() => showDialog("new_message")}>📨 Add message</MenuItem>
                            <MenuItem onClick={() => showDialog("schedule_message")}>⏰ Schedule a message</MenuItem>

                        </MenuList>
                    </Menu>
                            <ButtonGroup size='sm' isAttached variant="outline" colorScheme="blackAlpha">
                        <Menu size="sm">
                                    <MenuButton size="xs" as={Button} rightIcon={<IconChevronDown size="15px" />} onClick={() => setFetchAvailableAccounts(true)}>
                            {currentChanelAccount ? <HStack><Box>{getChannelInfo(currentChanelAccount?.channel).icon}</Box><Text>{currentChanelAccount.account}</Text></HStack> : <Text>Choose account for outreach</Text>}
                        </MenuButton>
                        <MenuList>
                                {getAvailableAccountOptionsEndpoint.isLoading ? <MenuItem><Spinner size="sm" /> Loading...</MenuItem> :
                                        getAvailableAccountOptionsEndpoint.data?.map(o => <MenuItem opacity={o.timestamp ? undefined : 0.5}
                                        onClick={() => {
                                            setCurrentChannelAccount(o)
                                        }}
                                        ><Box width="40px" >{getChannelInfo(o.channel)?.icon}</Box> {o.account}</MenuItem>)}

                        </MenuList>
                    </Menu>
                                <Tooltip label={"Refresh messages for " + currentChanelAccount?.account + " " + currentChanelAccount?.channel} placement='top-end'>
                                    <IconButton isLoading={refreshEndpoint.isRunning} size="xs" aria-label="Refresh messages" icon={<IconRefresh size="15px" />}
                                        onClick={() => refreshEndpoint.execute({}, {}, { channel: currentChanelAccount.channel, account: currentChanelAccount.account }).then(() => {
                                            activitiesRequest.mutate()
                                            getAvailableAccountOptionsEndpoint.mutate()
                                        })} />
                                </Tooltip>
                            </ButtonGroup>
                </HStack>
                </HStack >
                </Stack>
                {showContactDetails && <Stack flexGrow={1} w="30vw" minW="350px" border="1px solid lightgray" height="100%" borderRadius={8} spacing={0} >
                    <ContactDetail contact={contactRequest.data} onUpdated={(val) => {
                        contactRequest.mutate(val)
                        getAvailableAccountOptionsEndpoint.mutate()
                    }} />
                </Stack>}
            </HStack>
        </HStack>
    );
};

export default Inbox;