

import { useEffect, useMemo, useState } from "react";



import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useSWR from 'swr'

import { useAppContext } from "../appContext";
import { Box, Button, Card, CardBody, CardFooter, CardHeader, Flex, HStack, Heading, Input, SimpleGrid, Spinner, Stack, Text, Tooltip, Wrap, WrapItem } from "@chakra-ui/react";
import { SkillBox } from "../components/SkillBox/skillBox";
import { IconSquareRoundedPlus } from "@tabler/icons-react";
import { SkillIcon } from "../components/SkillBox/skillIcons";
import { DataSourceIcon } from "../components/DataSourcesEdit/dataSourceIcons";
import { DeleteWithConfirm } from "../components/DeleteWithConfirm/deleteWIthConfirm";

import { ConfirmModal } from "../components/ModalDialogs/confirmModal";
import { EditModal } from "../components/ModalDialogs/editModal";
import GenericList from "../components/GenericList/genericList";
import { RecipeDetail } from "../components/WorkflowDebuging/recipeDetail";
import { useApiEndpoint, useApiFetch } from "../utils/useApiHook";



interface TaskRecipe {
    id?: string
    task_name: string;
    task_description?: string | null;
    tags?: string[] | null;
    collect_information_instructions?: string | null;
    recipe_instructions: string;
    recipe_definition?: string | null;
}




export default function RecipesPage() {

    //const {currentUser, logOut} = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { recipeId } = useParams()
    const [modal, setModal] = useState<any>(null)


    const [currentRecipe, setCurrentRecipe] = useState<any>()
    const recipeFetch = useApiFetch(`/tasks/workflow-recipes/${recipeId}`, { shouldFetch: !!recipeId })
    const updateEndpoint = useApiEndpoint("POST", `/tasks/workflow-recipes/${recipeId}`, true, true)
    const deleteEndpoint = useApiEndpoint("DELETE", `/tasks/workflow-recipes/${recipeId}`, true, true)
    const createEndpoint = useApiEndpoint("POST", `/tasks/workflow-recipes/`, true, true)
    useEffect(() => {
        if (recipeFetch.data) {
            setCurrentRecipe(recipeFetch.data)
        }
    }, [recipeFetch.data])
    const isModified = useMemo(() => JSON.stringify(currentRecipe) != JSON.stringify(recipeFetch.data), [currentRecipe, recipeFetch.data])




    return (<Flex direction="column" justify="stretch" width="100%">
        <HStack justify="start" p="4px 8px" align="start">

            <GenericList
                collapsible={!!recipeId}
                defaultExpanded={!!recipeId}
                endpointPath="/tasks/workflow-recipes"
                listTitle="Recipes"
                primaryField="task_name"
                secondaryFields={["tags", "task_description"]}
                onSelected={(recipe) => {
                    navigate(`/recipes/${recipe.id}`)
                }}
                selectedId={recipeId}
            />
            {currentRecipe && <>
                <Button isDisabled={!isModified} isLoading={updateEndpoint.isRunning} colorScheme="brand" size="sm" onClick={() => {
                    updateEndpoint.execute({}, currentRecipe).then((res) => {
                        setCurrentRecipe(res)
                    })

                }}>Save</Button>
                <Button isLoading={deleteEndpoint.isRunning} variant="outline" colorScheme="brand" size="sm" onClick={() => {
                    setModal(<ConfirmModal caption="Delete recipe" question="Are you sure you want to delete this recipe?"
                        onCancel={() => setModal(undefined)}
                        onOk={() => {
                            deleteEndpoint.execute({}, currentRecipe).then((res) => {
                                setCurrentRecipe(res)
                            })
                            setModal(undefined)
                        }} />)

                }}>Delete</Button>
            </>
            }
            <Button size="sm" colorScheme="brand" variant="outline" justifySelf="end" onClick={() => {
                navigate("/recipes")
                setCurrentRecipe({})
                setModal(
                    <EditModal value={{
                        task_name: "",
                        recipe_instructions: "",
                    }} caption="New recipe" onOk={(value) =>
                        createEndpoint.execute({}, value).then((res) => {
                            navigate("/recipes/" + res.id)
                        })
                    } onCancel={() => setModal(undefined)}>
                        {(val, setVal) => <RecipeDetail recipe={val} onChange={setVal} />}

                    </EditModal>
                )
            }}>New</Button>
        </HStack>


        {modal}
        {currentRecipe && (
            <Stack flexGrow={1} mt="8px">

                <RecipeDetail recipe={currentRecipe} onChange={(recipe) => {
                    setCurrentRecipe(recipe)
                }} />
            </Stack>
        )}

    </Flex>

    )
}

const NewAppModal = ({ value, onChange }: any) => {

    const [withWizard, setWithWizard] = useState(false)



    return (
        <Stack maxW="700px" align="start">
            <Text>Name</Text>
            <Input size="sm" value={value.name} onChange={(e) => onChange({ ...value, name: e.target.value })} placeholder="name of the AI project" />
            {!withWizard ? <Button disabled={true} colorScheme="brand" variant="solid" onClick={() => setWithWizard(true)}>✨ Build with AI</Button> : (
                <Box>
                    <Text>TODO</Text>
                    {/* <GeniouslyControlModal onClose={()=>setWithWizard(false)}/> */}
                </Box>)}
        </Stack>
    )
}