



import { useState, useEffect, useMemo, useCallback } from "react";

import Editor, { useMonaco } from "@monaco-editor/react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Badge,
    Text,
    Stack,
    Box,
    Input,
    Checkbox,
    Spinner,
    Switch,
  } from '@chakra-ui/react'
import { getApi } from "../../apiService";
import { AutoUI } from "../AutoUI/AutoUI";
import { ConfirmModal } from "../ModalDialogs/confirmModal";
import { ChatComponent, ChatService, GeniouslyThemeProvider } from "geniously-chat-ui";

interface CodeFeedback{
    is_error:boolean
    lineno:number
    step_name:string
    text:string
}

export const AdvancedComplexDataEditor= ({value, onChange}:
  {
    value:any,
    onChange:(value:any)=>any,
    
  })=>{

const [editAsJson, setEditAsJson] = useState<boolean>();
const [jsonValue, _setJsonValue] = useState<string>();
const [error, setError] = useState<string>();

function setJsonValue(val:string){
  _setJsonValue(val)
  try{
    onChange(JSON.parse(val))
    setError(undefined)
  }
  catch(e){
    setError(e)
  }
}
useEffect(()=>{
  try{

    if (!jsonValue || (JSON.stringify(JSON.parse(jsonValue))!=JSON.stringify(value))){
      setJsonValue(JSON.stringify(value, null, 2))
    }
  }catch{
    setJsonValue(JSON.stringify(value, null, 2))
  }
},[value, editAsJson])





return (
  
                <Stack spacing={2} >
                    <HStack>
                    <Switch onChange={(e)=>setEditAsJson(e.target.checked)} isChecked={editAsJson} />
                    <Text>Edit as JSON</Text>
                    </HStack>
                {!editAsJson ?
                    (value ?<AutoUI value={value} onValueChange={(value)=>onChange(value)} />:<Text>No inputs</Text>)
                :
                <>
                    <Text color="red.500">{error}</Text>
                    <Editor height="80vh" defaultLanguage="json" value={jsonValue} onChange={(val)=>setJsonValue(val)} />
                </>
                 }
                </Stack>
          
)
}
