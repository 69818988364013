

import {
  Box, 
  Input, 
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  useDisclosure,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  useOutsideClick,
} from '@chakra-ui/react'
import  { useState, useRef, useEffect, useMemo } from 'react';
import { CalendarPanel ,Weekday_Names_Short, Month_Names_Short} from "chakra-dayzed-datepicker";


const SingleCalendar = ({date, onChange}:{
  date?:Date,
  onChange?:(date:Date)=>void
}) => {
    
  
    const handleOnDateSelected = (props: {
      date: Date;
      nextMonth: boolean;
      prevMonth: boolean;
      selectable: boolean;
      selected: boolean;
      today: boolean;
    }) => {
      
      if (props.date instanceof Date && !isNaN(props.date.getTime())) {
        const _date = new Date(props.date?.getTime()) 
        if (date){
          _date.setHours(date.getHours())
          _date.setMinutes(date.getMinutes())
        }
        if (date){
          _date.setHours(0)
          _date.setMinutes(0)
          _date.setSeconds(0)
          _date.setMilliseconds(0)
        }
        onChange(_date);
      }
    };

    const updateTime = ( hour=undefined, min=undefined)=>{
      const _date = date?new Date(date?.getTime()) : new Date()
      if (hour!==undefined){

        _date.setHours(hour)
        if (_date.toISOString()!==date?.toISOString()){
          onChange(_date)
        }
      }
      if (min!==undefined){
        if (min>=60){
          _date.setHours(_date.getHours()+1)
          _date.setMinutes(0)
          if (_date.toISOString()!==date?.toISOString()){
            onChange(_date)
          }
        }
        else if (min<0){
          _date.setHours(_date.getHours()-1)
          _date.setMinutes(45)
          if (_date.toISOString()!==date?.toISOString()){
            onChange(_date)
          }
        }
        else{
          _date.setMinutes(min)
          if (_date.toISOString()!==date?.toISOString()){
            onChange(_date)
          }
        }

      }
    }
  
    return (
      <Stack>
          
        <CalendarPanel
          dayzedHookProps={{
            showOutsideDays: true,
            onDateSelected: handleOnDateSelected,
            selected: date,
          
          }}
          configs={{
            dateFormat: 'yyyy-MM-dd',
            monthNames: Month_Names_Short,
            dayNames: Weekday_Names_Short,
            firstDayOfWeek: 0,
          }}
        />
        <Box>
        <Stack direction="row" align="center" justify="end">
            
            <NumberInput  min={-1} max={24} size='sm'  maxW={20} value={date?.getHours()||0}  format={v=>(v||0).toString().padStart(2, '0')} onChange={(_,val)=>updateTime(val, undefined)} allowMouseWheel>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
            </NumberInput>
            <Text>:</Text>
            <NumberInput   step={15}  size='sm' value={date?.getMinutes()||0}  maxW={20} format={v=>(v||0).toString().padStart(2, '0')}  onChange={(_,val)=>updateTime(undefined, val)} allowMouseWheel>
            <NumberInputField />
            <NumberInputStepper   >
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
            </NumberInput>
          </Stack>
        {/* <InputWithPopover value={date?.getHours()?.toString()||""} onValueChange={()=>{
          console.log("change")
        }}>
          <Box>
            
          </Box>
        </ InputWithPopover> */}
        </Box>
      </Stack>
    );
  };


export const AutoDatePicker = ({value,className, onValueChange,minWidth=null,onApply,placeholder,readOnly,  ...rest}:{
  value?:string, 
  minWidth?:number,
  className?:string,
  placeholder?:string,
  onValueChange?:(val:string)=>any,
  onApply?: (value)=>any,
  readOnly?:boolean,
  [key:string]:any

}) => {
  const FORMAT_SETTINGS:{localeSetting:any, formatPattern:string}  = useMemo(()=>
  {
    const localeSetting:any = { year: 'numeric', month: 'numeric', day: 'numeric',hour:"numeric",minute:"numeric" }
    const formatted = (new Date(1999,7,7,6,5,4)).toLocaleDateString(navigator.language,localeSetting);
    const formatPattern = formatted.replace(/(1999)|(99)/,"YYYY").replace(/0?8/,"MM").replace(/0?7/,"DD").replace(/0?6/,"hh").replace(/0?5/,"mm").replace(/0?4/,"ss")
    return {
      localeSetting,
      formatPattern
    }
  }
  ,[])


  const formatDate=(dateStr)=>{
    return dateStr?(new Date(dateStr)).toLocaleDateString(navigator.language,FORMAT_SETTINGS.localeSetting):undefined;
  }

  const [formattedDate, _setFormattedDate] = useState<string>(formatDate(value));
  const setFormattedDate =(val)=>{
    if (val!==formattedDate){
      if(!val){
        console.log(val)
      }
      _setFormattedDate(val)
    }
  }
  
  const [error, setError] = useState<string>(undefined);
  const [date, _setDate] = useState(new Date());
  const setDate = (date)=>{
    _setDate(date)
    setFormattedDate(formatDate(date))
  }
  const popoverRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<any>(null);
  

  
  

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

  const reverseFormatRegex = useMemo(()=>{
    const regex_pattern = escapeRegExp(FORMAT_SETTINGS.formatPattern).replace(/[,| ]+/g,"[,|\\s]+").replace("YYYY","(?<year>\\d{2,4})").replace("MM","(?<month>\\d{1,2})").replace("DD","(?<day>\\d{1,2})").replace("hh","(?<hour>\\d{1,2})").replace("mm","(?<minute>\\d{2})").replace("ss","(?<second>\\d{2})")
    const parseRegex = new RegExp(regex_pattern)
    return (text)=>{
      return parseRegex.exec(text)?.groups
    }
    
  },[])
  const parseDate=(dateStr)=>{
    const dateParts = reverseFormatRegex(dateStr)
    if (dateParts && dateParts.year && dateParts.month && dateParts.day){
      try{

        const parsed =  new Date(
          parseInt(dateParts.year)||undefined,
          (parseInt(dateParts.month)-1)||undefined,
          parseInt(dateParts.day)||undefined,
          parseInt(dateParts.hour)||0,
          parseInt(dateParts.minute)||0,
          parseInt(dateParts.second)||0,
          )
          return parsed
        }
        catch(e){
          return undefined
        }
    }
  }


  useEffect(() => {
    let parsed;
    if (!onValueChange && formatDate){
      return
    }
    try{
      if (value){
        parsed = new Date(Date.parse(value))
        if (parsed){
          setDate(parsed)
        }
      }
    }
    catch(e){
      console.error(e)
    }
    if (!(value && formattedDate && (parsed===date))){
      if (value){
      setFormattedDate(formatDate(value))
      }
      else
      {
        setFormattedDate(undefined)
      }
    }
  },[value]);

  useEffect(() => {
    if (onValueChange){
      if ( formattedDate?.trim() && formatDate(value)!==formattedDate?.trim()){
          let parsed = parseDate(formattedDate)
          if (parsed && !isNaN(parsed as any) ){
            setError(undefined)
            setDate(parsed)
            if (parsed?.toISOString()!==value){

              onValueChange(parsed?.toISOString())
            }
          }else{
            setError("Invalid date")
          }
      }else{
        
        if (value){
          onValueChange(null)
        }
      }
    }
    else if (formattedDate?.trim() && formatDate(date)!==formattedDate?.trim()){
      let parsed = parseDate(formattedDate)
      if (parsed && !isNaN(parsed as any) ){
        setError(undefined)
        setDate(parsed)
        
      }else{
        setError("Invalid date")
      }
    }
  }, [formattedDate]);


  
  // useOutsideClick({ref:inputRef, handler:(event)=>{
  //   console.log(formattedDate)
  //         if (event.target == inputRef.current){
            
  //           return
  //         }
  //           if (popoverRef.current &&  !popoverRef.current?.contains(event.target as Node)) {
  //               // Clicked outside the div, do something...
  //               // if (formattedDate?.trim()){
  //               //   let _date = parseDate(formattedDate)
  //               //   if ( !isNaN(_date as any) ){
  //               //     onApply && onApply(_date?.toISOString())
  //               //   }
  //               // }
  //               // else{
  //               //   onApply && onApply(undefined)
  //               // }
  //               isOpen && onClose()

  //           }
  // }})

  const { isOpen, onToggle,onOpen, onClose:_onClose } = useDisclosure()
  const onClose = ()=>{
     
          if (formattedDate?.trim()){
            let _date = parseDate(formattedDate)
            if ( !isNaN(_date as any) ){
              onApply && onApply(_date?.toISOString())
            }
          }
          else{
            onApply && onApply(undefined)
          }
  }

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //         if (event.target == inputRef.current){
            
  //           return
  //         }
  //           if (popoverRef.current &&  !popoverRef.current?.contains(event.target as Node)) {
  //               // Clicked outside the div, do something...
  //               if (formattedDate?.trim()){
  //                 let _date = parseDate(formattedDate)
  //                 if ( !isNaN(_date as any) ){
  //                   onApply && onApply(_date?.toISOString())
  //                 }
  //               }
  //               else{
  //                 onApply && onApply(undefined)
  //               }
  //               isOpen && onClose()

  //           }
        
  //   };

  //   // Attach the event listener on mount
  //   document.addEventListener('mousedown', handleClickOutside);

  //   // Clean up the event listener on unmount
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [popoverRef,isOpen]);

  return (
    <Box width={FORMAT_SETTINGS?.formatPattern?.length ?FORMAT_SETTINGS?.formatPattern?.length*10 + "px":undefined }  overflow="hidden">
    <Popover
    isOpen={isOpen}
    onClose={onClose}
    closeOnBlur={true}
    >
  <PopoverTrigger>
  <Input ref={inputRef} {...rest} readOnly={readOnly} className={className}  value={formattedDate||""}  p="2px" size="xs" fontSize="sm" placeholder={FORMAT_SETTINGS?.formatPattern}
  color={error?"red":"black"}
      type="text" //style={{ width, maxWidth:"100%",  fontWeight:"500"}}  
      onChange={e=>{

        setFormattedDate(e.target.value)

      }}
      rounded={4}
      onClick={(e)=>{
        if(!isOpen && !readOnly){

          onOpen()
        }
      }}
       />
  </PopoverTrigger>
  <PopoverContent ref={popoverRef}>
    <PopoverArrow />
    
    
    <PopoverBody>
        <SingleCalendar date={date} onChange={val=>setDate(val)}/> 

    </PopoverBody>
  </PopoverContent>
</Popover>
   </Box>
  );
};



