import { useEffect, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useSWR from "swr";

import { Link, Tag, Tooltip, useToast } from "@chakra-ui/react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  HStack,
  Heading,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import {
  IconBolt,
  IconBook,
  IconPlugConnected,
  IconSquare,
  IconSquareCheck,
  IconWebhook,
} from "@tabler/icons-react";
import { IconApi } from "@tabler/icons-react";

import { IconArrowsExchange2 } from "@tabler/icons-react";
import { IconSettingsCode } from "@tabler/icons-react";
import { getApi } from "../../apiService";
import { AppProject, Integration } from "../../models/dataModel";

import { EditModal } from "../ModalDialogs/editModal";
import { IconUsersPlus } from "@tabler/icons-react";
import { ConfirmModal } from "../ModalDialogs/confirmModal";
import IntegrationIcon from "./integrationIcon";
import { AutoUI } from "../AutoUI/AutoUI";
import { LinkedinIntegrationDetail } from "./linkedinDetail";
import { useApiEndpoint } from "../../utils/useApiHook";
import IntegrationDetail from "./integrationDetail";
import EmailIntegrationDetail from "./emailDetail";

const IntegrationBox = ({
  id,
  name,
  icon,
  description,
  confirmationBeforeConnect,
  moreInfo,
  tags,
  color,
  integration,
  app,
  onAppChange,
  onConnect,
  onDisconnect,
  onOpenDetail,
  onRefresh,
}: {
  id: string;
  name?: string;
  description?: string | any;
  moreInfo?: string | any;
  tags?: string[];
  color?: string;
  icon?: any;
  confirmationBeforeConnect?: any;
  integration?: Integration;
  app?: AppProject;
    onOpenDetail?: (integration?: Integration, defaultOnConnect?: () => Promise<any>) => void;
  onAppChange?: (app: AppProject) => void;
  onConnect?: (integration?) => Promise<any> | void;
  onDisconnect?: () => any;
  onRefresh?: () => any;
}) => {
  const { appId } = useParams();
  const [_integration, setIntegration] = useState<Integration>();
  useEffect(() => setIntegration(integration), [integration]);
  function getIntegration() {
    return getApi()
      .getIntegration(id)
      .then((res) => {
        setIntegration(res);
        return res;
      });
  }
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams && _integration) {
      const openConnector = searchParams.get("connector");
      let timeout = setTimeout(() => {
        if (openConnector == id) {
          if (!_active) {
            if (onConnect) {
              onConnect(integration);
            } else {
              defaultOnConnect();
            }
          } else {
            onOpenDetailInternal();
          }
        }
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [searchParams, _integration]);
  const toast = useToast();
  const defaultOnConnect = (skipConfirm = false) => {
    if (confirmationBeforeConnect && !skipConfirm) {
      setModal(
        <ConfirmModal
          caption={name}
          onOk={() => {
            setModal(undefined);
            defaultOnConnect(true);
          }}
          onCancel={() => setModal(undefined)}
        >
          {confirmationBeforeConnect}
        </ConfirmModal>
      );
      return;
    }

    return getApi()
      .getIntegration(id)
      .then((integration) => {
        if (integration?.auth_method === "oauth2") {
          setLoading(true);
          const followup_uri =
            window.location.origin +
            "/integrations/" +
            id +
            "/connect/callback";
          return getApi()
            .connectIntegration(id, {
              redirect: false,
              followup_uri: followup_uri,
            })
            .then(({ url }: any) => {
              let popupWindow = window.open(
                url,
                "_blank",
                "width=650,height=700"
              );
              if (popupWindow) {
                popupWindow &&
                  window.addEventListener("message", (event) => {
                    console.log("window.addEventListener", event);
                    setLoading(false);

                    getIntegration().then((res) => {
                      console.log("getIntegration", res);

                      //onOpenDetailInternal(res);
                    });
                  });
                let timer = setInterval(() => {
                  if (popupWindow == null || popupWindow.closed) {
                    setLoading(false);
                    return getIntegration().then((res) => {
                      console.log("getIntegration", res);
                      setIntegration(res);
                      if (res.active && res.accounts?.length && window.parent) {
                        window.parent.postMessage("connected:" + id, "*");
                      }
                      clearInterval(timer);
                      return res;
                      //onOpenDetailInternal(res);
                    });
                  }
                }, 1000);
              } else {
                toast({
                  title: "Popup blocked",
                  description:
                    "Please allow popups for this site and try again",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
              }
            })
            .catch((err) => {
              setLoading(false);
              console.error(err);
            });
        } else if (integration?.connector === "linkedin") {
          setModal(
            <LinkedinIntegrationDetail
              integration={integration}

            ></LinkedinIntegrationDetail>
          );
        } else if (["ms_outlook", "gmail"].includes(integration?.connector)) {
          setModal(
            <EditModal
              value={{}}
              onOk={(val) => {
                getApi()
                  .connectIntegration(id, { credentials: val })
                  .then((res) => {
                    console.log("connectIntegration", res);
                    if (res.success) {
                      setIntegration(res.integration);
                      setModal(undefined);
                      onRefresh();
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
              onCancel={() => setModal(undefined)}
              caption={`Connect to ${name}`}
            >
              {(val, setVal) => (
                //<Box></Box>
                <Box>
                  <EmailIntegrationDetail
                    integration={integration}
                    onConnect={onConnect || defaultOnConnect}
                    onChange={(val) => {
                      setVal(val);
                    }}


                  ></EmailIntegrationDetail>
                </Box>
                // ["api_key", "linkedin_cookie", "user_agent"]
              )}
            </EditModal>
          );
        } else if (integration?.auth_method === "api-key") {
          setModal(
            <EditModal
              value={{}}
              onOk={(val) => {
                getApi()
                  .connectIntegration(id, { credentials: val })
                  .then((res) => {
                    console.log("connectIntegration", res);
                    if (res.success) {
                      setIntegration(res.integration);
                      setModal(undefined);
                      onRefresh();
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
              onCancel={() => setModal(undefined)}
              caption={`Connect to ${name}`}
            >
              {(val, setVal) => (
                //<Box></Box>
                <RequestApiKey
                  connector={integration.connector}
                  connectorName={integration.name}
                  credentialsVal={val}
                  setCredentialsVal={setVal}
                />
              )}
            </EditModal>
          );
        }
      });
  };

  const [isLoading, setLoading] = useState(false);
  function defaultOnDisconnect() {
    setLoading(true);
    getApi()
      .disconnectIntegration(id)
      .then(() => {
        setLoading(false);
        setIntegration(undefined);
        if (onRefresh) {
          onRefresh();
        }
      });
  }
  const [modal, setModal] = useState<any>(null);

  function onOpenDetailInternal(integrationOverride = undefined) {
    console.log("open detail", integrationOverride);
    const __integration = integrationOverride || _integration;
    if (!__integration) {
      getIntegration().then((integration) => {
        onOpenDetailInternal(integration);
      });
      return;
    }
    if (onOpenDetail) {
      onOpenDetail(__integration, (onConnect || defaultOnConnect) as any);
      return;
    } else {
      setModal(
        <EditModal
          caption={__integration.name}
          value={__integration}
          onOk={(val) => {
            setModal(undefined);
            if (
              JSON.stringify(val.settings) !=
              JSON.stringify(__integration.settings)
            ) {
              getApi()
                .patchIntegrationSettings(id, val.settings)
                .then(() => {
                  getIntegration().then((res) => {
                    setIntegration(res);
                    onRefresh();
                  });
                });
            }
          }}
          onCancel={() => setModal(undefined)}
        >
          {(val, setVal) => (
            <IntegrationDetail
              integration={val}
              app={app}
              moreInfo={moreInfo}
              onAppChange={onAppChange}
              onConnect={onConnect || defaultOnConnect}
              onChange={(val) => {
                setVal(val);
              }}
            />
          )}
        </EditModal>
      );
    }
  }

  function getTagColor(tag: string) {
    if (tag == "mail") {
      return "cyan";
    } else if (tag == "calendar") {
      return "blue";
    } else if (tag == "task") {
      return "green";
    } else if (tag == "search") {
      return "orange";
    } else {
      return "gray";
    }
  }

  const showMoreInfoPopup = () => {
    setModal(
      <ConfirmModal caption="More info">
        {typeof moreInfo == "string" ? (
          <Text>{moreInfo}</Text>
        ) : (
          <Stack>{moreInfo}</Stack>
        )}
      </ConfirmModal>
    );
  };
  const _active = _integration?.active ? true : false;
  return (
    <Card
      cursor="pointer"
      p="15px"
      borderRadius="10px"
      boxShadow="md"
      width={["100%", "340px"]}
      border="1px solid lightgray"
      rounded={10}
      bgGradient="linear(to-b, white, gray.50)"
      bg="white"
      onClick={(e) => {
        if (!(e.target as any).classList.contains("inner-button")) {
          onOpenDetailInternal();
        }
      }}
    >
      {modal}
      <CardBody p="5px">
        <HStack align="start">
          <Box border="1px solid lightgray" padding="3px" rounded={8}>
            <Stack
              bgColor={color}
              rounded={5}
              p="5px"
              minWidth="50px"
              minH="50px"
              justify="center"
            >
              {!icon ? (
                <IntegrationIcon type={id} />
              ) : typeof icon === "string" ? (
                <Image src={icon} width="40px" height="auto" maxH="40px" />
              ) : (
                icon
              )}
            </Stack>
          </Box>
          <VStack align="start">
            <Heading size="md"> {name}</Heading>
            <Stack spacing={0}>
              <Text fontWeight={500} fontSize="xs">
                {description}
              </Text>

              {tags?.length && (
                <Stack direction="row">
                  {tags.map((tag) => (
                    <Badge key={tag} colorScheme={getTagColor(tag)}>
                      {tag}
                    </Badge>
                  ))}
                </Stack>
              )}
              {
                <Link
                  color="blue.800"
                  fontSize="xs"
                  onClick={() => onOpenDetailInternal()}
                >
                  More info
                </Link>
              }
            </Stack>
          </VStack>
        </HStack>
      </CardBody>
      <Divider orientation="horizontal" />
      <CardFooter p="5px">
        {isLoading ? (
          <Spinner size="xs" />
        ) : (
          <Flex
            fontSize="sm"
            fontWeight={500}
            color="blackAlpha.600"
            justify="space-between"
            width="100%"
            align="end"
          >
            <Button
              className="inner-button"
              leftIcon={
                !_active ? (
                  <IconPlugConnected size="20px" />
                ) : (
                  <IconSettingsCode size="20px" />
                )
              }
              colorScheme="brand"
              size="xs"
              variant={!_active ? "outline" : "solid"}
              onClick={(e) => {
                e.stopPropagation();
                if (!_active) {
                  let call = onConnect || defaultOnConnect;
                  call();
                } else {
                  onOpenDetailInternal();
                }
              }}
            >
              {!_active ? "Connect" : "Open settings"}
            </Button>
            <Box
              onClick={(e) => {
                e.stopPropagation();
                if (_active) {
                  (onDisconnect || defaultOnDisconnect)();
                } else {
                  (onConnect || defaultOnConnect)();
                }
              }}
            >
              <Switch
                className="inner-button"
                size="sm"
                colorScheme="brand"
                isChecked={_active}
              />
            </Box>
          </Flex>
        )}
      </CardFooter>
    </Card>
  );
};


const RequestApiKey = ({
  connector,
  connectorName,
  credentialsVal,
  setCredentialsVal,
}: {
  connector: string;
  connectorName: string;
  credentialsVal?: { api_key?: string };
  setCredentialsVal: (val: any) => void;
}) => {
  return (
    <Stack>
      <Stack align="center">
        <Image
          src={`/assets/${connector}_connect_help.gif`}
          width="100%"
          maxW="600px"
          height="auto"
        />
      </Stack>
      <Text fontWeight={700}>Please enter your API key</Text>
      <Input
        value={credentialsVal?.api_key || ""}
        onChange={(e) =>
          setCredentialsVal({
            ...(credentialsVal || {}),
            api_key: e.target.value,
          })
        }
        placeholder={`Enter your ${connector} API key`}
      />
    </Stack>
  );
};

export { IntegrationBox };
