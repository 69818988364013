import { HStack, Stack, Switch, Text } from '@chakra-ui/react';
import React from 'react';

const ReceivedMessagesSettings = ({ settings, onSettingsChange }: {
    settings: any;
    onSettingsChange: (settings: any) => void
}) => {
    const DEFAULT_CLASSES = {

    }
    return (
        <Stack backgroundColor="gray.100" borderRadius={4} p="4px" mt="8px">
            <Text fontSize="xs" fontWeight={900}>Received messages (beta)</Text>
            <HStack>

                <Switch size="sm" colorScheme="brand" isChecked={settings?.ignore_unknown_contacts} onChange={(e) => { onSettingsChange({ ...(settings || {}), ignore_unknown_contacts: e.target.checked }) }} />
                <Stack spacing={0}>
                    <Text fontSize="xs" fontWeight={800}>Ignore messages from unknown contacts</Text>
                    <Text fontSize="2xs">Conversations that were not started via EasyPie platform will not be imported</Text>

                </Stack>



            </HStack>

            <HStack>
                <Switch size="sm" colorScheme="brand" isChecked={settings?.auto_label_incoming} onChange={(e) => { onSettingsChange({ ...(settings || {}), auto_label_incoming: e.target.checked }) }} />
                <Text fontSize="xs" fontWeight={800}>Automatically label incoming messages</Text>
            </HStack>
            <Stack>


            </Stack>



        </Stack>

    );
};

export default ReceivedMessagesSettings;