import { Step } from "geniously-chat-ui";

function getStepPath(step: Step, toPlaceAtStepPath: any, stepList: Step[] = undefined) {
    // This will generate proper path to the step in the workflow step hierarchy and place {[step_name]:toPlaceAtStepPath} at the end of the path
    // e.q: {"step-1":{"step-1.1":{"step-1.1.1":{"step-1.1.1.1":[toPlaceAtStepPath]}}}} 
    // ... used to generate patch payload for API
    let _steps = stepList
    let found = _steps.indexOf(step) > 0;
    if (found) {
        return { [step.step_name]: toPlaceAtStepPath }
    }
    else {
        for (let i = 0; i < _steps.length; i++) {
            let _step = _steps[i]
            if (_step == step) {
                return { [step.step_name]: toPlaceAtStepPath }
            }
            else if (_step.step_name == step.step_name) {
                if (JSON.stringify(step) == JSON.stringify(_step)) {
                    return { [step.step_name]: toPlaceAtStepPath }
                }
            }

            if (_step.sub_steps?.length) {
                let res = getStepPath(step, toPlaceAtStepPath, _step.sub_steps)
                if (res) {
                    return { [_step.step_name]: res }
                }
            }
        }

    }
}

export { getStepPath }