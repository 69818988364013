import { Box } from "@chakra-ui/react"
// import {ReactComponent as GeniouslyLogo} from './logo_badge.svg';

const sizes=["xsmall","small","medium","large","xlarge"]

function getSizePx(size, ranges:number[]){
    for (let i=0;i<sizes.length;i++){
        if (size==sizes[i]){
            return ranges[i] + "px"
        }
    }
    return size
}

export const Logo =({ size,withText, ...rest}:{
    size?:string,
    withText?:boolean,
    [key:string]:any
})=>{
    if (withText){
        return(<Box  {...rest} gap={getSizePx(size,[2,3, 5,8,10]) } m="5px">

            <img src="/logo.svg" width={getSizePx(size,[90,120, 130,150,250])} height="auto"/>
    
            </Box>)
    }
    else
    return <img src="/g-logo.svg" style={{height:getSizePx(size,[10,15,20,35,60])}}  {...rest}/>
    //<BiStats/>
}


