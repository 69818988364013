import { Button, IconButton, Stack, Tooltip } from "@chakra-ui/react";
import Editor, { useMonaco } from "@monaco-editor/react";
import { IconAlignBoxLeftTop } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { icons } from "react-icons/lib";

const JsonObjectEditor = ({
  value,
  onChange,
  height,
}: {
  value: { [key: string]: any };
  onChange?: (val: { [key: string]: any }) => void;
  height?: string;
}) => {
  const [strValue, setStrValue] = useState<string>();
  const [err, setErr] = useState<string>();
  useEffect(() => {
    if (!value) {
      setStrValue("");
      return;
    }

    let valStr = JSON.stringify(value, null, 2);
    try {
      let current_val_formatted = JSON.stringify(JSON.parse(strValue), null, 2);
      if (valStr !== current_val_formatted) {
        setStrValue(valStr);
      }
    } catch (e) {
      setStrValue(valStr);
    }
  }, [value]);

  return (
    <Stack border={err?"1.5px solid red":"1px solid gray"} borderRadius="5px" p="1px">
      {onChange && (
        <Tooltip label="Prettify">
          <IconButton
            size="xs"
            marginBottom="-20px"
            marginEnd="5px"
            alignSelf="end"
            variant="ghost"
            opacity={0.5}
            zIndex={1}
            aria-label="Prettify"
            onClick={() => {
              setStrValue(JSON.stringify(value, null, 2));
            }}
            icon={<IconAlignBoxLeftTop />}
          />
        </Tooltip>
      )}
      <Editor
        height={height}
        defaultLanguage="json"
        value={strValue}
        options={onChange ? undefined : { readOnly: true }}
        onChange={(val) => {
          try {
            if (!val.trim()){
              setStrValue(val);
              onChange(null);
              setErr(undefined)
              return;
            }
            let valParsed = JSON.parse(val);
            setStrValue(val);
            onChange(valParsed);
            setErr(undefined)
          } catch (e) {
            setStrValue(val);
            setErr(e.message)
          }
        }}
      />
    </Stack>
  );
};

export default JsonObjectEditor;
