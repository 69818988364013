import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Checkbox, CloseButton, Flex, HStack, Heading, IconButton, Input, MenuGroup, Portal, Progress, SimpleGrid, Spinner, Stack, Tag, Text, Wrap, WrapItem, background, keyframes, useToast } from "@chakra-ui/react";

import { IconArrowsDiagonal2, IconBoxAlignBottomLeftFilled, IconChevronDown, IconChevronRight, IconChevronUp, IconCircleCheckFilled, IconExclamationCircle, IconPlayerPauseFilled, IconPlayerPlay, IconPlus, IconRefresh, IconStack2, IconThumbUp } from "@tabler/icons-react";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import { Reorder } from "framer-motion";
import { useEffect, useState } from "react";


const ColumnsSelector = ({ columns, pendingSave, hiddenColumns, setHiddenColumns, columnOrder, setColumnOrder, saveMetadata, ...rest }:
    {
        columns: string[],
        pendingSave: boolean,
        hiddenColumns: string[],
        setHiddenColumns: (columns: string[]) => void, [key: string]: any
        columnOrder?: string[] | null,
        setColumnOrder: (columns: string[]) => void,
        saveMetadata: (change_column_visibility?: any) => void
    }
) => {


    function _setColumnOrder(order: string[]) {
        setTmpColumnOrder(order)
    }

    const [tmpColumnOrder, setTmpColumnOrder] = useState(columnOrder || columns)
    useEffect(() => {
        setTmpColumnOrder(columnOrder || columns)

    }, [columnOrder, columns])

    const [filter, setFilter] = useState("")

    return columns?.length > 0 ? <Menu size="sm" closeOnSelect={false}>
        <MenuButton as={IconButton} icon={<IconChevronDown size="15px" />} />


        <MenuList zIndex={15}>
            <Stack overflow="auto">
                {/* <Text>

                    {pendingSave && JSON.stringify(tmpColumnOrder)}
                </Text> */}
                <HStack spacing={2} justify="space-between" p="0px 8px">

                    <HStack>
                        <Button
                            onClick={() => setHiddenColumns([])}
                            size="xs">Select all</Button>
                        <Button
                            onClick={() => setHiddenColumns(columns?.map((col) => col))}
                            size="xs">Clear selection</Button>
                    </HStack>
                    {pendingSave &&
                        <Button size="xs" colorScheme="red" justifySelf="end"
                        onClick={() => {
                            let payload = {} as any
                            if (hiddenColumns) {

                                payload.change_column_visibility = {}
                                columns.forEach((col) => {
                                    payload.change_column_visibility[col] = !hiddenColumns.includes(col)
                                })
                            }
                            if (columnOrder) {
                                payload.change_column_order = columnOrder
                            }
                            saveMetadata(payload)
                        }}
                    >Save preferences</Button>

                    }


                </HStack>
                <Stack p="0px 8px">
                    <Input size="xs" placeholder="Search columns" value={filter} onChange={(e) => setFilter(e.target.value)} />
                </Stack>
                {tmpColumnOrder && <Stack overflow="auto" maxH="70vh" as={Reorder.Group} onReorder={_setColumnOrder} values={(tmpColumnOrder)} >
                    {(tmpColumnOrder)?.map((col, i) => (
                        <MenuItem key={col} as={Reorder.Item}
                            display={filter && !col.toLowerCase().includes(filter.toLowerCase()) ? "none" : undefined}
                            onDragEnd={() => {
                                setColumnOrder(tmpColumnOrder)
                            }}
                            // whileDrag="dragging"
                            // position="relative"
                            value={col}
                        >
                            <Checkbox
                                isChecked={!hiddenColumns?.includes(col)}
                                onChange={(e) => {
                                    if (!hiddenColumns?.includes(col)) {
                                        setHiddenColumns(Array.from(new Set([...(hiddenColumns || []), col])));
                                    }
                                    else {
                                        setHiddenColumns(hiddenColumns.filter((c) => c != col))
                                    }
                                }} >{col}</Checkbox>
                        </MenuItem>))}
                </Stack>}

            </Stack>
        </MenuList>
    </Menu> : <></>
}


export default ColumnsSelector;