import React, { useEffect, useMemo } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, HStack, IconButton, Progress, Spinner, Stack, Tag, Text, Tooltip } from "@chakra-ui/react"

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react'
import { IconAdjustments, IconArrowsDiagonal2, IconArrowsDiagonalMinimize, IconAlertTriangle, IconX } from '@tabler/icons-react';

import { Step, TaskWorkflow } from './workFlowSchema';
import { AutoUI, AutosizeTextArea } from 'geniously-chat-ui';
import { StepInputsComponent } from './stepInputsComponent';
import { CodeEditor } from '../WorkflowDebuging/codeEditor';
import { getApi } from '../../apiService';
import { getStepPath } from './helpers';
import { useApiEndpoint } from '../../utils/useApiHook';



const DetailsModal = ({
  workflow,
  step: stepPreset,
  onClose,
  defaultTab,
  readonly,
}: {
  workflow: TaskWorkflow;
    step?: Step;
  defaultTab?: "inputs" | "code" | "outputs";
  onClose: (refreshNeeded: boolean, newWorkflow?: TaskWorkflow | undefined) => void;
  onSizeChange?: (newVal: "md" | "xl") => void;
  readonly?: boolean;
}) => {
  const all_tabs = ["inputs", "code", "errors", "analysis"] //this has to be in order as in bellow in the component


  const [changesState, setChangesState] = React.useState<any>();
  const [isReadOnly, setIsReadOnly] = React.useState<boolean>()
  useEffect(() => setIsReadOnly(readonly), [readonly, stepPreset])


  const [step, setStep] = React.useState<Step>()
  useEffect(() => {
    ifNoChangesOrCancel().then(() => {
      setStep(stepPreset)
    })
  }, [stepPreset])

  const [size, setSize] = React.useState<"md" | "full">("md")
  const [codeState, setCodeState] = React.useState<{ code?: string, stepName?: string, originalCode?: string }>({})
  const [modal, setModal] = React.useState<any>(null)
  const [processingMessage, setProcessingMessage] = React.useState<string>()
  const [processingMessageAllowClose, setProcessingMessageAllowClose] = React.useState<boolean>(false)
  useEffect(() => {
    if (processingMessage) {
      const timeout = setTimeout(() => {
        setProcessingMessageAllowClose(true)
      }, 25000) /*25s to allow discard that processing msg*/
      return () => clearTimeout(timeout)
    }
  }, [processingMessage])
  const [errorsState, setErrorsState] = React.useState<{
    errorDetails?: string[],
    errorMsg?: string,
    canForce?: boolean
  }>()

  useEffect(() => {
    setProcessingMessage(undefined)
  }, [errorsState])

  const isModified = useMemo(() => {
    if (codeState?.code !== codeState?.originalCode || (changesState && Object.values(changesState).filter(v => v !== undefined).length > 0)) {
      return true
    }
    return false


  }, [codeState, changesState])

  const workflowPatchEndpoint = useApiEndpoint("PATCH", `/tasks/workflows/{workflowId}`, false, true)
  const stepPatchEndpoint = useApiEndpoint("PATCH", `/tasks/workflows/{workflowId}/steps`, false, true)

  useEffect(() => {
    changesState && setChangesState(undefined)
  }, [step])

  const tabsToRender = useMemo(() => Object.fromEntries(all_tabs.map((tab) => {
    if (errorsState?.errorMsg && tab != "errors") {
      return [tab, false]
    }
    else if (tab == "errors") {
      return [tab, errorsState?.errorDetails || (stepPreset?.execution_status?.status == "failed" && stepPreset?.execution_status.status_message)]
    }
    if (step && tab == "code") {
      return [tab, step ? !!step.step_impl_function : true]
    }
    if (tab == "analysis") {
      return [tab, !step]
    }
    return [tab, true]
  }).filter(tabEntry => tabEntry[1]).map((tabEntry, i) => [tabEntry[0], i])), [step, errorsState])

  function onSave() {
    setProcessingMessage("Saving changes")
    if (step) {
      let currentStepPatch = { "$type": "patch" }
      if (changesState) {
        currentStepPatch["step_inputs"] = changesState
      }
      if (codeState?.code !== codeState?.originalCode) {
        currentStepPatch["code"] = codeState.code
      }
      let payload = getStepPath(stepPreset as any, currentStepPatch, workflow.workflow_steps as any)
      if (!payload) {
        setErrorsState({ errorMsg: "Unable to process changes", errorDetails: ["Step not found in workflow hierarchy"], canForce: false })
        return
      }
      stepPatchEndpoint.execute({ workflowId: workflow.id }, payload).then((response: { success: boolean, errors: string[], can_force: boolean, workflow?: TaskWorkflow }) => {
        if (!response.success) {
          setErrorsState({ errorMsg: "Failed to save changes", errorDetails: response.errors, canForce: response.can_force })
          return
        }
        else {
          onClose(true, response.workflow)
        }
      })


    }
    else {
      workflowPatchEndpoint.execute({ workflowId: workflow.id }, {
        "workflow_code": codeState.code,
        //"inputs": changesState

      }).then((response: { success: boolean, errors: string[], can_force: boolean, workflow?: TaskWorkflow }) => {
        if (!response.success) {
          setErrorsState({ errorMsg: "Failed to save changes", errorDetails: response.errors, canForce: response.can_force })
          return
        }
        else {
          onClose(true, response.workflow)
        }
      })
    }
    // getApi().authorize().then((authHeaders) => {

    //   fetch(`${getApi().baseUrl}/tasks/workflows/${workflow.id}`, {
    //     method: "PATCH",
    //     headers: {
    //       ...authHeaders,
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(payload)
    //   }).then((response) => response.json()).then((response: { success: boolean, errors: string[], can_force: boolean, workflow?: TaskWorkflow }) => {
    //     if (!response.success) {
    //       setErrorsState({ errorMsg: "Failed to save changes", errorDetails: response.errors, canForce: response.can_force })
    //       return
    //     }
    //     else {
    //       onClose(true, response.workflow)
    //     }
    //     //onClose(true)
    //   }).catch((error) => {
    //     setErrorsState({ errorMsg: "Failed to save changes", errorDetails: [error.message], canForce: false })
    //   })
    // })


  }

  const cancelRef = React.useRef()
  function ifNoChangesOrCancel() {
    return (new Promise<void>((resolve, reject) => {
      if (isModified) {
        setModal(
          <AlertDialog
            isOpen={true}
            leastDestructiveRef={cancelRef}
            onClose={() => setModal(undefined)}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Discard Changes?</AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure you want to discard all changes in configuration of this step?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={() => setModal(undefined)}>
                    Cancel
                  </Button>
                  <Button colorScheme='orange' onClick={() => {
                    setModal(undefined)
                    resolve()
                  }} ml={3}>
                    Discard changes
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )
      } else {
        resolve()
      }

    }))

  }

  function onCancel() {

    ifNoChangesOrCancel().then(() => onClose(false))

  }

  const [tabIndex, setTabIndex] = React.useState<number | number[]>(0)
  useEffect(() => {
    if (tabIndex == tabsToRender?.code) {
      if (!(codeState && codeState.code && codeState.stepName == step?.step_name)) {
        setCodeState({ code: undefined, originalCode: undefined, stepName: undefined })
        if (step) {

          getApi().authorize().then((authHeaders) => {
            fetch(`${getApi().baseUrl}/tasks/workflows/${workflow.id}/code/${step.step_impl_function}`, {
              headers: authHeaders
            }).then((response) => response.text()).then((code) => {
              setCodeState({ code, originalCode: code, stepName: step?.step_name })

            })
          })
        } else {
          setCodeState({ code: workflow.workflow_code, originalCode: workflow.workflow_code, stepName: undefined })
        }

      }
    }
  }, [tabIndex, step])

  useEffect(() => {
    if (defaultTab) {
      setTabIndex(tabsToRender[defaultTab])
    }
  }, [defaultTab])

  return (
    <Drawer isOpen={true} onClose={() => onCancel()} size={size} blockScrollOnMount={false} trapFocus={false} variant="aside">
      {/* <DrawerOverlay /> */}
      <DrawerContent>
        <DrawerHeader >
          <HStack align="start">
            <DrawerCloseButton />


            <IconButton variant="ghost" size="xs" color="gray"
              onClick={() => setSize(size == "md" ? "full" : "md")}
              icon={size == "full" ? <IconArrowsDiagonalMinimize size="20px" /> : <IconArrowsDiagonal2 size="20px" />} aria-label='Maximize/Minimize dialog' />
            {step ? (
              <Text>
                {step.step_name}
              </Text>
            ) : (<Stack>
              <Text>{workflow.task_name}</Text>
              <Text fontSize="2xs">v.{workflow.version}</Text>
            </Stack>)}
          </HStack>
        </DrawerHeader>

        <DrawerBody p="0px">
          {errorsState?.errorMsg && (
            <Stack p="20px" align="center" justify={"center"} color="red.800">
              <IconAlertTriangle size="50px" />
              <Text fontWeight={900} fontSize="lg">{errorsState?.errorMsg}</Text>
              <Button aria-label='Close error dialog' variant="ghost" colorScheme="gray" opacity={0.5} alignSelf="start" justifySelf="start" size="xs" onClick={() => {

                setErrorsState({ errorMsg: undefined })
              }} >Dismiss</Button>
            </Stack>
          )}
          {processingMessage ? (
            <Stack p="20px" align="center" justify={"center"} >
              <Spinner size="xl" />
              <Text fontWeight={900} fontSize="lg">{processingMessage}</Text>
              {processingMessageAllowClose &&
                <Button aria-label='Close processing dialog' variant="ghost" colorScheme="gray" opacity={0.5} alignSelf="start" justifySelf="start" size="xs" onClick={() => { setProcessingMessage(undefined) }} >Taking too long? Close this</Button>
              }
            </Stack>
          ) : <Accordion allowMultiple allowToggle defaultIndex={[0]} index={tabIndex} onChange={setTabIndex} >
            {tabsToRender?.inputs != undefined && <AccordionItem>
              {({ isExpanded }) => (<>
                <AccordionButton>
                  <HStack flex="1" textAlign="left">
                      {step ?
                        <><Text fontSize="sm">Step inputs</Text> <Tag>{Object.keys(step.step_inputs || {}).length}</Tag> </> : (
                          <><Text fontSize="sm">Task inputs</Text> <Tag>{Object.keys(workflow.inputs || {}).length}</Tag></>
                        )}
                  </HStack>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pl="20px">

                  {isExpanded && <StepInputsComponent step={step} onInputValuesChanged={(changes) => {
                    setChangesState(changes)
                  }} />}
                </AccordionPanel>

              </>
              )}
            </AccordionItem>}
            {tabsToRender?.code != undefined && <AccordionItem>
              {({ isExpanded }) => (<>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text fontSize="sm">Source code </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pl="0px">
                  <Box m="0px -15px 0px -25px">

                      {isExpanded && (codeState?.code ? <CodeEditor height='calc(100vh - 300px)' workflowCode={codeState.code} onChange={(val) => setCodeState({ ...codeState, code: val })} /> : <Stack p="20px" align="center" justify={"center"}><Spinner size="xl" /></Stack>)}
                    </Box>
                  </AccordionPanel>

                </>
                )}
              </AccordionItem>}
              {tabsToRender?.analysis != undefined && <AccordionItem>
                {({ isExpanded }) => (<>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize="sm">Static analysis </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pl="0px">
                    <Box m="0px -15px 0px -25px">

                      {isExpanded && (<AnalysisPanel taskId={workflow.task_id} task_workflow_id={workflow.id} code={codeState?.code || workflow.workflow_code} />)}
                    </Box>
                  </AccordionPanel>

              </>
              )}
            </AccordionItem>}
            {tabsToRender?.errors != undefined && <AccordionItem color="red.900">
              {({ isExpanded }) => (<>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text fontSize="sm" fontWeight={600}>Errors </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pl="30px">
                  <Box >
                    {stepPreset?.execution_status?.status == "failed" && stepPreset?.execution_status.status_message && (
                      <HStack p="2px" >
                        <IconAlertTriangle size="15px" />
                        <Text fontWeight={900} fontSize="xs">{stepPreset?.execution_status.status_message}</Text>
                      </HStack>
                    )}
                    {errorsState?.errorDetails && errorsState.errorDetails.map((err, i) => (
                      <HStack p="2px" key={i}>
                        <IconAlertTriangle size="15px" />
                        <Text fontWeight={900} fontSize="xs">{err}</Text>
                      </HStack>
                    ))}
                  </Box>
                </AccordionPanel>

              </>
              )}
            </AccordionItem>}
          </Accordion>}

        </DrawerBody>

        <DrawerFooter>
          {!isModified ? <Button
            size="sm"
            mr={3}
            onClick={() => onCancel()}
          >
            OK
          </Button> : (
            <HStack>
                <Button size="sm" colorScheme="brand" isLoading={stepPatchEndpoint.isRunning || workflowPatchEndpoint.isRunning} onClick={() => onSave()}>
                Save
              </Button>
              <Button size="sm" variant="outline" onClick={() => onCancel()}>
                Cancel
              </Button>
            </HStack>
          )}

          {modal}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
};


interface AnalysisFeedback {
  is_error: boolean,
  lineno: number,
  step_name?: string,
  text: string,
}
const AnalysisPanel = ({ taskId, task_workflow_id, code, task_inputs }: {
  taskId: string,
  code: string,
  task_workflow_id?: string,
  task_inputs?: any
}) => {
  const [analysis, setAnalysis] = React.useState<{ feedback: AnalysisFeedback[], used_connectors: string[] }>()


  const analysisEndpoint = useApiEndpoint("POST", `/tasks/${taskId}/workflows/analyze`, true, false)
  return (
    <Stack p="0px 40px" spacing={10}>

      {(analysis) && (
        analysis.feedback?.map((feedback, i) => (
          <Stack spacing={0}>
            <HStack>
              <Tag variant="outline" fontSize="sm" colorScheme={feedback.is_error ? "red" : "orange"}>{feedback.is_error ? "Error" : "Warning"} at line {feedback.lineno}</Tag>
              {feedback.step_name && <Text fontSize="sm" color="gray.800">in step {feedback.step_name}</Text>}
            </HStack>
            <Text whiteSpace="pre-wrap" fontSize="xs" color="gray.800">{feedback.text}</Text>

          </Stack>
        ))
      )}
      {analysis?.feedback?.length == 0 && <Tag fontSize="sm" colorScheme="green" alignSelf="center" p="8px 10px" color="gray.800">All good: No issues found</Tag>}
      <Button isLoading={analysisEndpoint.isRunning} alignSelf="center" onClick={() => {
        analysisEndpoint.execute({}, {
          workflow_code: code,
          task_inputs: task_inputs,
          task_workflow_id: task_workflow_id

        }).then((response) => {
          setAnalysis(response)
        })
      }}>{analysis ? "Re-Run analysis" : "Run analysis"}</Button>
    </Stack>
  )
}
export { DetailsModal }