import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { ImSearch } from "react-icons/im";
import { DataSource, Skill } from "../../models/dataModel";
import { useEffect, useMemo, useState } from "react";
import { AutoUI } from "../AutoUI/AutoUI";
import { getApi } from "../../apiService";

import { DataSourcesSection } from "../DataSourcesEdit/dataSourcesSection";
import { CommandEdit } from "../Commands/commandEdit";
import useSWR from 'swr'
import { CommandsSection } from "../Commands/commandsSection";



export const SkillEdit = ({skill, onChange, dataSources, onDataSourcesChange}:{
    skill:Skill, 
    onChange:(modifiedSkill:Skill)=>any
    dataSources:DataSource[],
    onDataSourcesChange?:(datasources:DataSource[])=>any
}) => {

    const [value, _setValue] = useState(skill)
    
    const setValue = (modifiedSkill:Skill)=>{
        _setValue(modifiedSkill)
        onChange(modifiedSkill)
    }
    
    
    const loadSchema = ()=>{
        if (!skill?.type) return
        
        return getApi().getMetaSchema("skills", skill.type).then((res)=>{
            res.properties["type"]["readOnly"] = true  
            return res
        })

    }

   

    const {data:schema, isLoading} = useSWR(`skills/`+skill.type,()=>loadSchema())

const [datasourcesLookup, setDatasourcesLookup] = useState<DataSource[]|null>(null)
const [typeSpecific, setTypeSpecific] = useState<string>(null)

useEffect(()=>{
    let lookup = schema?.properties?.configuration?.properties?.data_sources?.lookup
    if (!lookup) return
    if (lookup=="$.data_sources"){
        setDatasourcesLookup(dataSources)
    }
    else if (lookup.startsWith("$.data_sources")){
        const regex = /^\$\.data_sources\[@(\w+)==["|']([^"']+)["|']\]$/;
        const matches = regex.exec(lookup);
        if (matches) {
            const property = matches[1];
            const value = matches[2];
            if (property=="type"){
                setTypeSpecific(value)
            }
            setDatasourcesLookup(dataSources.filter((source) => source[property] === value))
          } 
    }
},[dataSources, schema])
 
 return(

     <Box alignItems="start" justifyItems="start" >
        
        {(isLoading||!value||!schema)?(<Spinner/>):(
            <>
            { <AutoUI value={value} onValueChange={val=>{
                    if (val && !val.name && schema){
                        val.name = schema.properties["name"]["default"]
                    }
                    setValue(val)
                }
                } schema={schema} excludeFields={value?.type!=="command"?["type","configuration.data_sources"]:["type","configuration"]}/>}
            {datasourcesLookup&&(
                <Box p="0px 20px">
                <DataSourcesSection
                allDataSources={datasourcesLookup}
                selectedDataSources={value.configuration?.data_sources}
                onSelectedDataSourcesChange={(newSelectedDataSources)=>{
                    setValue({...value, configuration:{...value.configuration, data_sources:newSelectedDataSources}})
                }}
                onAddDataSource={(ds)=>{
                    onDataSourcesChange([...(dataSources||[]), ds])
                }}
                onEditDataSource={(i, ds)=>{
                    onDataSourcesChange(dataSources?.map((d, j)=>i===j?ds:d))
                }}
                availableDataSourceTypes={typeSpecific?[typeSpecific]:undefined}
                />
                </Box>
            )}
            </>
        )}
        
           {value?.type==="command"&&(
            <CommandsSection commands={value.configuration.commands} onChange={(val)=>{ setValue({...value, configuration:{commands:val}})}}/>
            )}
        
    </Box>
      )  
}
