import { Badge, Box, Button, Flex, HStack, Heading, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Stack, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { IconFilter, IconInfinity, IconSearch, IconX } from "@tabler/icons-react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { IconChevronDown } from "@tabler/icons-react";

interface FilterDefinition {
    [filterField: string]: FilterField
}

interface FilterField {
    type: "text" | "select" | "date" | "number" | "boolean"
    options?: { [key: string]: any }
    label: string

}

const SearchFilter = ({ search, filter, filterDefinition, placeholder, onChange,onApply, onFocus,onBlur,...rest }: {
    search?: string
    placeholder?: string
    filter?: { [key: string]: any }
    filterDefinition?: FilterDefinition,
    onFocus?: (a?:any) => any
    onBlur?: (e?:any) => any
    onChange?: (search: string, filter: { [key: string]: any }) => any
    onApply?: (search: string, filter: { [key: string]: any }) => any

    [key: string]: any
}) => {

    const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
    const [tempSearch, setTempSearch] = useState<string>()
    const [tempFilter, setTempFilter] = useState<{ [key: string]: any }>()
    useMemo(() => setTempFilter(filter), [filter])
    const pendingSearch = (tempSearch || "") != (search || "")

    useEffect(() => {
        setTempSearch(search || "")
    }, [search])

    return (
        <Stack
            shadow="2xl" rounded={10}
            spacing={0}
            overflow="hidden"
            p="3px"
            boxShadow="lg"
            flexShrink={0}
            border="2px solid rgba(50,50,50,0.1)"
            {...rest}

        >
            <HStack>
                <InputGroup>
                    <InputLeftElement p="0px" height="25px">
                        <IconSearch color='gray' size="20px" />
                    </InputLeftElement>
                    <Input placeholder={placeholder||'Search'} size='md'
                        onFocus={onFocus}
                        variant='unstyled'
                        onBlur={(e) => {
                            if (e.relatedTarget?.id != "apply-search" && e.relatedTarget?.id != "open-filter") {
                                setTempSearch(search || "")
                                onBlur && onBlur(e)
                            }

                        }}
                        onKeyDown={(e) => {
                            if (e.key == "Enter") {
                                onChange && onChange(tempSearch, tempFilter)
                                onApply && onApply(tempSearch, tempFilter)
                                onClose()
                            }
                        }}
                        value={tempSearch || ""}
                        onChange={(e) => {
                            setTempSearch(e.target.value)
                        }}
                    />

                </InputGroup>
                {!pendingSearch && filter && Object.keys(filter).map((filterField, i) => {
                    let value: any = (filter[filterField] as any)
                    if (!value) return (<></>)
                    if (filterDefinition[filterField]?.type === "date") {
                        if (filter[filterField]["start"] && !filter[filterField]["end"]) {
                            value = <HStack ><Text>Last</Text> <Moment fromNow ago>{filter[filterField]["start"]}</Moment> </HStack>
                        }
                        else if (filter[filterField]["start"] || filter[filterField]["end"]) {

                            value = <HStack>{filter[filterField]["start"] || <IconInfinity />} <span>-</span> {filter[filterField]["end"] || <IconInfinity />}</HStack>
                        }
                        else {
                            return (<div key="-1"></div>)
                        }
                    }
                    return (<Badge key={i} colorScheme="brand" rounded={5} variant='outline'>{value}</Badge>)
                }
                )}

                {tempSearch && (
                    <IconButton h='1.75rem' size='xs' rounded={6} p="0px 5px" flexShrink={0} variant="ghost" aria-label="clear search phrase"
                        onClick={() => {
                            // !isOpen?onOpen():onClose()
                            setTempSearch("")
                            onChange && onChange("", filter)
                            onApply && onApply("", filter)
                        }}

                        icon={<IconX size="12px" />}
                    />

                )}
                {pendingSearch && (
                    <Button id="apply-search" h='1.75rem' size='xs' rounded={6} p="0px 5px" flexShrink={0} colorScheme="brand"
                        onClick={() => {
                            // !isOpen?onOpen():onClose()
                            onChange && onChange(tempSearch, filter)
                            onApply && onApply(tempSearch, filter)
                        }}

                        leftIcon={<IconSearch size="15px" />}
                    >
                        Apply
                        {/* <Badge bgColor="#f60678" color="white" rounded={5} m="-5px 5px 5px">1</Badge> */}
                    </Button>
                )}

                {filterDefinition &&
                    <Button id="open-filter" h='1.75rem' size='xs' rounded={6} p="0px 5px" flexShrink={0}
                        onClick={() => {
                            // !isOpen?onOpen():onClose()
                            onToggle()
                        }}

                        leftIcon={<IconFilter size="15px" />}
                    >
                        {!pendingSearch ? "Filter" : <IconChevronDown size="10px" />}
                        {/* <Badge bgColor="#f60678" color="white" rounded={5} m="-5px 5px 5px">1</Badge> */}
                    </Button>
                }
            </HStack>

            <Popover
                returnFocusOnClose={false}
                isOpen={isOpen}
                onClose={onClose}
                placement='bottom-end'
                arrowShadowColor="lightgray"
                closeOnBlur={false}
            >
                <PopoverAnchor>
                    <Box alignSelf="end" width="100px" />



                </PopoverAnchor>
                <PopoverContent width="auto" shadow="lg" border="1px solid lightgray">
                    <PopoverArrow />

                    <PopoverHeader textAlign="start" p="5px 10px">Filter

                    </PopoverHeader>
                    <PopoverBody>
                        <VStack spacing={3} align="start">
                            {filterDefinition && tempFilter && Object.keys(filterDefinition).filter(t => filterDefinition[t]).map((filterField, i) => {

                                if (filterDefinition[filterField].type === "date") {
                                    return <HStack key={i}>
                                        <VStack spacing={0} align="start">
                                            <Text fontSize="xs">From</Text>
                                            <Input
                                                placeholder="Select Date and Time"
                                                size="xs"
                                                value={(tempFilter[filterField] ? (tempFilter[filterField]["start"] || "") : "")}
                                                onChange={(e) => {
                                                    let newVal = { ...filter }
                                                    newVal[filterField] = { ...(newVal[filterField] || {}), start: e.target.value }
                                                    setTempFilter && setTempFilter(newVal)
                                                }}
                                                type="datetime-local"
                                            />
                                        </VStack>
                                        <VStack spacing={0} align="start">
                                            <Text fontSize="xs">To</Text>
                                            <Input
                                                placeholder="Select Date and Time"
                                                size="xs"
                                                value={(tempFilter[filterField] ? (tempFilter[filterField]["end"] || "") : "")}
                                                onChange={(e) => {
                                                    let newVal = { ...filter }
                                                    newVal[filterField] = { ...(newVal[filterField] || {}), end: e.target.value }
                                                    setTempFilter && setTempFilter(newVal)
                                                }}

                                                type="datetime-local"
                                            />
                                        </VStack>
                                    </HStack>
                                }
                                else {
                                    return <></>
                                }

                            })}
                            <HStack justify="space-between" alignSelf="stretch">
                                <Button size="xs" variant="ghost" colorScheme="brand" onClick={() => {
                                    setTempFilter({})
                                    onChange && onChange(search, {})
                                    onClose()
                                }}>Clear filter</Button>
                                <HStack>
                                    <Button size="xs" variant="outline" colorScheme="brand" onClick={() => {
                                        onClose()
                                        setTempFilter(filter)
                                    }}>Cancel</Button>
                                    <Button size="xs" colorScheme="brand" onClick={() => {
                                        onChange && onChange(tempSearch, tempFilter)
                                        onClose()
                                    }}>Apply</Button>
                                </HStack>
                            </HStack>
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Stack>)

}

export { SearchFilter }