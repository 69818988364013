
import Moment from "react-moment";
import { ConversationSession, TopicInfo } from "../../models/dataModel";
import { Stack, Box, Flex, Grid, GridItem, HStack, Heading, Text, Tooltip, Badge, Spinner, VStack, Card, Button, AccordionItem, AccordionButton, AccordionIcon, Accordion, AccordionPanel, Checkbox, IconButton } from "@chakra-ui/react";
import { SearchFilter } from "../SearchFilter/searchFilter";
import { useEffect, useMemo, useState } from "react";
import useSWRInfinite from 'swr/infinite'
import { getApi } from "../../apiService";
import { IconEdit, IconHistoryToggle, IconListDetails, IconPlayerPlayFilled, IconReload, IconTrash, IconVocabulary } from "@tabler/icons-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IconAlignBoxLeftTop } from "@tabler/icons-react";
import { Rating } from "../Rating/rating";
import { LimitItems } from "../LimitItems/limitItems";
import useSWR from "swr";
import { TaskRecipe, TaskWorkflow } from "./workflowsSchema";
import {ChatContainer, GeniouslyThemeProvider, Task, WorkflowEditor, WorkflowSteps,  } from "geniously-chat-ui"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Editor } from "@monaco-editor/react";
import { InteractiveWorkflowEditor } from "./InteractiveWorkflowEditor";
import { AutoUI } from "../AutoUI/AutoUI";
import { EditModal } from "../ModalDialogs/editModal";
import { AdvancedComplexDataEditor } from "./advancedComplexDataEditor";

import { RecipeDetail } from "./recipeDetail";
import { ConfirmModal } from "../ModalDialogs/confirmModal";
import { useAppContext } from "../../appContext";
import { ExecutionLogDetail } from "./executionLogDetail";
import { IconPlayerPlay } from "@tabler/icons-react";



const WorkDebugDetail = ({task}:{
    
    task:Task
    
    
}) => {
    
const [workflow, setWorkflow] = useState<TaskWorkflow>()
const { data:workflowFetched, isLoading, mutate: mutateLast} = useSWR(`${task?.id}/workflow/last`, () =>task? getApi().getLastTaskWorkflow(task.id):undefined)
const { data:workflowVersions, isLoading:isLoadingVersions, mutate:mutateVersions} = useSWR(`${task?.id}/workflow/versions`, () =>task? getApi().getTaskWorkflowVersions(task.id):undefined)
const { data:workflowExecutions, isLoading:isLoadingExecution, mutate:refreshExecutions} = useSWR(`${task?.id}/workflow/executions`, () =>task? getApi().getTasWorkflowExecutions(task.id):undefined)
const [scheduledRun, setScheduledRun] = useState<boolean>()
const [searchParams, setSearchParams] = useSearchParams();

if (!searchParams.get("tabIndex") || searchParams.get("tabIndex").length === 0) {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), tabIndex: "0" });
}
const tabIndex = parseInt(searchParams.get("tabIndex") || "0");

const setTabIndex = (newTabIndex: number) => 
setSearchParams({ ...Object.fromEntries(searchParams.entries()), tabIndex: `${newTabIndex}` });

  const navigate = useNavigate()

const taskNotReady = useMemo(()=>!["ready","failed","scheduled"].includes(task?.status),[task?.status])
const [forceRun, setForceRun]= useState<boolean>(false)
useEffect(()=>{setForceRun(false)},[task])
useEffect(()=>{
    if (workflowFetched!==undefined){
        setWorkflow(workflowFetched)
    }
},[workflowFetched])
const [modal, setModal] = useState<any>(null);
const [recipeSaved,setRecipeSaved] = useState<boolean>(false)

useEffect(()=>{ 
    setRecipeSaved(false)
    setClosestRecipes(undefined)
},[task])

interface StepMiniInfo{step_name:string, sub_steps?:StepMiniInfo[]}
function stepsDescription(steps: StepMiniInfo[], indent: number = 0): string {
    let result = "";
    for (let step of steps) {
        if (result.length > 0){
            result += "\n" 
        }
        result +="  ".repeat(indent) + step.step_name;
        if (step.sub_steps) {
            result += "\n";
            result += stepsDescription(step.sub_steps, indent + 1);
        }
        result += "  ";
    }
    return result;
}

const [closestRecipes, setClosestRecipes] = useState<TaskRecipe[]>()
const {setError}=useAppContext()
const [isLoadingRecipes, setIsLoadingRecipes] = useState<boolean>()
function getClosestRecipes(){
    if (!task) return
    setIsLoadingRecipes(true)
    getApi().getClosestRecipes(task.id).then((recipes)=>{
        setIsLoadingRecipes(false)
        setClosestRecipes(recipes)
    })
}

  const { data: messages, isLoading: isLoadingMessages, mutate } = useSWR(`taskSessions/${task?.id}`, () => {
    if (!task?.session_id) return
    return getApi().getMessages("default",task.session_id)
})

    return (
      <Stack maxH="100vh" overflow="auto">
        {modal}
        {task && (
          <Stack>
            <Stack maxH="30vh" overflow="auto" p="15px 2px">
              <Text fontWeight="bold" fontSize="sm">
                {task.task_name}
              </Text>

              <HStack fontSize="xs" spacing={5}>
                <HStack>
                  <Text>Task status:</Text>
                  <Badge>{task.status}</Badge>
                </HStack>
                <HStack>
                  <Text>Workflow status:</Text>
                  <Badge>{workflow?.status}</Badge>
                </HStack>
                <Button
                  variant="solid"
                  size="xs"
                  isDisabled={!workflow}
                  leftIcon={<IconEdit size="18px" />}
                  colorScheme="orange"
                  onClick={() => {
                    navigate(`/workflows/${workflow?.id}`);
                    // setModal(
                    //   <InteractiveWorkflowEditor
                    //     task_id={task.id}
                    //     task_workflow={workflow}
                    //     onOK={(val) => {
                    //       let newWorkflow = {
                    //         ...(workflow || {
                    //           task_id: task.id,
                    //           version: 1,
                    //           is_last: true,
                    //           tenant_id: task.tenant_id,

                    //           task_name: task.task_name,
                    //           task_description: task.task_description,
                    //         }),
                    //         workflow_code: val.code,
                    //         inputs: val.inputs,
                    //         status: "ready",
                    //         workflow_steps:
                    //           val.steps || workflow.workflow_steps,
                    //       } as any;
                    //       getApi()
                    //         .postWorkflow(newWorkflow)
                    //         .then((saved) => {
                    //           setWorkflow(saved);
                    //           setModal(null);
                    //         });
                    //     }}
                    //     onClose={() => {
                    //       setModal(null);
                    //     }}
                    //   />
                    // );
                  }}
                >
                  Edit workflow
                </Button>
              </HStack>
              <Accordion allowToggle>
                <AccordionItem>
                {({ isExpanded }) => (
                  <>
                  
                <AccordionButton >
                  <HStack justify="start" align="start">

                  <AccordionIcon  />
                {<Text textAlign="start" noOfLines={isExpanded?2:undefined} fontSize="sm">{task.task_description}</Text>}
                  </HStack>
                </AccordionButton>
                
                  </>
              )}
                </AccordionItem>

              </Accordion>
              
            </Stack>
            <Stack>
              <Tabs
                defaultIndex={tabIndex}
                onChange={(index) => setTabIndex(index)}
              >
                <TabList>
                  <Tab>Workflow</Tab>
                  <Tab>
                    Chat history <Badge>{messages?.length?.toString()}</Badge>{" "}
                  </Tab>
                  {workflow && <Tab>Code</Tab>}
                  {workflow && <Tab>Default inputs</Tab>}
                  {workflow && <Tab>Workflow executions</Tab>}
                  {workflow && <Tab>Workflow versions</Tab>}
                  {task && <Tab>Workflow recipes</Tab>}
                  {<Tab>Details</Tab>}
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Card p="5px">
                      {isLoading ? (
                        <Spinner />
                      ) : (
                        workflow?.workflow_steps?.length && (
                          <WorkflowSteps
                            readOnly={true}
                            steps={workflow?.workflow_steps as any}
                            originalSteps={workflow?.workflow_steps as any}
                          />
                        )
                      )}
                    </Card>
                  </TabPanel>
                  <TabPanel>
                    {/* chat messages */}
                    <GeniouslyThemeProvider
                      theme={{
                        colors: { primary: "#ff00c8", secondary: "black" },
                      }}
                    >
                      <Flex
                        align="stretch"
                        justify="stretch"
                        flexGrow={1}
                        bgColor="blue"
                      >
                        <ChatContainer
                          messages={messages}
                          justify="start"
                          getChatMessagesActions={(message) => {

                            return [{
                              id: "delete",
                              label: "Delete",
                              primary: false,
                              onClick: () => {
                                getApi()
                                  .deleteMessage("default", task.session_id, (message as any).id)
                                  .then(() => {
                                    mutate();
                                  });
                              },
                            }]
                          }}
                          bgColor="white"
                        />
                      </Flex>
                    </GeniouslyThemeProvider>
                  </TabPanel>
                  <TabPanel>
                    {!modal && (
                      <Editor
                        height="70vh"
                        defaultLanguage="python"
                        options={{ readOnly: true }}
                        value={workflow?.workflow_code}
                      />
                    )}
                  </TabPanel>
                  {workflow && <TabPanel>
                    <Card p="5px">
                      {workflow?.inputs ? (
                        <AutoUI value={workflow.inputs} readOnly={true} />
                      ) : (
                        <Text>No inputs</Text>
                      )}
                    </Card>
                    <Text fontSize="sm">
                      Note: Inputs can be edited in Code editor
                    </Text>
                  </TabPanel>}
                  {workflow && (
                    <TabPanel>
                      <HStack>
                        <Button
                          leftIcon={<IconPlayerPlayFilled />}
                          onClick={() =>
                            getApi()
                              .runTaskWorkflow(task.id, undefined, forceRun)
                              .then((res) => {
                                setScheduledRun(true);
                                setTimeout(() => {
                                  setScheduledRun(false);
                                  refreshExecutions();
                                }, 10 * 1000);
                              })
                              .catch((e) => {
                                setError(
                                  e.response?.data?.errors?.toString() ||
                                    e.toString(),
                                  "Error running workflow"
                                );
                              })
                          }
                        >
                          Run workflow now
                        </Button>
                        {
                          <HStack>
                            <Checkbox
                              checked={forceRun}
                              onChange={(e) => setForceRun(e.target.checked)}
                            />
                            <Text>Force run</Text>
                          </HStack>
                        }
                        {scheduledRun && <Text>Workflow scheduled to run</Text>}
                      </HStack>
                      {/* executions */}
                      {isLoading ? (
                        <Spinner />
                      ) : !workflowExecutions?.length ? (
                        <Text>No executions yet</Text>
                      ) : (
                        <></>
                      )}
                      {workflowExecutions?.map((execution, i) => (
                        <Card p="5px" key={i} m="4px">
                          <Accordion allowToggle>
                            <AccordionItem>
                              <AccordionButton>
                                <Box
                                  as="span"
                                  flex="1"
                                  textAlign="left"
                                  fontSize="xs"
                                >
                                  <HStack>
                                    <Text>Status: </Text>
                                    <Badge>
                                      {execution.status == "running" &&
                                      new Date().getTime() -
                                        new Date(
                                          execution.heartbeat
                                        ).getTime() >
                                        5 * 60 * 1000
                                        ? "Error (timeout)"
                                        : execution.status}
                                    </Badge>
                                    {(!["running", "finished"].includes(
                                      execution.status
                                    ) ||
                                      (execution.status == "running" &&
                                        new Date().getTime() -
                                          new Date(
                                            execution.heartbeat
                                          ).getTime() >
                                          5 * 60 * 1000)) && (
                                      <Button
                                        leftIcon={
                                          <IconPlayerPlay size="15px" />
                                        }
                                        size="xs"
                                        variant="outline"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          getApi()
                                            .resumeTaskExecution(
                                              task.id,
                                              execution.execution_id
                                            )
                                            .then((res) => {
                                              setScheduledRun(true);
                                              setTimeout(() => {
                                                setScheduledRun(false);
                                              }, 10 * 1000);
                                            })
                                            .catch((e) => {
                                              setError(
                                                e.response?.data?.errors?.toString() ||
                                                  e.toString(),
                                                "Error running task"
                                              );
                                            });
                                        }}
                                      >
                                        Resume
                                      </Button>
                                    )}

                                    <Text color="gray.500">
                                      {execution.status_message}
                                    </Text>
                                  </HStack>
                                  {execution.current_step && (
                                    <HStack align="start">
                                      <Text whiteSpace="nowrap">
                                        Last step:{" "}
                                      </Text>
                                      <Stack
                                        align="start"
                                        justify="start"
                                        spacing={0}
                                      >
                                        <HStack>
                                          <Text fontWeight={900}>
                                            {execution.current_step?.step_name}{" "}
                                            {execution.current_step
                                              .execution_status?.progress_total
                                              ? `[${execution.current_step.execution_status?.progress_index}/${execution.current_step.execution_status?.progress_total}]`
                                              : ""}
                                          </Text>
                                          <Badge>
                                            {
                                              execution.current_step
                                                ?.execution_status?.status
                                            }
                                          </Badge>
                                        </HStack>

                                        <Text fontWeight={400} color="gray.500">
                                          {
                                            execution.current_step
                                              ?.execution_status?.status_message
                                          }
                                        </Text>
                                      </Stack>
                                    </HStack>
                                  )}
                                  <Text>
                                    Workflow version:{" "}
                                    {execution.task_workflow_id.split(":")[1]}
                                  </Text>
                                  <Text>
                                    Execution id: {execution.execution_id}
                                  </Text>
                                  <Text>
                                    Started at:{" "}
                                    <Moment format="YYYY-MM-DD HH:mm">
                                      {execution.started_at}
                                    </Moment>{" "}
                                    ({" "}
                                    <Moment fromNow>
                                      {execution.started_at}
                                    </Moment>
                                    )
                                  </Text>
                                  <Text>
                                    Finished at:{" "}
                                    {execution.finished_at ? (
                                      <Moment format="YYYY-MM-DD HH:mm">
                                        {execution.finished_at}
                                      </Moment>
                                    ) : (
                                      ""
                                    )}
                                  </Text>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>

                              <AccordionPanel pb={4}>
                                <Stack>
                                  <Button
                                    size="sm"
                                    alignSelf="start"
                                    leftIcon={<IconListDetails />}
                                    onClick={() => {
                                      getApi()
                                        .getWorkflowExecutionsCheckPointState(
                                          task.id,
                                          execution.execution_id
                                        )
                                        .then((execstate) => {
                                          setModal(
                                            <EditModal
                                              caption="Execution Checkpoint data"
                                              value={{
                                                ...execution.checkpoint,
                                                locals: execstate,
                                              }}
                                              onOk={(val) => {
                                                setModal(null);
                                                getApi()
                                                  .patchWorkflowExecutionsCheckPointState(
                                                    task.id,
                                                    execution.execution_id,
                                                    val.locals
                                                  )
                                                  .then(() => setModal(null));
                                              }}
                                              onCancel={() => setModal(null)}
                                            >
                                              {(val, setVal) => (
                                                <AdvancedComplexDataEditor
                                                  value={val}
                                                  onChange={setVal}
                                                />
                                              )}
                                            </EditModal>
                                          );
                                        });
                                    }}
                                  >
                                    Show checkpoint state
                                  </Button>

                                  <Button
                                    size="sm"
                                    alignSelf="start"
                                    leftIcon={<IconListDetails />}
                                    onClick={() => {
                                      setModal(
                                        <ConfirmModal
                                          caption="Execution Checkpoint data"
                                          onOk={() => {
                                            setModal(null);
                                          }}
                                          onCancel={() => setModal(null)}
                                        >
                                          <ExecutionLogDetail
                                            execution={{
                                              task_id: task.id,
                                              execution_id:
                                                execution.execution_id,
                                            }}
                                          />
                                        </ConfirmModal>
                                      );
                                    }}
                                  >
                                    Show logs
                                  </Button>
                                  <Text>Executed steps:</Text>
                                  {execution.executed_steps && (
                                    <WorkflowSteps
                                      steps={execution.executed_steps as any}
                                      originalSteps={
                                        execution.executed_steps as any
                                      }
                                      readOnly={true}
                                    />
                                  )}
                                </Stack>
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        </Card>
                      ))}
                    </TabPanel>
                  )}
                  {workflow && (
                    <TabPanel>
                      {/* {versions} */}
                      <Stack>
                        {workflowVersions?.map((version, i) => (
                          <Button
                            key={i}
                            p="5px"
                            border="1px solid gray"
                            rounded="md"
                            m="2px"
                            justifyContent="start"
                            colorScheme="blue"
                            size="sm"
                            variant={version.is_last ? "solid" : "ghost"}
                            onClick={() => {
                              navigate(
                                `/workflows/${version.id}`
                              );
                              // setModal(
                              //   <InteractiveWorkflowEditor
                              //     task_id={task.id}
                              //     task_workflow={version}
                              //     onOK={(val) => {
                              //       const save = (asLast: boolean = false) => {
                              //         let newWorkflow = {
                              //           ...(version || {
                              //             task_id: task.id,
                              //             version: 1,

                              //             tenant_id: task.tenant_id,
                              //             is_last: true,
                              //             task_name: task.task_name,
                              //             task_description:
                              //               task.task_description,
                              //           }),
                              //           workflow_code: val.code,
                              //           inputs: val.inputs,

                              //           status: "ready",
                              //           workflow_steps:
                              //             val.steps || version.workflow_steps,
                              //         } as any;
                              //         if (asLast) {
                              //           newWorkflow.is_last = true;
                              //         }
                              //         getApi()
                              //           .postWorkflow(newWorkflow)
                              //           .then((saved) => {
                              //             setWorkflow(saved);
                              //             setModal(null);
                              //             if (asLast) mutateVersions();
                              //             mutateLast();
                              //           });
                              //       };
                              //       if (!version.is_last) {
                              //         setModal(
                              //           <ConfirmModal
                              //             caption="Change current latest version"
                              //             onCancel={() => save(false)}
                              //             onOk={() => {
                              //               save(true);
                              //             }}
                              //           ></ConfirmModal>
                              //         );
                              //       } else {
                              //         save();
                              //       }
                              //     }}
                              //     onClose={() => {
                              //       setModal(null);
                              //     }}
                              //   />
                              // );
                            }}
                          >
                            <HStack>
                              <Text>v.{version.version}</Text>
                              <Text>
                                <Moment>{version.created_at}</Moment>
                              </Text>
                              <Text>
                                (<Moment fromNow>{version.created_at}</Moment>)
                              </Text>
                            </HStack>
                          </Button>
                        ))}
                      </Stack>
                    </TabPanel>
                  )}
                  {workflow && (
                    <TabPanel>
                      <Stack>
                        <Stack>
                          <Button
                            variant="solid"
                            size="xs"
                            alignSelf="start"
                            leftIcon={<IconVocabulary size="18px" />}
                            colorScheme="blue"
                            isDisabled={recipeSaved}
                            onClick={() => {
                              let recipe = {
                                task_name: task.task_name,
                                task_description: task.task_description,
                                tags: [],
                                recipe_instructions: workflow?.workflow_steps
                                  ? stepsDescription(workflow?.workflow_steps)
                                  : "",
                                recipe_definition: workflow.workflow_code,
                              };
                              setModal(
                                <EditModal
                                  caption="Please review the recipe"
                                  value={recipe}
                                  onOk={(val) => {
                                    getApi()
                                      .postRecipe(val)
                                      .then((saved) => {
                                        setRecipeSaved(true);
                                        getClosestRecipes();
                                        setModal(null);
                                      });
                                  }}
                                  onCancel={() => {
                                    setModal(null);
                                  }}
                                >
                                  {(val, setVal) => (
                                    <RecipeDetail
                                      recipe={val as any}
                                      onChange={setVal}
                                    />
                                  )}
                                </EditModal>
                              );
                            }}
                          >
                            {recipeSaved
                              ? "Recipe saved"
                              : "Make this a recipe"}
                          </Button>
                          <Text fontWeight={600} fontSize="sm">
                            Recipes similar to this task
                          </Text>
                          <Stack
                            m="5px"
                            rounded="md"
                            p="5px"
                            border="1px solid #eaeaea"
                            justify="start"
                            align="start"
                            spacing={0}
                          >
                            {!isLoadingRecipes && closestRecipes ? (
                              closestRecipes?.length == 0 ? (
                                <Text>No similar recipes found</Text>
                              ) : (
                                closestRecipes.map((recipe, i) => (
                                  <Card
                                    key={i}
                                    m="5px 5px"
                                    border="0.5px solid gray"
                                    p="5px"
                                    cursor="pointer"
                                    onClick={(e) => {
                                      setModal(
                                        <EditModal
                                          caption="Please review the recipe"
                                          value={recipe}
                                          onOk={(val) => {
                                            getApi()
                                              .updateRecipe(val.id, val)
                                              .then((saved) => {
                                                let newRecipes = [
                                                  ...closestRecipes,
                                                ];
                                                newRecipes[i] = saved;
                                                setClosestRecipes(newRecipes);
                                                setModal(null);
                                              });
                                          }}
                                          onCancel={() => {
                                            setModal(null);
                                          }}
                                        >
                                          {(val, setVal) => (
                                            <RecipeDetail
                                              recipe={val as any}
                                              onChange={setVal}
                                            />
                                          )}
                                        </EditModal>
                                      );
                                    }}
                                  >
                                    <HStack justify="space-between">
                                      <HStack>
                                        <Text fontWeight={900}>
                                          {recipe.task_name}{" "}
                                        </Text>
                                        <Badge>
                                          {Math.round(
                                            (recipe as any).score * 1000
                                          ) / 10}{" "}
                                          %
                                        </Badge>
                                      </HStack>
                                      <IconButton
                                        variant="ghost"
                                        size="xs"
                                        icon={<IconTrash size="18px" />}
                                        aria-label="Delete"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setModal(
                                            <ConfirmModal
                                              caption="Delete recipe"
                                              onCancel={() => setModal(null)}
                                              onOk={() => {
                                                setModal(null);
                                                getApi()
                                                  .deleteRecipe(recipe.id)
                                                  .then(() => {
                                                    getClosestRecipes();
                                                  });
                                              }}
                                            >
                                              You really want to delete this
                                              recipe
                                            </ConfirmModal>
                                          );
                                        }}
                                      />
                                    </HStack>

                                    <Text fontSize="xs">
                                      {recipe.task_description}
                                    </Text>
                                  </Card>
                                ))
                              )
                            ) : (
                              <Stack
                                p="25px"
                                width="100%"
                                align="center"
                                minH="30vh"
                                justify="center"
                              >
                                {isLoadingRecipes ? (
                                  <Spinner />
                                ) : (
                                  <Button
                                    leftIcon={<IconReload />}
                                    colorScheme="blue"
                                    onClick={() => getClosestRecipes()}
                                  >
                                    Load closest recipes
                                  </Button>
                                )}
                              </Stack>
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </TabPanel>
                  )}

                  {(
                    <TabPanel>
                      {task && (
                        <Stack fontSize="xs">
                          <HStack>
                            <Text>Task status:</Text>
                            <Badge>{task.status}</Badge>
                          </HStack>
                          <HStack>
                            <Text>Workflow status:</Text>
                            <Badge>{workflow?.status}</Badge>
                          </HStack>
                          <Text>task_id: {task.id}</Text>
                          <Text>active: {task.active ? "true" : "false"}</Text>
                          <Text>session_id: {task.session_id}</Text>
                          <Text>AppId: {task.app_id}</Text>
                          <Text>workflow_id: {workflow?.id}</Text>
                          <Text>tenant_id: {task.tenant_id}</Text>
                          <Text>version: {workflow?.version}</Text>
                          <Text>
                            is last version:{" "}
                            {workflow?.is_last ? "true" : "false"}
                          </Text>
                        </Stack>
                      )}
                    </TabPanel>
                  )}
                </TabPanels>
              </Tabs>
            </Stack>

            <Stack spacing="4px" overflow="auto" maxH="70vh"></Stack>
          </Stack>
        )}
      </Stack>
    );

}

export {WorkDebugDetail}


