

import { useEffect, useState } from "react";



import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getApi } from "../apiService";


import { Flex, Stack } from "@chakra-ui/react";

import WorkFlowDetail from "../components/Workflows/workFlowDetail";




export  default function WorkflowDetailPage(){
    
    
    const {workflowId} = useParams()
  const navigate = useNavigate()


  


  return (<Stack flexGrow={1}>
      <WorkFlowDetail workflowId={workflowId} onWorkflowIdChange={(workflowId) => {
        navigate(`/workflows/${workflowId}`)
      }} />
    </Stack>
    
    )
}
