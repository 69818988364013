import { Image } from "@chakra-ui/react";
import { IconBolt, IconBook, IconPlugConnected, IconWebhook } from "@tabler/icons-react";
import { IconApi } from "@tabler/icons-react";



const IntegrationIcon=({type}:{type:string})=>{
    switch(type) {
        case "api":
        case "external_rest":
            return <IconApi size="40px"/>;
        case "zapier":
            return <Image  src={"/assets/zapier-logo.png"} width="40px" height="auto" maxH="40px"/>;
        case "ms_outlook":
            return <Image  src={"https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg/2203px-Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png"} width="40px" height="auto" maxH="40px"/>
        case "gmail":
            return <Image  src={"https://mailmeteor.com/logos/assets/PNG/Gmail_Logo_256px.png"}  width="40px" height="auto" maxH="40px"/>
        case "google_calendar":
            return <Image  src={"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/1024px-Google_Calendar_icon_%282020%29.svg.png?20221106121915"} width="40px" height="auto" maxH="40px"/>
        case "monday":
            return <Image  src={"/assets/monday.png"}  width="40px" height="auto" maxH="40px"/>
        case "webhooks":
            return <IconWebhook color="white" size="40px"/>
        case "hubspot":
            return <Image  src={"/assets/hubspot.png"} width="40px" height="auto" maxH="40px"/>;
        case "linkedin":
            return <Image src={"/assets/linkedin.png"} width="40px" height="auto" maxH="40px"/>;
        default:
            return <IconPlugConnected size="40px"/>
    }
}

export default IntegrationIcon
